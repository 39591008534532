import {
  Dialog, Button, DialogActions, DialogContent, DialogTitle, TextField,
} from '@material-ui/core';
import React, { useRef } from 'react';

export interface LinkDialogProps { title: string, sharedUrl: string, open: boolean, onClose: () => void }
export function LinkDialog(props: LinkDialogProps) {
  const textAreaRef = useRef<HTMLInputElement>(null);

  const handleCopyLinkToClipboard = () => {
    if (textAreaRef?.current !== null && textAreaRef?.current !== undefined) {
      textAreaRef.current.focus();
      textAreaRef.current.setSelectionRange(0, props.sharedUrl.length);
      document.execCommand('copy');
      props.onClose();
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="urlTextField"
          label="URL"
          defaultValue={props.sharedUrl}
          fullWidth
          inputRef={textAreaRef}
          InputProps={{
            readOnly: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCopyLinkToClipboard} color="primary">
          Copy link to clipboard
        </Button>
        <Button onClick={() => props.onClose()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
