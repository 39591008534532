import {
  SearchQueryForAPI, DEFAULT_MINIMUM_DATE, DEFAULT_MAXIMUM_DATE, DEFAULT_MINIMUM_DEPTH,
  DEFAULT_MAXIMUM_DEPTH, DEFAULT_LUCENE, LUCENE_TRUE_STRING_VALUE, LUCENE_FALSE_STRING_VALUE, DEFAULT_ORDERING_VIEW_MODEL,
} from './searchParametersTypes';
import {
  GetFormattedDate, ConcatWithSeparator, formatReportType, formatDateYYYYDashMMDashDD,
} from '../../common/formatters';
import { AppState } from '..';

export const getSearchParameterAvailableValues = (state: AppState) => state.searchParameters.searchParams.availableValues.searchParameterAvailableValues;

export const getSearchParameterSelectedValues = (state: AppState) => state.searchParameters.searchParams.userQuery.searchParams;
export const getServiceNowFeedbackUrl = (state: AppState) => state.searchParameters.searchParams.availableValues.serviceNowFeedbackUrl;

export const getSearchText = (state: AppState) => state.searchParameters.searchParams.userQuery.text;
export const getSelectedOrdering = (state: AppState) => {
  const selectedOrderings = state.searchParameters.searchParams.userQuery.searchParams.orderings;
  return (selectedOrderings.length > 0) ? selectedOrderings[0] : DEFAULT_ORDERING_VIEW_MODEL;
};
export const getIsSearchTextErroneous = (state: AppState) => state.searchParameters.searchTextValidity.isSearchTextErroneous;
export const getSearchTextFieldHelperText = (state: AppState) => state.searchParameters.searchTextValidity.searchTextFieldHelperText;
export const getIsSearchButtonDisabled = (state: AppState) => state.searchParameters.searchTextValidity.isSearchButtonDisabled;

export const getSearchQuery = (state: AppState) => state.searchParameters.searchParams.userQuery;

export const getSearchQueryForAPI = (state: AppState) => {
  const StartTime = state.searchParameters.searchParams.userQuery.searchParams.startDate;
  const EndTime = state.searchParameters.searchParams.userQuery.searchParams.endDate;
  const StartDepth = state.searchParameters.searchParams.userQuery.searchParams.startDepth;
  const EndDepth = state.searchParameters.searchParams.userQuery.searchParams.endDepth;
  const Lucene = state.searchParameters.searchParams.userQuery.searchParams.lucene;

  const startTimeNullable = StartTime.valueOf() !== DEFAULT_MINIMUM_DATE.valueOf() ? StartTime : null;
  const endTimeNullable = EndTime.valueOf() !== DEFAULT_MAXIMUM_DATE.valueOf() ? EndTime : null;

  return new SearchQueryForAPI(
    {
      CountryNames: state.searchParameters.searchParams.userQuery.searchParams.countries.map((v) => v.name),
      FieldNames: state.searchParameters.searchParams.userQuery.searchParams.fields.map((v) => v.name),
      WellboreNames: state.searchParameters.searchParams.userQuery.searchParams.wellbores.map((v) => v.name),
      SectionNames: state.searchParameters.searchParams.userQuery.searchParams.sectionNames.map((v) => v.name),
      SectionTypeNames: state.searchParameters.searchParams.userQuery.searchParams.sectionTypes.map((v) => v.name),
      FormationNames: state.searchParameters.searchParams.userQuery.searchParams.formations.map((v) => v.name),
      RigNames: state.searchParameters.searchParams.userQuery.searchParams.rigs.map((v) => v.name),
      ReportTypes: state.searchParameters.searchParams.userQuery.searchParams.reportTypes.map((v) => v.id),
      Topics: state.searchParameters.searchParams.userQuery.searchParams.topics.map((v) => v.name),
      StartTime: startTimeNullable,
      EndTime: endTimeNullable,
      StartDepth: StartDepth !== DEFAULT_MINIMUM_DEPTH ? StartDepth : null,
      EndDepth: EndDepth !== DEFAULT_MAXIMUM_DEPTH ? EndDepth : null,
      Lucene: Lucene !== DEFAULT_LUCENE ? Lucene : null,
      Orderings: state.searchParameters.searchParams.userQuery.searchParams.orderings.map((x) => x.ordering),
    },
    state.searchParameters.searchParams.userQuery.text,
    state.searchResults.receivedResultsCount,
  );
};

export interface FilterNameAndText {
  FilterName: string,
  FilterText: string
}

const addNamesConcatenatedLine = (filterName: string, textLines: FilterNameAndText[], names : string[]) => {
  if (names.length > 0) {
    textLines.push({ FilterName: filterName, FilterText: ConcatWithSeparator(names) });
  }
};

export const getSearchQueryText = (state: AppState) : FilterNameAndText[] => {
  const filterNameAndTextList : FilterNameAndText [] = [];
  const luceneParameter = state.searchParameters.searchParams.userQuery.searchParams.lucene;

  addNamesConcatenatedLine('Countries', filterNameAndTextList, state.searchParameters.searchParams.userQuery.searchParams.countries.map((v) => v.name));
  addNamesConcatenatedLine('Fields', filterNameAndTextList, state.searchParameters.searchParams.userQuery.searchParams.fields.map((v) => v.name));
  addNamesConcatenatedLine('Wellbores', filterNameAndTextList, state.searchParameters.searchParams.userQuery.searchParams.wellbores.map((v) => v.name));
  addNamesConcatenatedLine('Section types', filterNameAndTextList, state.searchParameters.searchParams.userQuery.searchParams.sectionTypes.map((v) => v.name));
  addNamesConcatenatedLine('Sections', filterNameAndTextList, state.searchParameters.searchParams.userQuery.searchParams.sectionNames.map((v) => v.name));
  addNamesConcatenatedLine('Rigs', filterNameAndTextList, state.searchParameters.searchParams.userQuery.searchParams.rigs.map((v) => v.name));
  addNamesConcatenatedLine('Formations', filterNameAndTextList, state.searchParameters.searchParams.userQuery.searchParams.formations.map((v) => v.name));
  addNamesConcatenatedLine(
    'Report types',
    filterNameAndTextList,
    state.searchParameters.searchParams.userQuery.searchParams.reportTypes.map((v) => formatReportType(v.name)),
  );
  addNamesConcatenatedLine('Topics', filterNameAndTextList, state.searchParameters.searchParams.userQuery.searchParams.topics.map((v) => v.name));

  if (formatDateYYYYDashMMDashDD(state.searchParameters.searchParams.userQuery.searchParams.startDate) !== formatDateYYYYDashMMDashDD(DEFAULT_MINIMUM_DATE)
    || formatDateYYYYDashMMDashDD(state.searchParameters.searchParams.userQuery.searchParams.endDate) !== formatDateYYYYDashMMDashDD(DEFAULT_MAXIMUM_DATE)) {
    const dateFilterText = `${GetFormattedDate(state.searchParameters.searchParams.userQuery.searchParams.startDate)}-${
      GetFormattedDate(state.searchParameters.searchParams.userQuery.searchParams.endDate)}`;

    filterNameAndTextList.push({ FilterName: 'Date', FilterText: dateFilterText });
  }

  if (state.searchParameters.searchParams.userQuery.searchParams.startDepth !== DEFAULT_MINIMUM_DEPTH
      || state.searchParameters.searchParams.userQuery.searchParams.endDepth !== DEFAULT_MAXIMUM_DEPTH) {
    const depthFilterText = `${state.searchParameters.searchParams.userQuery.searchParams.startDepth}-${
      state.searchParameters.searchParams.userQuery.searchParams.endDepth}`;

    filterNameAndTextList.push({ FilterName: 'Depth', FilterText: depthFilterText });
  }

  if (luceneParameter !== DEFAULT_LUCENE) {
    filterNameAndTextList.push({ FilterName: 'Lucene', FilterText: luceneParameter ? LUCENE_TRUE_STRING_VALUE : LUCENE_FALSE_STRING_VALUE });
  }

  if (state.searchParameters.searchParams.userQuery.text) {
    filterNameAndTextList.push({ FilterName: 'Searched text', FilterText: state.searchParameters.searchParams.userQuery.text });
  }

  return filterNameAndTextList;
};
