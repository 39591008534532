import { AppState } from '../index';

export const getLinkedReport = (state: AppState) => state.reportDetails.linkedReport;

export const getRelatedReports = (state: AppState) => state.reportDetails.relatedReports.filter((x) => x.description != null);
export const getRelatedReportsCount = (state: AppState) => state.reportDetails.relatedReports.length;
export const getExistsMoreRelatedReports = (state: AppState) => state.reportDetails.existsMoreRelatedReports;

export const getSimilarReports = (state: AppState) => state.reportDetails.similarReports;
export const getSimilarReportsCount = (state: AppState) => state.reportDetails.similarReports.length;
export const getExistsMoreSimilarReports = (state: AppState) => state.reportDetails.existsMoreSimilarReports;

export const getIsGettingRelatedReports = (state: AppState) => state.reportDetails.isGettingRelatedReports;
export const getIsLoadingMoreRelatedReports = (state: AppState) => state.reportDetails.isLoadingMoreRelatedReports;

export const getIsGettingSimilarReports = (state: AppState) => state.reportDetails.isGettingSimilarReports;
export const getIsLoadingMoreSimilarReports = (state: AppState) => state.reportDetails.isLoadingMoreSimilarReports;
