import * as React from 'react';
import {
  TableContainer, Table, TableRow, TableBody, TableHead, TableCell, Paper, withStyles,
  Theme, createStyles, Button, ButtonGroup, IconButton, makeStyles, CircularProgress, Tabs, Tab, Box, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ShareIcon from '@material-ui/icons/Share';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Tooltip from '@material-ui/core/Tooltip';
import { ChangeEvent } from 'react';
import { ReportQueryForAPI } from '../store/searchParameters/searchParametersTypes';
import { formatReportType, GetReportDate } from '../common/formatters';
import { useMountEffect } from '../containers/App';
import {
  getRelatedReportsRequested, clearRelatedReports, getSimilarReportsRequested, loadMoreSimilarReportsRequested, loadMoreRelatedReportsRequested,
} from '../store/reportDetails/actions';
import {
  getExistsMoreRelatedReports, getExistsMoreSimilarReports, getIsLoadingMoreRelatedReports, getIsGettingRelatedReports,
  getRelatedReports, getSimilarReports, getIsGettingSimilarReports, getIsLoadingMoreSimilarReports,
} from '../store/reportDetails/selectors';

import { LinkDialog } from './LinkDialog';
import { getBaseUrl } from '../common/baseUrl';
import { setSelectedResult } from '../store/searchResults/actions';
import { IReport, ReportFunctions, DepthIntervalFunctions } from '../store/searchResults/searchResultTypes';
import { GetReportTitle, GetReportDescription } from './ReportText';

const useStyles = makeStyles({
  closeButtonPanel: {
    'text-align': 'right',
  },
});

export interface ReportDetailsProps {
  report: IReport,
  showHidePanelButton?: boolean,
  renderDependency?: any
}

export function ReportDetails(props: ReportDetailsProps) {
  const reportQuery = new ReportQueryForAPI(props.report.id, props.report.reportType);

  // Own state -------------------------------------------
  const [shareReportDialogOpen, setshareReportDialogOpen] = React.useState(false);

  const RELATED_REPORTS_TAB_INDEX = 0;
  const SIMILAR_REPORTS_TAB_INDEX = 1;
  const [activeTabIndex, setActiveTabIndex] = React.useState(RELATED_REPORTS_TAB_INDEX);

  const [initializedTabIndexes, setInitializedTabIndexes] = React.useState(new Array<number>());

  // Selectors --------------------------------------------
  const relatedReports = useSelector(getRelatedReports);
  const existsMoreRelatedReports = useSelector(getExistsMoreRelatedReports);
  const isRetrievingRelatedReports = useSelector(getIsGettingRelatedReports);
  const isLoadingMoreRelatedReports = useSelector(getIsLoadingMoreRelatedReports);

  const similarReports = useSelector(getSimilarReports);
  const existsMoreSimilarReports = useSelector(getExistsMoreSimilarReports);

  const isRetrievingSimilarReports = useSelector(getIsGettingSimilarReports);
  const isLoadingMoreSimilarReports = useSelector(getIsLoadingMoreSimilarReports);

  // Dispatchers ------------------------------------------
  const dispatch = useDispatch();
  const hidePanel = () => dispatch(setSelectedResult(null));

  useMountEffect(() => {
    dispatch(clearRelatedReports());
    setInitializedTabIndexes([]);
    initializeActiveTab(activeTabIndex, []);
  }, props.renderDependency);

  // Variables --------------------------------------------
  const classes = useStyles();
  const { report, showHidePanelButton } = props;
  const wellcomLink = ReportFunctions.getWellcomUrl(report);

  // Event handlers ---------------------------------------
  const handleTabChange = (_: ChangeEvent<{}>, value: any) => {
    const activeTabIndex = value;
    setActiveTabIndex(activeTabIndex);
    initializeActiveTab(activeTabIndex, initializedTabIndexes);
  };

  const initializeActiveTab = (tabIndex: number, initializedTabIndexes: Array<number>) : void => {
    switch (tabIndex) {
      case RELATED_REPORTS_TAB_INDEX:
        if (!initializedTabIndexes.includes(RELATED_REPORTS_TAB_INDEX)) {
          dispatch(getRelatedReportsRequested(reportQuery));
          setInitializedTabIndexes(initializedTabIndexes.concat(RELATED_REPORTS_TAB_INDEX));
        }
        break;
      case SIMILAR_REPORTS_TAB_INDEX:
        if (!initializedTabIndexes.includes(SIMILAR_REPORTS_TAB_INDEX)) {
          dispatch(getSimilarReportsRequested(reportQuery));
          setInitializedTabIndexes(initializedTabIndexes.concat(SIMILAR_REPORTS_TAB_INDEX));
        }
        break;
      default:
        break;
    }
  };

  const handleLoadMoreSimilarReports = () : void => {
    dispatch(loadMoreSimilarReportsRequested(reportQuery));
  };

  const handleLoadMoreRelatedReports = () : void => {
    dispatch(loadMoreRelatedReportsRequested(reportQuery));
  };

  // Render -----------------------------------------------
  return (
    <div>
      <div className={classes.closeButtonPanel}>
        {showHidePanelButton ? (
          <Tooltip title="Close" placement="top">
            <IconButton
              aria-label="close"
              color="default"
              onClick={() => hidePanel()}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
      <ReportPropertiesTable report={report} />
      <br />
      <div className="row">
        <div className="col-3">
          <strong>Topic: </strong>
          {ReportFunctions.concatenateTopicNames(report)}
        </div>
        <div className="col-6">
          <strong>Report date: </strong>
          {GetReportDate(report.reportDate, report.timeInterval)}
        </div>
        <div className="col-3">
          <ButtonGroup
            orientation="vertical"
          >
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setshareReportDialogOpen(true)}
              startIcon={<ShareIcon />}
            >
              Share report
            </Button>
            <Button
              disabled={wellcomLink === ''}
              color="default"
              variant="outlined"
              startIcon={<ArrowForwardIcon />}
              target="_blank"
              href={wellcomLink}
            >
              View in Wellcom
            </Button>
          </ButtonGroup>
          <LinkDialog
            title="Copy link to report"
            sharedUrl={createReportDetailsURL(report)}
            open={shareReportDialogOpen}
            onClose={() => setshareReportDialogOpen(false)}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-12">
          <h3>
            {GetReportTitle(report)}
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div>
            {GetReportDescription(report)}
          </div>
        </div>
      </div>
      <br />
      <Tabs value={activeTabIndex} onChange={handleTabChange} indicatorColor="primary">
        <Tab label="Connected reports" />
        <Tab label="More like this" />
      </Tabs>
      <TabPanel value={activeTabIndex} index={RELATED_REPORTS_TAB_INDEX}>
        <div className="row">
          <div className="col-10">
            {!isRetrievingRelatedReports ? (
              <ReportTable
                reports={relatedReports}
                isLoadMoreEnabled={existsMoreRelatedReports}
                isLoadingMoreInProgress={isLoadingMoreRelatedReports}
                onLoadMoreClick={() => handleLoadMoreRelatedReports()}
              />
            )
              : <CircularProgress />}
          </div>
          <div className="col-2" />
        </div>
      </TabPanel>
      <TabPanel value={activeTabIndex} index={SIMILAR_REPORTS_TAB_INDEX}>
        <div className="row">
          <div className="col-10">
            {!isRetrievingSimilarReports ? (
              <ReportTable
                reports={similarReports}
                isLoadMoreEnabled={existsMoreSimilarReports}
                isLoadingMoreInProgress={isLoadingMoreSimilarReports}
                onLoadMoreClick={() => handleLoadMoreSimilarReports()}
              />
            )
              : <CircularProgress />}
          </div>
          <div className="col-2" />
        </div>
      </TabPanel>
    </div>
  );
}

// TabPanel -----------------------------------------------
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.defaultProps = {
  children: null,
};

// Report properties table --------------------------------
const StyledTableCell = withStyles((theme: Theme) => createStyles({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold as number,
  },
  body: {
    fontSize: 14,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold as number,
  },
}))(TableCell);

interface IReportPropertiesTableProps {
  report: IReport;
}

function ReportPropertiesTable(props : IReportPropertiesTableProps) {
  const { report } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="report overview" size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Country</StyledTableCell>
            <StyledTableCell align="center">Field</StyledTableCell>
            <StyledTableCell align="center">Rig</StyledTableCell>
            <StyledTableCell align="center">Wellbore</StyledTableCell>
            <StyledTableCell align="center">Section</StyledTableCell>
            <StyledTableCell align="center">Depth MD</StyledTableCell>
            <StyledTableCell align="center">Formation</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell component="th" scope="row" align="center">{ReportFunctions.concatenateCountries(report)}</StyledTableCell>
            <StyledTableCell align="center">{ReportFunctions.concatenateFieldNames(report)}</StyledTableCell>
            <StyledTableCell align="center">{ReportFunctions.concatenateRigNames(report)}</StyledTableCell>
            <StyledTableCell align="center">{ReportFunctions.concatenateWellboreNames(report)}</StyledTableCell>
            <StyledTableCell align="center">{ReportFunctions.concatenateSectionNames(report)}</StyledTableCell>
            <StyledTableCell align="center">{DepthIntervalFunctions.formattedString(report.depthInterval)}</StyledTableCell>
            <StyledTableCell align="center">{ReportFunctions.concatenateFormationNames(report)}</StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// Reports table ------------------------------------------
const useStylesReportTable = makeStyles({
  tableRow: {
    cursor: 'pointer',
  },
  closeButtonPanel: {
    'text-align': 'right',
  },
});

interface IReportTableProps {
  reports: IReport[];
  isLoadMoreEnabled: boolean,
  isLoadingMoreInProgress: boolean,
  onLoadMoreClick: () => void;
}
function ReportTable(props : IReportTableProps) {
  const classes = useStylesReportTable();

  return props.reports !== null && props.reports.length > 0 ? (
    <div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">Wellbore</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Title</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.reports.map((report) => (
              <TableRow key={report.resultGuid} hover className={classes.tableRow} onClick={() => window.open(createReportDetailsURL(report))}>
                <TableCell align="left" component="th" scope="row">
                  {ReportFunctions.concatenateWellboreNames(report)}
                </TableCell>
                <TableCell align="left">{formatReportType(report.reportType)}</TableCell>
                <TableCell align="left">{report.title}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {props.isLoadMoreEnabled && (
        <Button variant="text" color="primary" onClick={() => props.onLoadMoreClick()}>
          Load more
          {props.isLoadingMoreInProgress && <CircularProgress size={12} />}
        </Button>
      )}
    </div>
  )
    : <div>No related reports were found</div>;
}

export const createReportDetailsURL = (report: IReport) => {
  let url = getBaseUrl();
  url += `ReportDetails/${report.reportType}/${report.id}`;
  return url;
};
