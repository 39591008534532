import { getAuthServiceSingleton, RESOURCE_NAME_MAIN } from './authentication/authService';

export const httpGet = async <T>(url: string, resource: string = RESOURCE_NAME_MAIN): Promise<T> => {
  const authService = getAuthServiceSingleton();
  const accessToken = await authService.acquireAccessToken(resource);

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append('Authorization', bearer);
  const options = {
    method: 'GET',
    headers,
    body: null,
  };

  const response = await GetResponse<T>(url, options);
  return response;
};

export const submitFeedback = async (url: string, data: {}, files: File[]) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  for (let index = 0; index < files.length; index += 1) {
    formData.append('file', files[index]);
  }

  const authService = getAuthServiceSingleton();
  const accessToken = await authService.acquireAccessToken(RESOURCE_NAME_MAIN);

  return fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(defaultHttpErrorMessage(response.statusText, url));
    });
};

export const httpPost = async <V, T>(url: string, data: V, resource: string = RESOURCE_NAME_MAIN): Promise<T> => {
  const authService = getAuthServiceSingleton();
  const accessToken = await authService.acquireAccessToken(resource);

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append('Authorization', bearer);
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  };

  const response = await GetResponse<T>(url, options);
  return response;
};

type RequestOptions = {
    method: string;
    headers: Headers;
    body: string | null;
}

interface ErrorResponse {
    errorMessage : string;
}

function instanceOfErrorResponse(object: any): object is ErrorResponse {
  return 'errorMessage' in object;
}

const GetResponse = async <T>(url: string, options: RequestOptions) => {
  let response : any;
  try {
    response = await fetch(url, options);
  } catch (err) {
    throw new Error(defaultHttpErrorMessage(err.message, url));
  }

  if (!response.ok) {
    let errorResponse : any;
    try {
      errorResponse = await response.json();
    } catch {
      throw new Error(defaultHttpErrorMessage(response.statusText, url));
    }

    if (instanceOfErrorResponse(errorResponse) && errorResponse.errorMessage) {
      throw new Error(errorResponse.errorMessage);
    } else {
      throw new Error(defaultHttpErrorMessage(response.statusText, url));
    }
  }

  const data = await response.json();
  return data as T;
};

const defaultHttpErrorMessage = (message: string, url: string) => `Error while calling server: ${message} (${url})`;
