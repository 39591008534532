import * as React from 'react';
import {
  Grid, Button, IconButton, Switch, FormControl, FormControlLabel, makeStyles,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ChangeEvent } from 'react';
import ParameterMultiSelectDropdown from './ParameterMultiSelectDropdown';
import ParameterMultiSelectDropdownVirtualized from './ParameterMultiSelectDropdownVirtualized';
import {
  SearchParameterAvailableValues, SELECTABLE_PROPERTY_FOR_FILTERS, ISearchParameterValues, GenericFilterType,
  SECTIONNAMES_GROUPBY_PROPERTY, SectionName, Topic, Country, Field, Rig, Formation, ReportType, SectionType, ISearchQuery,
} from '../store/searchParameters/searchParametersTypes';
import { formatReportType } from '../common/formatters';
import { SearchDatePicker } from './SearchDatePicker';
import { NumberInput } from './NumberInput';
import {
  getSearchText, getIsSearchTextErroneous, getSearchTextFieldHelperText, getIsSearchButtonDisabled, getSearchQuery,
} from '../store/searchParameters/searchParametersSelectors';
import { setSearchText, setSelectedParameters, getFacetableFields } from '../store/searchParameters/actions';
import { SearchTextInput } from './SearchTextInput';
import { ReportSearchParametersActionTypes } from '../store/searchParameters/actionTypes';
import { AppState } from '../store';
import { thunkUpdateQueryAndUrl, UserPrefsActionTypes } from '../store/userPrefs/types';
import { IConfiguration, getConfiguration } from '../config/configuration';

export interface OwnProps {
    searchParameterValidValues: SearchParameterAvailableValues,
    onSearchClick: () => void
}

interface StateProps {
    userSearchText: string,
    isSearchTextErroneous: boolean,
    searchTextFieldHelperText: string,
    isSearchButtonDisabled: boolean,
    userSearchParameters: ISearchQuery,
}

interface DispatchProps {
    updateSearchParameter: (key: keyof ISearchParameterValues, value: GenericFilterType[] | Date | Number | Boolean) => void,
    updateSearchText: (text: string) => void,
    getFacetableFields: () => void,
}

type Props = StateProps & DispatchProps & OwnProps

const useStyles = makeStyles({
  gridCell: {
    // bottom line of dropdowns should be aligned vertically
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  searchGridCell: {
    display: 'flex',
    flexDirection: 'column',
  },
  lucene: {
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
});

const appConfig: IConfiguration = getConfiguration();

const { luceneDocsUrl } = appConfig;

const SearchParametersPanel : React.FC<Props> = ({
  searchParameterValidValues,
  userSearchParameters,
  updateSearchParameter,
  userSearchText,
  isSearchTextErroneous,
  isSearchButtonDisabled,
  searchTextFieldHelperText,
  updateSearchText,
  onSearchClick,
  getFacetableFields,
}) => {
  // Styles
  const classes = useStyles();

  const luceneSearchKey = 'lucene';

  const handleLuceneSwitchChange = (event: ChangeEvent<any>) => updateSearchParameter(luceneSearchKey, event.target.checked);

  const handleChangeDropdown = <T extends GenericFilterType>(searchKey: keyof ISearchParameterValues, value: T[]) => {
    updateSearchParameter(searchKey, value);
    getFacetableFields();
  };

  return (
    <Grid container spacing={3}>
      {/* Row 1 */}
      <Grid item xs={2} className={classes.gridCell}>
        <ParameterMultiSelectDropdown<Country>
          label="Country"
          searchKey="countries"
          selectableValues={searchParameterValidValues.countries}
          selectedValues={userSearchParameters.searchParams.countries}
          onChange={handleChangeDropdown}
          selectableProperty={SELECTABLE_PROPERTY_FOR_FILTERS}
        />
      </Grid>
      <Grid item xs={2} className={classes.gridCell}>
        <ParameterMultiSelectDropdown<Field>
          label="Field"
          searchKey="fields"
          selectableValues={searchParameterValidValues.fields}
          selectedValues={userSearchParameters.searchParams.fields}
          onChange={handleChangeDropdown}
          selectableProperty={SELECTABLE_PROPERTY_FOR_FILTERS}
        />
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <ParameterMultiSelectDropdownVirtualized
          label="Wellbore"
          searchKey="wellbores"
          selectableValues={searchParameterValidValues.wellbores}
          selectedValues={userSearchParameters.searchParams.wellbores}
          onChange={handleChangeDropdown}
          selectableProperty={SELECTABLE_PROPERTY_FOR_FILTERS}
        />
      </Grid>
      <Grid item xs={2} className={classes.gridCell}>
        <ParameterMultiSelectDropdown<SectionType>
          label="Section type"
          searchKey="sectionTypes"
          selectableValues={searchParameterValidValues.sectionTypes}
          selectedValues={userSearchParameters.searchParams.sectionTypes}
          onChange={handleChangeDropdown}
          selectableProperty={SELECTABLE_PROPERTY_FOR_FILTERS}
        />
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <ParameterMultiSelectDropdown<SectionName>
          label="Section"
          searchKey="sectionNames"
          selectableValues={searchParameterValidValues.sectionNames}
          selectedValues={userSearchParameters.searchParams.sectionNames}
          onChange={handleChangeDropdown}
          selectableProperty={SELECTABLE_PROPERTY_FOR_FILTERS}
          groupBy={SECTIONNAMES_GROUPBY_PROPERTY}
        />
      </Grid>

      {/* Row 2 */}
      <Grid item xs={3} className={classes.gridCell}>
        <ParameterMultiSelectDropdown<Rig>
          label="Rig"
          searchKey="rigs"
          selectableValues={searchParameterValidValues.rigs}
          selectedValues={userSearchParameters.searchParams.rigs}
          onChange={handleChangeDropdown}
          selectableProperty={SELECTABLE_PROPERTY_FOR_FILTERS}
        />
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <ParameterMultiSelectDropdown<Formation>
          label="Formation"
          searchKey="formations"
          selectableValues={searchParameterValidValues.formations}
          selectedValues={userSearchParameters.searchParams.formations}
          onChange={handleChangeDropdown}
          selectableProperty={SELECTABLE_PROPERTY_FOR_FILTERS}
        />
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <ParameterMultiSelectDropdown<ReportType>
          label="Report type"
          searchKey="reportTypes"
          selectableValues={searchParameterValidValues.reportTypes}
          selectedValues={userSearchParameters.searchParams.reportTypes}
          onChange={handleChangeDropdown}
          selectableProperty={SELECTABLE_PROPERTY_FOR_FILTERS}
          formatter={formatReportType}
        />
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <ParameterMultiSelectDropdown<Topic>
          label="Topics"
          searchKey="topics"
          selectableValues={searchParameterValidValues.topics}
          selectedValues={userSearchParameters.searchParams.topics}
          onChange={handleChangeDropdown}
          selectableProperty={SELECTABLE_PROPERTY_FOR_FILTERS}
        />
      </Grid>

      {/* Row 3 */}
      <Grid item xs={3} className={classes.gridCell}>
        <SearchDatePicker
          id="start-date"
          label="Start date"
          searchKey="startDate"
          selectedValue={userSearchParameters.searchParams.startDate}
          onChange={updateSearchParameter}
          onBlur={getFacetableFields}
        />
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <SearchDatePicker
          id="end-date"
          label="End date"
          searchKey="endDate"
          selectedValue={userSearchParameters.searchParams.endDate}
          onChange={updateSearchParameter}
          onBlur={getFacetableFields}
        />
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <NumberInput
          id="start-depth"
          label="Start depth"
          searchKey="startDepth"
          selectedValue={userSearchParameters.searchParams.startDepth}
          onChange={updateSearchParameter}
          onBlur={getFacetableFields}
        />
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <NumberInput
          id="end-depth"
          label="End depth"
          searchKey="endDepth"
          selectedValue={userSearchParameters.searchParams.endDepth}
          onChange={updateSearchParameter}
          onBlur={getFacetableFields}
        />
      </Grid>

      {/* Row separator */}
      <Grid item xs={12} />

      {/* Row search */}
      <Grid item xs={1} className={classes.searchGridCell} />
      <Grid item xs={1} className={classes.searchGridCell}>
        <div>Search</div>
      </Grid>
      <Grid item xs={5} className={classes.searchGridCell}>
        <SearchTextInput
          value={userSearchText}
          error={isSearchTextErroneous}
          helperText={searchTextFieldHelperText}
          onChange={updateSearchText}
          onBlur={getFacetableFields}
        />
      </Grid>
      <Grid item xs={2} className={classes.searchGridCell}>
        <div className={classes.lucene}>
          <FormControl fullWidth>
            <FormControlLabel
              control={(
                <Switch
                  id="lucene"
                  checked={Boolean(userSearchParameters.searchParams.lucene)}
                  onChange={handleLuceneSwitchChange}
                  color="primary"
                  name="Lucene"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                          )}
              label={(
                <div>
                  Lucene
                  <Tooltip
                    title={(
                      <div>
                        Learn more about Lucene here:
                        {/* TODO: replace Link to Button and remove eslint-disable */}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link href="#" onClick={() => window.open(luceneDocsUrl)} color="inherit" underline="always">
                          {luceneDocsUrl}
                          {' '}
                          <OpenInNewIcon fontSize="inherit" />
                        </Link>
                      </div>
                    )}
                    interactive
                  >
                    <IconButton
                      size="small"
                      color="primary"
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                          )}
              labelPlacement="start"
            />
          </FormControl>
        </div>
      </Grid>
      <Grid item xs={2} className={classes.searchGridCell}>
        <FormControl fullWidth>
          <Button id="search-button" variant="contained" color="primary" onClick={onSearchClick} disabled={isSearchButtonDisabled}>
            Search
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={2} className={classes.searchGridCell} />

      <Grid item xs={12} />
    </Grid>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    userSearchText: getSearchText(state),
    isSearchTextErroneous: getIsSearchTextErroneous(state),
    searchTextFieldHelperText: getSearchTextFieldHelperText(state),
    isSearchButtonDisabled: getIsSearchButtonDisabled(state),
    userSearchParameters: getSearchQuery(state),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, void, ReportSearchParametersActionTypes | UserPrefsActionTypes>): DispatchProps {
  return {
    updateSearchParameter: (key: keyof ISearchParameterValues, value: GenericFilterType[] | Date | Number | Boolean) => dispatch(
      thunkUpdateQueryAndUrl(setSelectedParameters(key, value)),
    ),
    updateSearchText: (text: string) => dispatch(thunkUpdateQueryAndUrl(setSearchText(text))),
    getFacetableFields: () => dispatch(thunkUpdateQueryAndUrl(getFacetableFields())),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SearchParametersPanel);
