import { SearchQueryForAPI } from '../../store/searchParameters/searchParametersTypes';
import { AppInsightsEvent } from './appInsights';

const REPORT_SEARCH_EXECUTE = 'REPORT_SEARCH_EXECUTE';

export class ReportSearchEvent extends AppInsightsEvent {
  constructor(public searchQuery: SearchQueryForAPI) {
    super(REPORT_SEARCH_EXECUTE, searchQuery);
  }
}
