import * as React from 'react';
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  FormLabel,
  DialogContentText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ReportSearchApi } from '../services/reportSearchApi';

export interface FeedbackModalProps { open: boolean, onClose: () => void }
export function FeedbackModal(props: FeedbackModalProps) {
  const reportSearchApi = new ReportSearchApi();
  const [feedbackFiles, setfeedbackFiles] = React.useState<Array<File>>([]);
  const [feedbackTitle, setfeedbackTitle] = React.useState('');
  const [feedbackDescription, setfeedbackDescription] = React.useState('');

  const createQueryObject = () => ({
    Title: feedbackTitle,
    Description: feedbackDescription,
    Url: window.location.href,
    Browser: getBrowser(),
  });

  const getBrowser = () => {
    if ((navigator.userAgent.indexOf('Opera') !== -1 || navigator.userAgent.indexOf('OPR')) !== -1) {
      return 'Opera';
    }
    if (navigator.userAgent.indexOf('Edg') !== -1) {
      return 'Edge';
    }
    if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome';
    }
    if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    }
    if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox';
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1) {
      return 'IE';
    }
    return 'Other';
  };

  const handleSubmitFeedbackDialog = () => {
    const query = createQueryObject();
    reportSearchApi.saveFeedback(query, feedbackFiles).then(() => {
      // Provide a toast when feedback has been stored
      setfeedbackDescription('');
      setfeedbackTitle('');
      setfeedbackFiles([]);
      props.onClose();
    });
  };

  const handleFeedbackFileUpload = (event: React.ChangeEvent) => {
    if (!event || !event.target) {
      return;
    }
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    const validFileTypes = ['.jpg', '.jpeg', '.png', '.svg', '.txt', '.csv', '.json', '.pdf'];
    const inputFiles: Array<File> = Array.from(files);
    const uploadedFiles: Array<File> = Object.assign([], feedbackFiles);
    const invalidFiles = [];
    for (let index = 0; index < inputFiles.length; index += 1) {
      const inputFile = inputFiles[index];
      if (validFileTypes.indexOf(inputFile.name.substring(inputFile.name.lastIndexOf('.')).toLowerCase()) === -1) {
        invalidFiles.push(inputFile);
      } else if (uploadedFiles.findIndex((x) => x.name === inputFile.name) === -1) {
        uploadedFiles.push(inputFile);
      }
    }
    setfeedbackFiles(uploadedFiles);
  };

  const handleRemoveFile = (filename: string) => {
    setfeedbackFiles(feedbackFiles.filter((x: File) => x.name !== filename));
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>DIWA feedback</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Title*"
          placeholder="Enter a short title here"
          fullWidth
          variant="standard"
          InputLabelProps={{ shrink: true }}
          value={feedbackTitle}
          onChange={(e) => setfeedbackTitle(e.target.value)}
        />
        <TextField
          margin="dense"
          id="name"
          label="Description*"
          fullWidth
          variant="standard"
          placeholder="Enter your feedback/problem here"
          multiline
          InputLabelProps={{ shrink: true }}
          value={feedbackDescription}
          onChange={(e) => setfeedbackDescription(e.target.value)}
        />
        <input
          id="files"
          type="file"
          name="attachments"
          multiple
          accept=".jpg, .jpeg, .png, .svg, .pdf, .csv, .txt, .json"
          hidden
          onChange={(e) => handleFeedbackFileUpload(e)}
        />
        <DialogContentText>
          Attachments
        </DialogContentText>
        <FormLabel htmlFor="files">
          <Button variant="contained" color="primary" component="div">
            Select file(s)
          </Button>
        </FormLabel>
        <div className="files">
          {feedbackFiles.map((file) => (
            <DialogContentText>
              {file.name}
              <IconButton aria-label="delete" size="small" onClick={() => handleRemoveFile(file.name)}>
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </DialogContentText>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button disabled={!feedbackTitle || !feedbackDescription} onClick={handleSubmitFeedbackDialog}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export default FeedbackModal;
