/* TODO: replace <a> to Button and remove eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import brandLogo from '../resources/images/equinor-logo.png';
import userIcon from '../resources/images/userIcon.png';
import feedbackIcon from '../resources/images/comment.svg';
import './AppHeader.css';
import { getAuthServiceSingleton, getLoginErrorMessage } from '../services/authentication/authService';
import { getUserIdentity } from '../store/auth/selectors';
import { getServiceNowFeedbackUrl } from '../store/searchParameters/searchParametersSelectors';
import packageInfo from '../../package.json';
import { InfoMessagePanel } from './Messages/InfoMessagePanel';
import { reportErrorWithCustomMessage } from '../store/appMessages/actions';
import { SystemMessage } from '../store/appMessages/types';
import { FeedbackModal } from '../components/FeedbackModal';

export function AppHeader() {
  const dispatch = useDispatch();
  const authService = getAuthServiceSingleton();
  const userIdentity = useSelector(getUserIdentity);
  const serviceNowFeedbackUrl = useSelector(getServiceNowFeedbackUrl);
  const userDisplayName = userIdentity == null ? '' : `${userIdentity.userName} (${userIdentity.userId})`;
  const history = useHistory();

  const [feedbackModalOpen, setfeedbackModalOpen] = React.useState(false);

  // Replaces CSS calc for ".flex-master" height. Increases performance
  $(window).on('resize', () => calculateFlexMasterHeight());
  $(() => calculateFlexMasterHeight());

  const calculateFlexMasterHeight = () => $('.flex-master').css('height', '100vh').css('height', '-=50px');

  const loginHandler = () => {
    authService.loginUser('', (redirectUrl : string) => history.replace(redirectUrl))
      .catch((e) => {
        dispatch(reportErrorWithCustomMessage(new SystemMessage(getLoginErrorMessage(e)), e));
      });
  };

  const redirectToAdminUI = () => {
    const { hostname } = window.location;
    const domain = hostname.split('.').slice(-3).join('.');
    const adminUrl = hostname === 'localhost' ? '#' : `https://admin.${domain}`;
    window.location.replace(adminUrl);
  };

  return (
    <div
      className="navbar navbar-expand-md app-header"
      style={{
        position: 'fixed', width: '100%', zIndex: 20, padding: '0',
      }}
    >
      {/* {this.props.loading && <Loader id="header" />} */}
      <div className="link">
        {/* to={'/'} onClick={() => this.onNavigateHome()} exact */}
        <div className="brand">
          <a className="nav-link" style={{ paddingTop: '0', paddingBottom: '0' }} href={`/${authService.getPopupAuthSearchParameter()}`}>
            <img className="brand-logo" alt="Equinor" src={brandLogo} />
          </a>
        </div>
      </div>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#header-menu" aria-controls="header-menu" aria-expanded="false">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="header-menu">
        <ul className="navbar-nav">
          <a className="nav-link" style={{ paddingTop: '0', paddingBottom: '0' }} href={`/${authService.getPopupAuthSearchParameter()}`}>
            <h1 style={{ fontSize: '18px', marginBottom: '0px', color: '#FF0000' }}>
              <span className="nav-link" style={{ paddingTop: '0', paddingBottom: '0' }}>Report search</span>
            </h1>
          </a>
        </ul>
        <ul className="navbar-nav mr-auto">
          <li className="nav-item"><InfoMessagePanel /></li>
        </ul>
        <ul
          className="navbar-nav ml-auto"
          style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          <li className="nav-item dropdown" style={{ verticalAlign: 'middle' }}>
            <a className="nav-link " href="#" id="feedbackDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="menu-item">
                <img className="feedbackIcon" alt="feedback" src={feedbackIcon} style={{ marginRight: '20px', marginTop: '0px' }} />
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-right navbar-dark" style={{ marginRight: '1rem' }}>
              <a className="dropdown-item" href="#" onClick={() => setfeedbackModalOpen(true)} role="menuitem">
                Feedback
              </a>
              <div className="dropdown-divider" />
              <a
                className="dropdown-item"
                href={serviceNowFeedbackUrl}
                role="menuitem"
              >
                Report bug
              </a>
            </div>
          </li>
          <li className="nav-item dropdown" style={{ verticalAlign: 'middle' }}>
            {/* TODO: replace Link to Button and remove eslint-disable */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="nav-link "
              style={{ paddingTop: '0', paddingBottom: '0' }}
              href="#"
              id="userDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="menu-item">
                <img className="userIcon" alt="user" src={userIcon} style={{ marginRight: '20px', marginTop: '0px' }} />
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-right navbar-dark">
              <div className="dropdown-header">
                <div>{userDisplayName}</div>
                <div>
                  version:
                  {packageInfo.version}
                </div>
              </div>
              <div className="dropdown-divider" />
              {authService.isLoggedIn()
                ? (
                  <>
                    { userIdentity?.roles.some((role) => role === 'diwa_admin')
                      ? <a tabIndex={0} href="#" className="dropdown-item" role="menuitem" onClick={redirectToAdminUI}>Admin</a>
                      : '' }
                    <a tabIndex={0} href="#" className="dropdown-item" role="menuitem" onClick={() => { authService.logout(); }}>Sign out</a>
                  </>
                )
                : <a tabIndex={0} href="#" className="dropdown-item" role="menuitem" onClick={loginHandler}>Sign in</a>}
            </div>
          </li>
        </ul>
      </div>
      <FeedbackModal open={feedbackModalOpen} onClose={() => setfeedbackModalOpen(false)} />
    </div>
  );
}
