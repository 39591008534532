import { ReportQueryForAPI } from '../searchParameters/searchParametersTypes';
import { IReport } from '../searchResults/searchResultTypes';
import { IRelatedReportsResponse } from './types';

// ReportRetrieval ----------------------------------------
export const EXECUTE_REPORT_RETRIEVAL_REQUESTED = 'EXECUTE_REPORT_RETRIEVAL_REQUESTED';

export interface ExecuteReportRetrievalActionRequested {
    type: typeof EXECUTE_REPORT_RETRIEVAL_REQUESTED;
    reportQuery: ReportQueryForAPI;
}

export const EXECUTE_REPORT_RETRIEVAL_SUCCEEDED = 'EXECUTE_REPORT_RETRIEVAL_SUCCEEDED';

export interface ExecuteReportRetrievalActionSucceeded {
    type: typeof EXECUTE_REPORT_RETRIEVAL_SUCCEEDED;
    reportResult: IReport;
}

// GetRelatedReports --------------------------------------
export const GET_RELATED_REPORTS_REQUESTED = 'GET_RELATED_REPORTS_REQUESTED';

export interface GetRelatedReportsActionRequested {
    type: typeof GET_RELATED_REPORTS_REQUESTED;
    reportQuery: ReportQueryForAPI;
}

export const GET_RELATED_REPORTS_SUCCEEDED = 'GET_RELATED_REPORTS_SUCCEEDED';

export interface GetRelatedReportsActionSucceeded {
    type: typeof GET_RELATED_REPORTS_SUCCEEDED;
    relatedReportsResponse: IRelatedReportsResponse;
}

export const GET_RELATED_REPORTS_FAILED = 'GET_RELATED_REPORTS_FAILED';

export interface GetRelatedReportsActionFailed {
    type: typeof GET_RELATED_REPORTS_FAILED;
}

// LoadMoreRelatedReports --------------------------------------
export const LOAD_MORE_RELATED_REPORTS_REQUESTED = 'LOAD_MORE_RELATED_REPORTS_REQUESTED';

export interface LoadMoreRelatedReportsActionRequested {
    type: typeof LOAD_MORE_RELATED_REPORTS_REQUESTED;
    reportQuery: ReportQueryForAPI;
}

export const LOAD_MORE_RELATED_REPORTS_SUCCEEDED = 'LOAD_MORE_RELATED_REPORTS_SUCCEEDED';

export interface LoadMoreRelatedReportsActionSucceeded {
    type: typeof LOAD_MORE_RELATED_REPORTS_SUCCEEDED;
    relatedReportsResponse: IRelatedReportsResponse;
}

export const LOAD_MORE_RELATED_REPORTS_FAILED = 'LOAD_MORE_RELATED_REPORTS_FAILED';

export interface LoadMoreRelatedReportsActionFailed {
    type: typeof LOAD_MORE_RELATED_REPORTS_FAILED;
}

// GetSimilarReports --------------------------------------
export const GET_SIMILAR_REPORTS_REQUESTED = 'GET_SIMILAR_REPORTS_REQUESTED';

export interface GetSimilarReportsActionRequested {
    type: typeof GET_SIMILAR_REPORTS_REQUESTED;
    reportQuery: ReportQueryForAPI;
}

export const GET_SIMILAR_REPORTS_SUCCEEDED = 'GET_SIMILAR_REPORTS_SUCCEEDED';

export interface GetSimilarReportsActionSucceeded {
    type: typeof GET_SIMILAR_REPORTS_SUCCEEDED;
    similarReportsResponse: IRelatedReportsResponse;
}

export const GET_SIMILAR_REPORTS_FAILED = 'GET_SIMILAR_REPORTS_FAILED';

export interface GetSimilarReportsActionFailed {
    type: typeof GET_SIMILAR_REPORTS_FAILED;
}

// LoadMoreSimilarReports --------------------------------------
export const LOAD_MORE_SIMILAR_REPORTS_REQUESTED = 'LOAD_MORE_SIMILAR_REPORTS_REQUESTED';

export interface LoadMoreSimilarReportsActionRequested {
    type: typeof LOAD_MORE_SIMILAR_REPORTS_REQUESTED;
    reportQuery: ReportQueryForAPI;
}

export const LOAD_MORE_SIMILAR_REPORTS_SUCCEEDED = 'LOAD_MORE_SIMILAR_REPORTS_SUCCEEDED';

export interface LoadMoreSimilarReportsActionSucceeded {
    type: typeof LOAD_MORE_SIMILAR_REPORTS_SUCCEEDED;
    similarReportsResponse: IRelatedReportsResponse;
}

export const LOAD_MORE_SIMILAR_REPORTS_FAILED = 'LOAD_MORE_SIMILAR_REPORTS_FAILED';

export interface LoadMoreSimilarReportsActionFailed {
    type: typeof LOAD_MORE_SIMILAR_REPORTS_FAILED;
}

// ClearRelatedReports -------------------------------------
export const EXECUTE_CLEAR_RELATED_REPORTS = 'EXECUTE_CLEAR_RELATED_REPORTS';

export interface ClearRelatedReportsAction {
    type: typeof EXECUTE_CLEAR_RELATED_REPORTS;
}

export type ReportDetailsActionTypes =
    ExecuteReportRetrievalActionRequested | ExecuteReportRetrievalActionSucceeded

    | GetSimilarReportsActionRequested | GetSimilarReportsActionSucceeded | GetSimilarReportsActionFailed
    | LoadMoreRelatedReportsActionRequested | LoadMoreRelatedReportsActionSucceeded | LoadMoreRelatedReportsActionFailed

    | GetRelatedReportsActionRequested | GetRelatedReportsActionSucceeded | GetRelatedReportsActionFailed
    | LoadMoreSimilarReportsActionRequested | LoadMoreSimilarReportsActionSucceeded | LoadMoreSimilarReportsActionFailed
    | ClearRelatedReportsAction;
