import { updateUrlQuery } from '../../common/urlParameters/urlParameterConverter';
import { ISearchQuery } from '../searchParameters/searchParametersTypes';
import { Report } from '../searchResults/searchResultTypes';
import {
  SET_HEADER_EXPANDED, SET_SHARE_DIALOG_OPEN, SET_SHARE_URL, UPDATE_SELECTED_REPORTS, UPDATE_SHARE_URL, UserPrefsActionTypes,
} from './types';

interface IUserPrefsState {
  selectedReports: Report[],
  shareUrl : string,
  headerExpanded: boolean,
  shareDialogOpen: boolean,
}

const initialState: IUserPrefsState = {
  selectedReports: [],
  shareUrl: '',
  headerExpanded: true,
  shareDialogOpen: false,
};

const UpdateSelectedReports = (selectedReports: Report[], reportToUpdate: Report, reportIsSelected: boolean) => {
  if (reportIsSelected) {
    if (selectedReports.filter((r) => r.id === reportToUpdate.id).length > 0) {
      return selectedReports;
    }
    return selectedReports.concat(reportToUpdate);
  }
  return selectedReports.filter((r) => r.id !== reportToUpdate.id);
};

const UpdateShareUrl = (query: ISearchQuery, selectedReports: Report[]) => updateUrlQuery(query, selectedReports);

export function userPrefsReducer(
  state = initialState,
  action: UserPrefsActionTypes,
): IUserPrefsState {
  switch (action.type) {
    case UPDATE_SELECTED_REPORTS:
      return {
        ...state,
        selectedReports: UpdateSelectedReports(state.selectedReports, action.report, action.isReportSelected),
      };

    case UPDATE_SHARE_URL:
      return {
        ...state,
        shareUrl: UpdateShareUrl(action.query, state.selectedReports),
      };

    case SET_SHARE_URL:
      return {
        ...state,
        shareUrl: action.url,
      };

    case SET_HEADER_EXPANDED:
      return {
        ...state,
        headerExpanded: action.expanded,
      };

    case SET_SHARE_DIALOG_OPEN:
      return {
        ...state,
        shareDialogOpen: action.dialogOpen,
      };
    default:
      return state;
  }
}
