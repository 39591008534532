import { httpGet, httpPost, submitFeedback } from './httpClient';
import {
  IInitialInformationResponse, SearchQueryForAPI, ReportQueryForAPI, SearchParameterAvailableValues,
} from '../store/searchParameters/searchParametersTypes';
import { getConfiguration } from '../config/configuration';
import { IReport, ISearchResult } from '../store/searchResults/searchResultTypes';
import { IReportKey } from '../store/common/types';
import { IRelatedReportsRequest, IRelatedReportsResponse } from '../store/reportDetails/types';

export interface GetReportListByTypeAndIdResponse {
    reports : Array<IReport>;
}
export interface AppInsightsInstrumentationKeyResponse {
  appInsigthsInstrumentationKey: string;
}
type getReportListByTypeAndIdType = (reportKeys: Array<IReportKey>) => Promise<GetReportListByTypeAndIdResponse>;
type getRelatedReportsByTypeAndIdType = (userQuery: IRelatedReportsRequest) => Promise<IRelatedReportsResponse>;
type getSimilarReportsByTypeAndId = (userQuery: IRelatedReportsRequest) => Promise<IRelatedReportsResponse> ;

export class ReportSearchApi {
  constructor() {
    const appConfig = getConfiguration();
    this.baseUrl = appConfig.apiBaseUrl;
  }

  private baseUrl : string;

  public getInitialInformation = () : Promise<IInitialInformationResponse> => httpGet<IInitialInformationResponse>(`${this.baseUrl}/GetInitialInformation`);

  public getAppInsightsKey = () : Promise<AppInsightsInstrumentationKeyResponse> => httpGet<AppInsightsInstrumentationKeyResponse>(
    `${this.baseUrl}/GetAppInsightsInstrumentationKey`,
  );

  public saveFeedback = (userQuery: {}, files: File[]) : Promise<any> => submitFeedback(
    `${this.baseUrl}/Feedback`,
    userQuery,
    files,
  );

  public searchReports = (userQuery: SearchQueryForAPI) : Promise<ISearchResult> => httpPost<SearchQueryForAPI, ISearchResult>(
    `${this.baseUrl}/SearchReportsInternal`,
    userQuery,
  );

  public getFacets = (userQuery: SearchQueryForAPI) : Promise<SearchParameterAvailableValues> => httpPost<SearchQueryForAPI, SearchParameterAvailableValues>(
    `${this.baseUrl}/GetFacets`,
    userQuery,
  );

  public getReportByTypeAndId = (userQuery: ReportQueryForAPI) : Promise<IReport> => httpPost<ReportQueryForAPI, IReport>(
    `${this.baseUrl}/GetReportByTypeAndId`,
    userQuery,
  );

  public getReportListByTypeAndId : getReportListByTypeAndIdType = (reportKeys) => httpPost<Array<IReportKey>, GetReportListByTypeAndIdResponse>(
    `${this.baseUrl}/GetReportListByTypeAndId`,
    reportKeys,
  );

  public getRelatedReportsByTypeAndId : getRelatedReportsByTypeAndIdType = (userQuery) => httpPost<IRelatedReportsRequest, IRelatedReportsResponse>(
    `${this.baseUrl}/GetRelatedReportsByTypeAndId`,
    userQuery,
  );

  public getSimilarReportsByTypeAndId : getSimilarReportsByTypeAndId = (userQuery) => httpPost<IRelatedReportsRequest, IRelatedReportsResponse>(
    `${this.baseUrl}/GetSimilarReportsByTypeAndId`,
    userQuery,
  );
}
