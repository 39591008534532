import {
  ExecuteSearchSucceededAction, EXECUTE_SEARCH_SUCCEEDED, SetSelectedResultAction, SET_SELECTEDRESULT, SetIsUserCollectedAction,
  SET_IS_USER_COLLECTED, GET_NEXT_RESULTS_PAGE, GET_NEXT_RESULTS_PAGE_SUCCEEDED, GetNextResultsPage, GetNextResultsPageSucceeded,
  SET_NEXT_RESULTS_PAGE_REQUESTED, SetNextResultsPageRequested,
} from './actionTypes';
import { ISearchResult, Report } from './searchResultTypes';

export const executeSearchSucceeded = (searchResult: ISearchResult, userSelectedReports: Report[]): ExecuteSearchSucceededAction => ({
  type: EXECUTE_SEARCH_SUCCEEDED,
  searchResult,
  userSelectedReports,
});

export const setSelectedResult = (selectedResult: Report | null): SetSelectedResultAction => ({
  type: SET_SELECTEDRESULT,
  selectedResult,
});

export const setIsUserCollected = (reportId : string, isUserCollectedValue : boolean): SetIsUserCollectedAction => ({
  type: SET_IS_USER_COLLECTED,
  reportId,
  isUserCollected: isUserCollectedValue,
});

export const getNextResultsPage = (startIndex: number, stopIndex: number): GetNextResultsPage => ({
  type: GET_NEXT_RESULTS_PAGE,
  startIndex,
  stopIndex,
});

export const getNextResultsPageSucceeded = (searchResult: ISearchResult): GetNextResultsPageSucceeded => ({
  type: GET_NEXT_RESULTS_PAGE_SUCCEEDED,
  searchResult,
});

export const setNextResultsPageRequested = (): SetNextResultsPageRequested => ({
  type: SET_NEXT_RESULTS_PAGE_REQUESTED,
});
