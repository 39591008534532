import { ReportQueryForAPI } from '../searchParameters/searchParametersTypes';
import { Report, ISearchResult } from './searchResultTypes';

export const EXECUTE_SEARCH_SUCCEEDED = 'EXECUTE_SEARCH_SUCCEDED';

export interface ExecuteSearchSucceededAction {
    type: typeof EXECUTE_SEARCH_SUCCEEDED;
    searchResult: ISearchResult;
    userSelectedReports: Report[];
}

export const EXECUTE_REPORTRETRIEVAL_REQUESTED = 'EXECUTE_REPORTRETRIEVAL_REQUESTED';

export interface ExecuteReportRetrievalActionRequested {
    type: typeof EXECUTE_REPORTRETRIEVAL_REQUESTED;
    reportQuery: ReportQueryForAPI;
}

export const EXECUTE_REPORTRETRIEVAL_SUCCEEDED = 'EXECUTE_REPORTRETRIEVAL_SUCCEEDED';

export interface ExecuteReportRetrievalActionSucceeded {
    type: typeof EXECUTE_REPORTRETRIEVAL_SUCCEEDED;
    reportResult: Report;
}

export const SET_SELECTEDRESULT = 'SET_SELECTEDRESULT';

export interface SetSelectedResultAction {
    type: typeof SET_SELECTEDRESULT;
    selectedResult: Report | null;
}

export const SET_IS_USER_COLLECTED = 'SET_IS_USER_COLLECTED';
export interface SetIsUserCollectedAction {
    type: typeof SET_IS_USER_COLLECTED,
    reportId: string,
    isUserCollected: boolean
}

export const GET_NEXT_RESULTS_PAGE = 'GET_NEXT_RESULTS_PAGE';

export interface GetNextResultsPage {
    type: typeof GET_NEXT_RESULTS_PAGE;
    startIndex: number;
    stopIndex: number;
}

export const GET_NEXT_RESULTS_PAGE_SUCCEEDED = 'GET_NEXT_RESULTS_PAGE_SUCCEEDED';

export interface GetNextResultsPageSucceeded {
    type: typeof GET_NEXT_RESULTS_PAGE_SUCCEEDED;
    searchResult: ISearchResult;
}

export const SET_NEXT_RESULTS_PAGE_REQUESTED = 'SET_NEXT_RESULTS_PAGE_REQUESTED';

export interface SetNextResultsPageRequested {
    type: typeof SET_NEXT_RESULTS_PAGE_REQUESTED;
}

export type ReportSearchResultsActionTypes = ExecuteSearchSucceededAction | GetNextResultsPage | GetNextResultsPageSucceeded
    | SetNextResultsPageRequested | SetSelectedResultAction | SetIsUserCollectedAction;
