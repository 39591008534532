import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Button, CircularProgress } from '@material-ui/core';
import { getProgressMessage } from '../../store/appMessages/selectors';
import { hideProgress } from '../../store/appMessages/actions';

import { MESSAGESPANEL_DIV_ID } from '../../store/searchResults/searchResultTypes';

export function InfoMessagePanel() {
  const progressMessage = useSelector(getProgressMessage);
  const dispatch = useDispatch();
  return (
    <div id={MESSAGESPANEL_DIV_ID}>
      { progressMessage !== ''
        && (
          <Alert
            icon={<CircularProgress size={20} />}
            severity="info"
            action={(
              <Button
                onClick={() => dispatch(hideProgress())}
                color="inherit"
                size="small"
              >
                X
              </Button>
            )}
          >
            { progressMessage }
          </Alert>
        )}
    </div>
  );
}
