import {
  all, call, fork, put, takeEvery, select,
} from 'typed-redux-saga';
import { RESULTS_DIV_ID, MESSAGESPANEL_DIV_ID } from './searchResultTypes';
import { ReportSearchApi } from '../../services/reportSearchApi';
import { SystemMessage } from '../appMessages/types';
import { showProgress, hideProgress, reportError } from '../appMessages/actions';
import { moveViewToDiv } from '../../components/Animator';
import { appInsightsTrackEvent } from '../../services/appInsights/appInsights';
import { ReportSearchEvent } from '../../services/appInsights/reportSearchInsightsEvents';
import { EXECUTE_SEARCH_REQUESTED } from '../searchParameters/actionTypes';
import { getSearchQueryForAPI } from '../searchParameters/searchParametersSelectors';
import { executeSearchSucceeded, getNextResultsPageSucceeded } from './actions';
import { getSelectedReports } from '../userPrefs/selectors';
import { GET_NEXT_RESULTS_PAGE } from './actionTypes';
import { setHeaderExpanded } from '../userPrefs/actions';

function* handleExecuteSearch() {
  const reportSearchApi = new ReportSearchApi();

  yield put(showProgress(new SystemMessage('Getting search results from server')));

  try {
    moveViewToDiv(MESSAGESPANEL_DIV_ID);

    const userQuery = yield select(getSearchQueryForAPI);
    userQuery.skip = 0;

    yield put(setHeaderExpanded(false));

    const res = yield call(reportSearchApi.searchReports, userQuery);
    appInsightsTrackEvent(new ReportSearchEvent(userQuery));
    const previouslySelectedReports = yield select(getSelectedReports);
    yield put(executeSearchSucceeded(res, previouslySelectedReports));
    moveViewToDiv(RESULTS_DIV_ID);
  } catch (err) {
    yield put(reportError(err));
  }

  yield put(hideProgress());
}

function* watchExecuteSearch() {
  yield takeEvery(EXECUTE_SEARCH_REQUESTED, handleExecuteSearch);
}

function* handleGetNextResultsPage() {
  const reportSearchApi = new ReportSearchApi();

  try {
    const userQuery = yield select(getSearchQueryForAPI);

    const res = yield call(reportSearchApi.searchReports, userQuery);
    yield put(getNextResultsPageSucceeded(res));
  } catch (err) {
    yield put(reportError(err));
  }

  yield put(hideProgress());
}

function* watchGetNextResultsPage() {
  yield takeEvery(GET_NEXT_RESULTS_PAGE, handleGetNextResultsPage);
}

function* searchResultSaga() {
  yield all([fork(watchExecuteSearch), fork(watchGetNextResultsPage)]);
}

export default searchResultSaga;
