import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import MainPage from './MainPage';
import { AppHeader } from './AppHeader';
import { ErrorBoundary } from './ErrorBoundary';
import { ErrorMessagePanel } from './Messages/ErrorMessagePanel';
import { UnauthorizedPage } from './UnauthorizedPage';
import { ReportDetailsPage } from './ReportDetailsPage';
import { SignedOutPage } from './SignedOut';
import './App.css';

export function App() {
  return (
    <div>
      <AppHeader />
      <ErrorBoundary>
        <div className="appHeaderSpacing" />
        <ErrorMessagePanel />
        <Switch>
          <Route exact path="/">
            <MainPage />
          </Route>
          <Route exact path="/unauthorized">
            <UnauthorizedPage />
          </Route>
          <Route exact path="/signedout">
            <SignedOutPage />
          </Route>
          <Route exact path="/ReportDetails/:reportType/:reportId">
            <ReportDetailsPage />
          </Route>
        </Switch>
      </ErrorBoundary>
    </div>
  );
}

// For initial rendering of containers
export const useMountEffect = (eff: React.EffectCallback, dependency?: any) => React.useEffect(eff, [dependency]);
