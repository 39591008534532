import {
  PublicClientApplication, Configuration, InteractionRequiredAuthError, AccountInfo, BrowserAuthError, AuthenticationResult,
} from '@azure/msal-browser';
import { IConfiguration, getConfiguration } from '../../config/configuration';
import { UserIdentity } from '../../store/auth/types';
import { store } from '../../store';
import { LoginSucceeded, LoginFailed } from '../../store/auth/actions';
import { extractErrorMessage } from '../../common/errorUtils';

const DIWA_INTERNAL_ROLE = 'diwa_internal';

export class LoginInProgressError extends Error {
}

export const LoginErrorMessage : string = 'An error happened during login. ';

export const getLoginErrorMessage = (e : any) : string => LoginErrorMessage + extractErrorMessage(e);

export const RESOURCE_NAME_MAIN = 'DIWA';
export const RESOURCE_NAME_GRAPH = 'Graph';
export const AUTH_PARAM_NAME = 'auth';
export const AUTH_PARAM_VALUE_POPUP = 'popup';

interface IdTokenClaims {
  roles : Array<string>
}

export class AuthService {
  constructor() {
    const appConfig: IConfiguration = getConfiguration();

    this.popupAuth = new URLSearchParams(window.location.search).get(AUTH_PARAM_NAME) === AUTH_PARAM_VALUE_POPUP;

    this.appHostName = `${window.location.protocol}//${window.location.hostname}${window.location.port === '80' ? '' : `:${window.location.port}`}`;

    this.msalConfig = {
      auth: {
        authority: appConfig.msal.authority,
        clientId: appConfig.msal.clientId,
        redirectUri: this.appHostName,
        postLogoutRedirectUri: '', // appHostName + "/loggedout.html"
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
    };

    this.msalClient = new PublicClientApplication(this.msalConfig);
  }

  private msalClient : PublicClientApplication;

  private msalConfig : Configuration;

  private popupAuth: boolean;

  private appHostName : string;
  // private unknownErroMesage = "Unknown error happend during login.";

  public isPopupAuth = () => this.popupAuth;

  public async ensureUserLoggedIn() : Promise<void> {
    if (!this.isLoggedIn()) {
      const tokenResponse = await this.handleRedirect();
      const isAuthRedirect = tokenResponse !== null;

      if (!isAuthRedirect) {
        await this.loginUser(window.location.href);
      }
    }

    const account = this.getAccount();
    this.authorize(account);
  }

  private async handleRedirect() : Promise<AuthenticationResult | null> {
    try {
      const tokenResponse = await this.msalClient.handleRedirectPromise();
      return tokenResponse;
    } catch (error) {
      if (this.isAadRoleMissing(error)) {
        this.redirectUnauthorized();
      }

      store.dispatch(LoginFailed(getLoginErrorMessage(error)));
      throw new Error(extractErrorMessage(error));
    }
  }

  private isAadRoleMissing = (error: Error) => error instanceof InteractionRequiredAuthError
    && (error.errorCode === 'interaction_required' || error.errorCode === 'invalid_grant')
    && error.errorMessage.includes('AADSTS50105');

  private authorize(account: AccountInfo | null): void {
    if (account === null) {
      throw new Error('User is not logged (Account is null), cannot authorize.');
    }

    const loggedInUser = this.mapAccountToUserIdentity(account);
    if (!loggedInUser.roles.some((value) => value === DIWA_INTERNAL_ROLE)) {
      this.redirectUnauthorized();
    }
    this.msalClient.setActiveAccount(account);
    store.dispatch(LoginSucceeded(loggedInUser));
  }

  private mapAccountToUserIdentity(account: AccountInfo): UserIdentity {
    // this typeconversion is intentional as roles are array of string insted of string
    if (account === null) {
      throw new Error('Account is null.');
    }

    if (account.idTokenClaims === undefined) {
      throw new Error('idTokenClaims is undefined.');
    }

    if (account.name === undefined) {
      throw new Error('User id (account.name) is empty.');
    }

    return new UserIdentity(account.name, account.username, (account.idTokenClaims as IdTokenClaims).roles);
  }

  private redirectUnauthorized() {
    if (!window.location.href.includes('/unauthorized')) {
      window.location.href = `/unauthorized${this.getPopupAuthSearchParameter()}`;
    }
  }

  public getPopupAuthSearchParameter = () => (this.isPopupAuth() ? `?${AUTH_PARAM_NAME}=${AUTH_PARAM_VALUE_POPUP}` : '');

  public isLoggedIn = () => {
    const accounts = this.msalClient.getAllAccounts();
    return accounts !== null && accounts.length > 0;
  };

  private getAccount = () : AccountInfo | null => (this.isLoggedIn() ? this.msalClient.getAllAccounts()[0] : null);

  public async loginUser(redirectUrl : string = '', afterLoginPopup: ((redirectUrl: string) => void) | null = null): Promise<void> {
    if (this.isLoggedIn()) {
      // authorize will dispatch loginSucceeded to store
      this.authorize(this.getAccount());
      return;
    }

    const loginRequest = {
      scopes: [],
    };

    if (this.popupAuth) {
      try {
        const response = await this.msalClient.loginPopup(loginRequest);
        this.authorize(response.account);
        if (afterLoginPopup) {
          afterLoginPopup('/?auth=popup');
        }
      } catch (error) {
        store.dispatch(LoginFailed(getLoginErrorMessage(error)));

        if (error instanceof BrowserAuthError && error.errorCode === 'popup_window_error') {
          throw Error(' Error opening popup window. Unblock popup using the icon in the address bar.');
        } else if (this.isAadRoleMissing(error)) {
          this.redirectUnauthorized();
        } else {
          throw error;
        }
      }
    } else {
      try {
        const defaultedRedirectUrl = (redirectUrl === '' ? this.appHostName : redirectUrl);
        const loginRedirectRequest = {
          ...loginRequest,
          redirectStartPage: defaultedRedirectUrl,
        };

        await this.msalClient.loginRedirect(loginRedirectRequest);
      } catch (err) {
        store.dispatch(LoginFailed(getLoginErrorMessage(err)));
        throw err;
      }
    }
  }

  public async logout() {
    if (this.popupAuth) {
      const redirectUrl = `${this.appHostName}/signedout${this.getPopupAuthSearchParameter()}`;
      await this.msalClient.logoutPopup({
        postLogoutRedirectUri: redirectUrl,
        mainWindowRedirectUri: redirectUrl,
        account: this.getAccount(),
      });
    } else {
      await this.msalClient.logoutRedirect();
    }
  }

  private getResourceScope(resource: string): string {
    switch (resource) {
      case RESOURCE_NAME_MAIN: return `${this.msalConfig.auth.clientId}/.default`;
      case RESOURCE_NAME_GRAPH: return 'https://graph.microsoft.com/.default';
      default: throw new Error('Invalid resource name');
    }
  }

  public async acquireAccessToken(resource: string): Promise<string> {
    if (!this.isLoggedIn()) {
      await this.loginUser();
    }
    // Documentation: see last bullet point here
    // https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-compare-msal-js-and-adal-js#scopes-for-acquiring-tokens

    const scope = this.getResourceScope(resource);
    const tokenRequest = {
      scopes: [scope],
    };

    try {
      const response = await this.msalClient.acquireTokenSilent(tokenRequest);
      return response.accessToken;
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        if (this.popupAuth) {
          const response = await this.msalClient.acquireTokenPopup(tokenRequest);
          this.authorize(response.account);
          return response.accessToken;
        }
        await this.msalClient.acquireTokenRedirect(tokenRequest);
      }

      store.dispatch(LoginFailed(getLoginErrorMessage(err)));
      throw err;
    }
  }
}

let authService : AuthService | null = null;

export const getAuthServiceSingleton = () : AuthService => {
  if (authService == null) {
    authService = new AuthService();
  }

  return authService;
};
