import { useEffect, useState } from 'react';

// Supporting code for virtual scrolling
export function windowHeightResized(): null {
  const [windowHeight, setWindowHeight] = useState<number>(0);

  useEffect(() => {
    const handler = () => {
      setWindowHeight(windowHeight - windowHeight + window.innerHeight);
    };

    handler();

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return null;
}
