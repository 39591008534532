import { isLuceneQueryValid } from '../../common/queryValidation';
import {
  ReportSearchParametersActionTypes, GET_INITIAL_INFORMATION_SUCCEDED, GET_FACETABLE_FIELDS_SUCCEDED,
  SET_SELECTEDPARAMETERVALUES, SET_SEARCHTEXT, EXECUTE_SEARCH_REQUESTED, SET_ORDERING,
} from './actionTypes';
import {
  IInitialInformation, ISearchQuery, SearchParameterAvailableValues, Facet, Country, Field, Wellbore, SectionType, SectionName, Rig,
  Formation, ReportType, Topic, DEFAULT_MINIMUM_DATE, DEFAULT_MAXIMUM_DATE, DEFAULT_MINIMUM_DEPTH, DEFAULT_MAXIMUM_DEPTH, DEFAULT_LUCENE,
  availableOrderingViewModels, DEFAULT_ORDERING_VIEW_MODEL,
} from './searchParametersTypes';

const SEARCH_TEXT_FIELD_HELPER_TEXT_DEFAULT = '';
const SEARCH_TEXT_FIELD_HELPER_TEXT_DEFAULT_ERROR_MESSAGE = 'Invalid entry.';

export interface ISearchState {
  searchParams: IParametersState,
  searchTextValidity: ISearchTextValidityState,
}

interface IParametersState {
  availableValues: IInitialInformation,
  userQuery: ISearchQuery,
}

interface ISearchTextValidityState {
  isSearchTextErroneous: boolean;
  searchTextFieldHelperText: string;
  isSearchButtonDisabled: boolean;
}

const generateNewSearchTextValidityState = (text: string, lucene: boolean): ISearchTextValidityState => {
  if ((isLuceneQueryValid(text) && lucene) || !lucene) {
    return {
      isSearchTextErroneous: false,
      searchTextFieldHelperText: SEARCH_TEXT_FIELD_HELPER_TEXT_DEFAULT,
      isSearchButtonDisabled: false,
    };
  }
  return {
    isSearchTextErroneous: true,
    searchTextFieldHelperText: SEARCH_TEXT_FIELD_HELPER_TEXT_DEFAULT_ERROR_MESSAGE,
    isSearchButtonDisabled: true,
  };
};

export const emptySearchQuery = {
  searchParams: {
    countries: new Array<Country>(),
    fields: new Array<Field>(),
    wellbores: new Array<Wellbore>(),
    sectionTypes: new Array<SectionType>(),
    sectionNames: new Array<SectionName>(),
    rigs: new Array<Rig>(),
    formations: new Array<Formation>(),
    reportTypes: new Array<ReportType>(),
    topics: new Array<Topic>(),
    startDate: DEFAULT_MINIMUM_DATE,
    endDate: DEFAULT_MAXIMUM_DATE,
    startDepth: DEFAULT_MINIMUM_DEPTH,
    endDepth: DEFAULT_MAXIMUM_DEPTH,
    lucene: DEFAULT_LUCENE,
    orderings: [DEFAULT_ORDERING_VIEW_MODEL],
  },

  text: '',
  skip: 0,
  executeQueryOnStartup: false,
};

const initialState: ISearchState = {
  searchParams: {
    availableValues: {
      searchParameterAvailableValues: {
        countries: new Array<Country>(),
        fields: new Array<Field>(),
        wellbores: new Array<Wellbore>(),
        sectionTypes: new Array<SectionType>(),
        sectionNames: new Array<SectionName>(),
        rigs: new Array<Rig>(),
        formations: new Array<Formation>(),
        reportTypes: new Array<ReportType>(),
        topics: new Array<Topic>(),
        orderings: availableOrderingViewModels,
      },
      serviceNowFeedbackUrl: '#/',
    },
    userQuery: emptySearchQuery,
  },
  searchTextValidity: {
    isSearchTextErroneous: false,
    searchTextFieldHelperText: '',
    isSearchButtonDisabled: false,
  },
};
const updateFacetValueCount = <T extends Facet>(oldFacets: Array<T>, facets: Array<T>) => oldFacets.map((fv) => {
  const count = facets.find((x) => x.name === fv.name)?.count ?? 0;
  fv.count = count;
  return fv;
});

function updateFacetValuesAllFields(state: ISearchState, newSearchParameterAvailableValues: SearchParameterAvailableValues) {
  const searchParameter = { ...state.searchParams.availableValues.searchParameterAvailableValues };
  searchParameter.countries = updateFacetValueCount(searchParameter.countries, newSearchParameterAvailableValues.countries);
  searchParameter.fields = updateFacetValueCount(searchParameter.fields, newSearchParameterAvailableValues.fields);
  searchParameter.formations = updateFacetValueCount(searchParameter.formations, newSearchParameterAvailableValues.formations);
  searchParameter.reportTypes = updateFacetValueCount(searchParameter.reportTypes, newSearchParameterAvailableValues.reportTypes);
  searchParameter.rigs = updateFacetValueCount(searchParameter.rigs, newSearchParameterAvailableValues.rigs);
  searchParameter.sectionNames = updateFacetValueCount(searchParameter.sectionNames, newSearchParameterAvailableValues.sectionNames);
  searchParameter.sectionTypes = updateFacetValueCount(searchParameter.sectionTypes, newSearchParameterAvailableValues.sectionTypes);
  searchParameter.topics = updateFacetValueCount(searchParameter.topics, newSearchParameterAvailableValues.topics);
  searchParameter.wellbores = updateFacetValueCount(searchParameter.wellbores, newSearchParameterAvailableValues.wellbores);
  return searchParameter;
}

export function searchParametersReducer(
  state = initialState,
  action: ReportSearchParametersActionTypes,
): ISearchState {
  switch (action.type) {
    case GET_INITIAL_INFORMATION_SUCCEDED: {
      const { initialInformation } = action;
      if (action.searchQuery.searchParams.orderings !== null && action.searchQuery.searchParams.orderings.length === 0) {
        action.searchQuery.searchParams.orderings.push(DEFAULT_ORDERING_VIEW_MODEL);
      }
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          availableValues: initialInformation,
          userQuery: action.searchQuery,
        },
      };
    }

    case GET_FACETABLE_FIELDS_SUCCEDED: {
      const newFacetValues = action.searchParameterAvailableValues;

      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          availableValues: {
            ...state.searchParams.availableValues,
            searchParameterAvailableValues: updateFacetValuesAllFields(state, newFacetValues),
          },
        },
      };
    }

    case SET_SELECTEDPARAMETERVALUES: {
      const newSearchTextValidityState = generateNewSearchTextValidityState(
        state.searchParams.userQuery.text,
        (action.selectedParametersType === 'lucene')
          ? Boolean(action.selectedParameters).valueOf()
          : state.searchParams.userQuery.searchParams.lucene.valueOf(),
      );

      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          userQuery: {
            ...state.searchParams.userQuery,
            searchParams: {
              ...state.searchParams.userQuery.searchParams,
              [action.selectedParametersType]: action.selectedParameters,
            },
            executeQueryOnStartup: false,
          },
        },
        searchTextValidity: {
          ...state.searchTextValidity,
          ...newSearchTextValidityState,
        },
      };
    }

    case SET_SEARCHTEXT: {
      const newSearchTextValidityState = generateNewSearchTextValidityState(
        action.searchText,
        state.searchParams.userQuery.searchParams.lucene.valueOf(),
      );

      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          userQuery: {
            ...state.searchParams.userQuery,
            text: action.searchText,
            executeQueryOnStartup: false,
          },
        },
        searchTextValidity: {
          ...state.searchTextValidity,
          ...newSearchTextValidityState,
        },
      };
    }
    case SET_ORDERING: {
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          userQuery: {
            ...state.searchParams.userQuery,
            searchParams: {
              ...state.searchParams.userQuery.searchParams,
              orderings: [action.ordering],
            },
            executeQueryOnStartup: false,
          },
        },
      };
    }

    case EXECUTE_SEARCH_REQUESTED: {
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          userQuery: {
            ...state.searchParams.userQuery,
            executeQueryOnStartup: true,
          },
        },
      };
    }

    default:
      return state;
  }
}
