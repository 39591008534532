import {
  UserIdentity,
  LoginSuccededAction, LOGIN_SUCCEDED, LoginFailedAction, LOGIN_FAILED,
  LogoutSuccededAction, LOGOUT_SUCCEDED, LogoutFailedAction, LOGOUT_FAILED,
} from './types';

export const LoginSucceeded = (userIdentity : UserIdentity) : LoginSuccededAction => ({
  type: LOGIN_SUCCEDED,
  userIdentity,
});

export const LoginFailed = (errorMessage: string) : LoginFailedAction => ({
  type: LOGIN_FAILED,
  errorMessage,
});

export const LogoutSucceeded = () : LogoutSuccededAction => ({
  type: LOGOUT_SUCCEDED,
});

export const LogoutFailed = (errorMessage: string) : LogoutFailedAction => ({
  type: LOGOUT_FAILED,
  errorMessage,
});
