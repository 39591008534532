import {
  ExecuteReportRetrievalActionRequested, ExecuteReportRetrievalActionSucceeded,
  EXECUTE_REPORT_RETRIEVAL_SUCCEEDED, EXECUTE_REPORT_RETRIEVAL_REQUESTED,

  ClearRelatedReportsAction, EXECUTE_CLEAR_RELATED_REPORTS,

  GetSimilarReportsActionFailed, GetSimilarReportsActionRequested, GetSimilarReportsActionSucceeded,
  GET_SIMILAR_REPORTS_FAILED, GET_SIMILAR_REPORTS_REQUESTED, GET_SIMILAR_REPORTS_SUCCEEDED,

  LoadMoreSimilarReportsActionFailed, LoadMoreSimilarReportsActionRequested, LoadMoreSimilarReportsActionSucceeded,
  LOAD_MORE_SIMILAR_REPORTS_FAILED, LOAD_MORE_SIMILAR_REPORTS_REQUESTED, LOAD_MORE_SIMILAR_REPORTS_SUCCEEDED,

  GetRelatedReportsActionFailed, GetRelatedReportsActionRequested, GetRelatedReportsActionSucceeded,
  GET_RELATED_REPORTS_REQUESTED, GET_RELATED_REPORTS_SUCCEEDED, GET_RELATED_REPORTS_FAILED,

  LoadMoreRelatedReportsActionRequested, LoadMoreRelatedReportsActionSucceeded, LoadMoreRelatedReportsActionFailed,
  LOAD_MORE_RELATED_REPORTS_FAILED, LOAD_MORE_RELATED_REPORTS_REQUESTED, LOAD_MORE_RELATED_REPORTS_SUCCEEDED,
} from './actionTypes';

import { ReportQueryForAPI } from '../searchParameters/searchParametersTypes';
import { IReport } from '../searchResults/searchResultTypes';
import { IRelatedReportsResponse } from './types';

// ReportRetrieval ----------------------------------------
export const executeReportRetrieval = (reportQuery: ReportQueryForAPI): ExecuteReportRetrievalActionRequested => ({
  type: EXECUTE_REPORT_RETRIEVAL_REQUESTED,
  reportQuery,
});

export const executeReportRetrievalSucceeded = (reportResult: IReport): ExecuteReportRetrievalActionSucceeded => ({
  type: EXECUTE_REPORT_RETRIEVAL_SUCCEEDED,
  reportResult,
});

// GetRelatedReport ---------------------------------------
export const getRelatedReportsRequested = (reportQuery: ReportQueryForAPI): GetRelatedReportsActionRequested => ({
  type: GET_RELATED_REPORTS_REQUESTED,
  reportQuery,
});

export const getRelatedReportsSucceeded = (relatedReportsResponse: IRelatedReportsResponse): GetRelatedReportsActionSucceeded => ({
  type: GET_RELATED_REPORTS_SUCCEEDED,
  relatedReportsResponse,
});

export const getRelatedReportsFailed = (): GetRelatedReportsActionFailed => ({
  type: GET_RELATED_REPORTS_FAILED,
});

// LoadMoreRelatedReport ---------------------------------------
export const loadMoreRelatedReportsRequested = (reportQuery: ReportQueryForAPI): LoadMoreRelatedReportsActionRequested => ({
  type: LOAD_MORE_RELATED_REPORTS_REQUESTED,
  reportQuery,
});

export const loadMoreRelatedReportsSucceeded = (relatedReportsResponse: IRelatedReportsResponse): LoadMoreRelatedReportsActionSucceeded => ({
  type: LOAD_MORE_RELATED_REPORTS_SUCCEEDED,
  relatedReportsResponse,
});

export const loadMoreRelatedReportsFailed = (): LoadMoreRelatedReportsActionFailed => ({
  type: LOAD_MORE_RELATED_REPORTS_FAILED,
});

// GetSimilarReports ---------------------------------
export const getSimilarReportsRequested = (reportQuery: ReportQueryForAPI): GetSimilarReportsActionRequested => ({
  type: GET_SIMILAR_REPORTS_REQUESTED,
  reportQuery,
});

export const getSimilarReportsSucceeded = (similarReportsResponse: IRelatedReportsResponse): GetSimilarReportsActionSucceeded => ({
  type: GET_SIMILAR_REPORTS_SUCCEEDED,
  similarReportsResponse,
});

export const getSimilarReportsFailed = (): GetSimilarReportsActionFailed => ({
  type: GET_SIMILAR_REPORTS_FAILED,
});

// LoadMoreSimilarReports ---------------------------------
export const loadMoreSimilarReportsRequested = (reportQuery: ReportQueryForAPI): LoadMoreSimilarReportsActionRequested => ({
  type: LOAD_MORE_SIMILAR_REPORTS_REQUESTED,
  reportQuery,
});

export const loadMoreSimilarReportsSucceeded = (relatedReportsResponse: IRelatedReportsResponse): LoadMoreSimilarReportsActionSucceeded => ({
  type: LOAD_MORE_SIMILAR_REPORTS_SUCCEEDED,
  similarReportsResponse: relatedReportsResponse,
});

export const loadMoreSimilarReportsFailed = (): LoadMoreSimilarReportsActionFailed => ({
  type: LOAD_MORE_SIMILAR_REPORTS_FAILED,
});

// ClearRelatedReports ------------------------------------
export const clearRelatedReports = (): ClearRelatedReportsAction => ({
  type: EXECUTE_CLEAR_RELATED_REPORTS,
});
