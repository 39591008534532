import $ from 'jquery';

export const moveViewToDiv = (divID: string) => {
  const el = $(`#${$.escapeSelector(divID)}`);
  if (el && el.offset()) {
    $('html, body').animate({
      scrollTop: el.offset()!.top - 125,
    }, 500);
  }
};
