import * as React from 'react';
import { Autocomplete } from '@material-ui/lab';
import './OrderByCombo.css';
import { TextField } from '@material-ui/core';
import { DEFAULT_ORDERING_VIEW_MODEL, OrderingViewModel } from '../store/searchParameters/searchParametersTypes';

export interface OrderByComboProps {
  orderings: Array<OrderingViewModel>,
  selectedOrdering: OrderingViewModel,
  onChange: (oredering: OrderingViewModel) => void
}
export function OrderByCombo(props: OrderByComboProps) {
  const handleOnChangeEvent = (_event: React.ChangeEvent<{}>, value: OrderingViewModel | null) => {
    const orderingOrDefault = value ?? DEFAULT_ORDERING_VIEW_MODEL;
    props.onChange(orderingOrDefault);
  };

  return (
    <div className="order-by-combo-column-div">
      <div className="order-by-combo">
        <Autocomplete
          disableClearable
          options={props.orderings}
          getOptionLabel={(option) => option.label}
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(params) => <TextField {...params} label="Order" />}
          onChange={handleOnChangeEvent}
          value={props.selectedOrdering}
        />
      </div>
    </div>
  );
}
