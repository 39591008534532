declare let reportAppConfig: IConfiguration;

export interface IConfiguration {
    apiBaseUrl : string;
    msal : IMsalConfiguration;
    wellcomPlannerUrl: string;
    parentFrameDomain: string;
    luceneDocsUrl: string;
}

export interface IMsalConfiguration {
    authority : string;
    clientId : string;
    scopes: Array<string>;
}

export const getConfiguration = () : IConfiguration => reportAppConfig;
