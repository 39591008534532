import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import './ResultsOverview.css';

export interface ResultsOverviewProps { numberOfResults: number, total: number }
export function ResultsOverview(props: ResultsOverviewProps) {
  return (
    <div className="result-overview">
      <Chip
        id="results-overview-chip"
        className="result-overview-chip"
        label={`Showing ${props.numberOfResults.toLocaleString()} of ${props.total.toLocaleString()} results`}
      />
    </div>
  );
}
