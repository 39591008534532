import * as React from 'react';
import './MainPage.css';

interface IProps {
}

interface IState {
  hasError: boolean;
  errorName: string;
  errorMessage: string;
}

export class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      errorName: '',
      errorMessage: '',
    };
  }

  componentDidCatch(error: Error, _: React.ErrorInfo) {
    this.setState({
      hasError: true,
      errorName: error.name,
      errorMessage: error.message,
    });
    // eslint-disable-next-line no-console
    console.log(`A rendering error occured: ${error.name}`);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="container">
          <div style={{ position: 'absolute', top: '50px' }}>
            <h3>An internal error happened while rendering the page.</h3>
            <h3>
              {this.state.errorName}
              :
              {' '}
              {this.state.errorMessage}
            </h3>
          </div>
        </div>
      );
    }
    // TODO: check what is returned here exactly
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}
