import * as React from 'react';
import './MainPage.css';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useLocation } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import $ from 'jquery';
import SearchParametersPanel from '../components/SearchParametersPanel';
import SearchResultsPanel, { updateSearchResultsPanel } from '../components/SearchResultsPanel';
import { executeSearch, setOrderingRequested, getInitialInformation } from '../store/searchParameters/actions';
import setupAppInsights from '../services/appInsights/appInsightsSetup';

import { ResultsOverview } from '../components/ResultsOverview';
import { getAuthServiceSingleton, getLoginErrorMessage } from '../services/authentication/authService';

import { useMountEffect } from './App';
import {
  getHasNextPage, getIsNextResultsPageLoading, getSearchResults, getSearchResultsTotal, getSelectedResult,
} from '../store/searchResults/searchResultSelectors';
import { RESULTS_DIV_ID, Report } from '../store/searchResults/searchResultTypes';
import {
  FilterNameAndText, getSearchParameterAvailableValues, getSearchQueryText, getSelectedOrdering,
} from '../store/searchParameters/searchParametersSelectors';
import { getIsHeaderExpanded, getSelectedReports } from '../store/userPrefs/selectors';
import { OrderingViewModel, SearchParameterAvailableValues } from '../store/searchParameters/searchParametersTypes';
import { AppState } from '../store';
import { ReportSearchParametersActionTypes } from '../store/searchParameters/actionTypes';
import { thunkUpdateQueryAndUrl, UserPrefsActionTypes } from '../store/userPrefs/types';
import { setHeaderExpanded } from '../store/userPrefs/actions';

import { appMessagesActionTypes } from '../store/appMessages/types';
import { OrderByCombo } from '../components/OrderByCombo';

export interface OwnProps {
    //
}

interface StateProps {
    searchResults: Report[],
    searchResultsTotal: number,
    selectedResult: Report | null,
    hasNextPage: boolean,
    isNextResultsPageLoading: boolean,
    searchParametersAvailableValues: SearchParameterAvailableValues,
    filterNameAndTextList: FilterNameAndText[],
    userCollectedReports: Report[],
    isHeaderExpanded: boolean,
    selectedOrdering: OrderingViewModel,
}

interface DispatchProps {
    getInitialInformation: (url: string) => void,
    executeSearch: () => void,
    setHeaderExpanded: (expanded: boolean) => void,
    setOrdering: (ordering: OrderingViewModel) => void,
}

type Props = StateProps & DispatchProps & OwnProps

const MainPage : React.FC<Props> = ({
  searchResults,
  selectedResult,
  hasNextPage,
  isNextResultsPageLoading,
  searchResultsTotal,
  searchParametersAvailableValues,
  filterNameAndTextList,
  userCollectedReports,
  getInitialInformation,
  executeSearch,
  isHeaderExpanded,
  setHeaderExpanded,
  setOrdering,
  selectedOrdering,
}) => {
  const location = useLocation();

  useMountEffect(() => {
    const authService = getAuthServiceSingleton();
    authService.ensureUserLoggedIn()
      .catch((e) => {
        throw new Error(getLoginErrorMessage(e));
      })
      .then(setupAppInsights)
      .then(() => getInitialInformation(location.search));
  });

  const handleSearchClick = () => {
    executeSearch();
    // Supporting code for virtual scrolling
    updateSearchResultsPanelDuringAccordionAnimation();
  };

  const handleAccordionChange = (_: React.ChangeEvent<{}>, expanded: boolean) => {
    setHeaderExpanded(expanded);
    // Supporting code for virtual scrolling
    updateSearchResultsPanelDuringAccordionAnimation();
  };

  const handleOrderingChange = (ordering: OrderingViewModel) => {
    setOrdering(ordering);
  };

  // Supporting code for virtual scrolling
  const hasSelectedResult = (selectedResult !== null);
  const delayUpdatingSearchResultsPanel = (milliseconds: number) => window.setTimeout(() => updateSearchResultsPanel(hasSelectedResult), milliseconds);
  const updateSearchResultsPanelDuringAccordionAnimation = () => Array
    .from(Array(800).keys()).map((milliseconds) => delayUpdatingSearchResultsPanel(milliseconds));

  $(window).on('resize', () => updateSearchResultsPanel(hasSelectedResult));
  $(() => updateSearchResultsPanel(hasSelectedResult));

  return (
    <Box className="flex-master">
      <Box className="page-header">
        <Box className="page-header-left">
          {searchResults.length > 0
                        && <ResultsOverview numberOfResults={searchResults.length} total={searchResultsTotal} />}
        </Box>
        <Box className="page-header-params" m="auto">
          <Accordion expanded={isHeaderExpanded} onChange={handleAccordionChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {!isHeaderExpanded && (
              <div className="filter-summary-text">
                {filterNameAndTextList.map((filterNameAndText) => (
                  <Typography key={filterNameAndText.FilterName}>
                    <b>{filterNameAndText.FilterName}</b>
                    :
                    {' '}
                    {filterNameAndText.FilterText}
&nbsp;
                  </Typography>
                ))}
              </div>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <SearchParametersPanel searchParameterValidValues={searchParametersAvailableValues} onSearchClick={handleSearchClick} />
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="page-header-right">
          <OrderByCombo
            orderings={searchParametersAvailableValues.orderings}
            selectedOrdering={selectedOrdering}
            onChange={handleOrderingChange}
          />
        </Box>
      </Box>

      {searchResults.length > 0 && (
      <Box id={RESULTS_DIV_ID} className="page-content" border={1} borderRadius={6}>
        <SearchResultsPanel
          searchResult={searchResults}
          selectedResult={selectedResult}
          hasNextPage={hasNextPage}
          isNextResultsPageLoading={isNextResultsPageLoading}
          extendedView={!isHeaderExpanded}
          userCollectedReports={userCollectedReports}
          total={searchResultsTotal}
        />
      </Box>
      )}
    </Box>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    searchResults: getSearchResults(state),
    searchResultsTotal: getSearchResultsTotal(state),
    selectedResult: getSelectedResult(state),
    hasNextPage: getHasNextPage(state),
    isNextResultsPageLoading: getIsNextResultsPageLoading(state),
    searchParametersAvailableValues: getSearchParameterAvailableValues(state),
    filterNameAndTextList: getSearchQueryText(state),
    userCollectedReports: getSelectedReports(state),
    isHeaderExpanded: getIsHeaderExpanded(state),
    selectedOrdering: getSelectedOrdering(state),
  };
}

type dispatchType = ThunkDispatch<AppState, void, ReportSearchParametersActionTypes | UserPrefsActionTypes | appMessagesActionTypes>;
function mapDispatchToProps(dispatch: dispatchType): DispatchProps {
  return {
    getInitialInformation: (url: string) => dispatch(getInitialInformation(url)),
    executeSearch: () => dispatch(thunkUpdateQueryAndUrl(executeSearch())),
    setHeaderExpanded: (expanded: boolean) => dispatch(setHeaderExpanded(expanded)),
    setOrdering: (ordering: OrderingViewModel) => dispatch(thunkUpdateQueryAndUrl(setOrderingRequested(ordering))),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MainPage);
