import { FormControl, TextField } from '@material-ui/core';
import React, { ChangeEvent } from 'react';

import { ISearchParameterValues } from '../store/searchParameters/searchParametersTypes';

export interface NumberInputProps {
    id: string,
    label: string,
    searchKey: keyof ISearchParameterValues,
    selectedValue: Date | Number,
    onChange: (key: keyof ISearchParameterValues, value: Number) => void,
    onBlur: () => void,
}
export function NumberInput(props: NumberInputProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const parsedInt = parseInt(event.currentTarget.value, 10);
    const newValue = Number.isInteger(parsedInt) ? parsedInt : 0;
    props.onChange(props.searchKey, newValue);
  };

  return (
    <FormControl fullWidth>
      {/* <InputLabel id="number-input-label">{props.label}</InputLabel> */}
      <TextField
        id={`${props.id}number-input`}
        label={props.label}
        type="text"
        value={props.selectedValue}
        onChange={handleChange}
        onBlur={props.onBlur}
      />
    </FormControl>
  );
}
