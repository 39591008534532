import { ITimeInterval } from '../store/searchResults/searchResultTypes';

export const GetFormattedDate = (date: Date) => GetValueOrNA(!date
  ? ''
  : new Date(date.toString().split('+')[0]).toLocaleDateString('en-GB').replace(/\//g, '.'));

export const GetFormattedTime = (date: Date) => {
  const dateValue = new Date(date.toString().split('+')[0]);
  let hour = dateValue.getHours().toString();
  let minutes = dateValue.getMinutes().toString();
  if (hour.length < 2) {
    hour = `0${hour}`;
  }
  if (minutes.length < 2) {
    minutes = `0${minutes}`;
  }

  return GetValueOrNA((!date ? '' : (`${hour}:${minutes}`)).replace(/\//g, '.'));
};

export const GetFormattedTimeInterval = (timeInterval : ITimeInterval) => {
  if (!timeInterval.startTime && !timeInterval.endTime) {
    return '[N/A]';
  }

  return `${GetFormattedDate(timeInterval.startTime)} - ${GetFormattedDate(timeInterval.endTime)}`;
};

export const GetReportDate = (reportDate: Date, timeInterval: ITimeInterval) => {
  if (reportDate != null) {
    return GetFormattedDate(reportDate);
  }

  // If report has not report date use time interval of report as a fallback
  return GetFormattedTimeInterval(timeInterval);
};

export const formatDateYYYYDashMMDashDD = (date: Date) => {
  // TODO: in IReport the time interval is defined as date but in execution time it is a string
  // we need this conversion here until the data returned by backend is not converted
  const dateInput = new Date(date);
  const year = dateInput.getFullYear().toString();
  let month = (dateInput.getMonth() + 1).toString();
  let day = dateInput.getDate().toString();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
};

export const parseYYYYDashMMDashDDToDate = (dateString: string) : Date | null => {
  const dateParts = dateString.split('-');
  if (dateParts.length === 3) {
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const day = parseInt(dateParts[2], 10);

    if (!(Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day))) {
      const date = new Date(year, month - 1, day);
      return date;
    }
  }

  return null;
};

export const ConcatWithSeparator = (strings : Array<string>) : string => GetValueOrNA(Array.from(new Set(strings)).join(Separator));

export const GetValueOrNA = (text: string) => (text && text.length > 0 ? text : '[N/A]');

export function NumberToStringNullToNA(input: number | null | undefined) {
  return (input === null || input === undefined) ? '[N/A]' : input.toFixed(1);
}

export const Separator = ', ';

export const formatReportType = (reportType: string) => GetValueOrNA(firstUpperRestLowerCase(reportType.replace(/([A-Z])/g, ' $1').trim()));

export const firstUpperRestLowerCase = (input : string) : string => input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
