import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import { getErrorMessage } from '../../store/appMessages/selectors';
import { hideError, showError } from '../../store/appMessages/actions';

import './ErrorMessagePanel.css';
import { MESSAGESPANEL_DIV_ID } from '../../store/searchResults/searchResultTypes';

export function ErrorMessagePanel() {
  const errorMessage = useSelector(getErrorMessage);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const handleError = (errorEvent: ErrorEvent) => {
      // app insights registers a handler to this event so we only have to show the error to the user
      dispatch(showError(errorEvent.error));
    };
    window.addEventListener('error', handleError);

    const handleRejection = (event : PromiseRejectionEvent) => {
      // app insights registers a handler to this event so we only have to show the error to the user
      dispatch(showError(event.reason));
    };
    window.addEventListener('unhandledrejection', handleRejection);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleRejection);
    };
  });

  return (
    <div id={MESSAGESPANEL_DIV_ID} className="ErrorMessagePanel">
      { errorMessage !== '' && (
        <Alert
          severity="error"
          action={<Button onClick={() => dispatch(hideError())} color="inherit" size="small">X</Button>}
        >
          { errorMessage }
        </Alert>
      )}
    </div>
  );
}
