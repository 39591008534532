import { ReportSearchApi } from '../reportSearchApi';
import { GraphApi } from '../graphApi';
import { initAppInsigths } from './appInsights';

async function setupAppInsights() : Promise<void> {
  const reportSearchApi = new ReportSearchApi();
  const graphApi = new GraphApi();
  const graphResponse = await graphApi.getDepartment();
  const appInsightsKeyResponse = await reportSearchApi.getAppInsightsKey();
  await initAppInsigths(appInsightsKeyResponse.appInsigthsInstrumentationKey, graphResponse.department);
}

export default setupAppInsights;
