import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const tooltipEnterDelay = 200;

export interface TableCellContentWithTooltipProps {
  className?: string,
  id: string,
  enterDelay?: number,
  title: string,
}

export function TableCellContentWithTooltip(props: TableCellContentWithTooltipProps) {
  const styleId = `${props.id}-style`;
  const enterDelay = props.enterDelay ?? tooltipEnterDelay;
  return (
    <div>
      <style id={styleId} />
      <Tooltip
        className={props.className ?? ''}
        id={props.id}
        title={props.title}
        enterDelay={enterDelay}
        enterNextDelay={enterDelay}
      >
        <div>{props.title}</div>
      </Tooltip>
    </div>
  );
}
