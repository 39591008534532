import { httpGet } from './httpClient';
import { RESOURCE_NAME_GRAPH } from './authentication/authService';

export interface GraphResponse {
    department : string;
}
export class GraphApi {
  constructor() {
  }

  public getDepartment = () : Promise<GraphResponse> => httpGet<GraphResponse>('https://graph.microsoft.com/v1.0/me?$select=department', RESOURCE_NAME_GRAPH);
}
