import {
  ConcatWithSeparator, formatDateYYYYDashMMDashDD, formatReportType, GetReportDate, NumberToStringNullToNA,
} from '../../common/formatters';
import { IConfiguration, getConfiguration } from '../../config/configuration';
import { highlightText } from '../../common/highlightText';
import {
  Topic, Rig, Formation, Wellbore, DEFAULT_MINIMUM_DEPTH, DEFAULT_MAXIMUM_DEPTH, DEFAULT_MINIMUM_DATE, DEFAULT_MAXIMUM_DATE,
} from '../searchParameters/searchParametersTypes';

export interface ISearchResult {
    // TODO: this should have  Array<IReport> and we should execute the transformation mapIReportToReportWith when getting the data from server
    items: Array<Report>;
    skip: number | null;
    total: number;
}

export interface IDepthInterval {
    // TODO: startDepth and endDepth should be of type (number | null)
    startDepth: number;
    endDepth: number;
}

export class DepthIntervalFunctions {
  static formattedString(depthInterval : IDepthInterval) : string {
    const startDepth = NumberToStringNullToNA(depthInterval.startDepth);
    const endDepth = NumberToStringNullToNA(depthInterval.endDepth);

    if (startDepth === endDepth) {
      return startDepth;
    }

    return `${startDepth} - ${endDepth}`;
  }
}

export interface ITimeInterval {
    // TODO: these are actualy strings when returned by the server.
    // either we have to change the types here to string or convert the data received from server
    startTime: Date;
    endTime: Date;
}

export interface IDescriptions {
  id: string;
  title: string;
  description: string;
  operationData: IOperationSource
}

export interface IOperationSource {
  startTime: Date;
  endTime: Date;
  mainActivity: string;
  subActivity: string;
}

export interface IReport {
    resultGuid: string;
    id: string;
    reportType: string;
    title: string;
    highlightedTitle: string;
    description: string;
    highlightedDescription: string;
    projectId: string;
    topics: Array<Topic>;
    rigs: Array<Rig>;
    formations: Array<Formation>;
    wellbores: Array<Wellbore>;
    sections: Array<Section>;
    depthInterval: IDepthInterval;
    timeInterval: ITimeInterval;
    reportDate: Date;
    sourceReports: Array<IDescriptions>;
}

export interface Section {
  id: string;
  typeCode: string;
  type: string;
  sectionNameCode: string;
  sectionName: string;
}

export class Report implements IReport {
  constructor(
        public resultGuid: string,
        public id: string,
        public reportType: string,
        public title: string,
        public highlightedTitle: string,
        public description: string,
        public highlightedDescription: string,
        public projectId: string,
        public topics: Array<Topic>,
        public rigs: Array<Rig>,
        public formations: Array<Formation>,
        public wellbores: Array<Wellbore>,
        public sections: Array<Section>,
        public depthInterval: IDepthInterval,
        public timeInterval: ITimeInterval,
        public reportDate: Date,
        public sourceReports: Array<IDescriptions>,

        // Formatted fields
        public formattedReportType: string,
        public highlightedTitleString: '' | (string | JSX.Element)[],
        public topicsString: string,
        public rigsString: string,
        public wellboresString: string,
        public formationsString: string,
        public sectionsString: string,
        public formattedDepthInterval: string,
        public formattedReportDate: string,
  ) {}

  public isUserCollected: boolean = false;

  // TODO: use these methods instead of ReportFunctions. methods after IReport is converted to Report class when data arrived from server.
  public get concatenatedCountries() : string {
    return ConcatWithSeparator(this.wellbores.map((wellbore) => wellbore.country));
  }

  public get concatenatedFieldNames(): string {
    return ConcatWithSeparator(this.wellbores.map((wellbore) => wellbore.field));
  }

  public get concatenatedRigNames() : string {
    return ConcatWithSeparator(this.rigs.map((rig) => rig.name));
  }

  public get concatenatedWellboreNames() : string {
    return ConcatWithSeparator(this.wellbores.map((wellbore) => wellbore.name));
  }

  public get concatenatedFormationNames() : string {
    return ConcatWithSeparator(this.formations.map((formation) => formation.name));
  }

  public get concatenatedTopicNames() : string {
    return ConcatWithSeparator(this.topics.map((topic) => topic.name));
  }

  public get concatenatedSectionNames() : string {
    return ConcatWithSeparator(this.sections.map((section) => section.sectionName));
  }

  public static Clone(report : Report) : Report {
    return new Report(
      report.resultGuid,
      report.id,
      report.reportType,
      report.title,
      report.highlightedTitle,
      report.description,
      report.highlightedDescription,
      report.projectId,
      report.topics,
      report.rigs,
      report.formations,
      report.wellbores,
      report.sections,
      report.depthInterval,
      report.timeInterval,
      report.reportDate,
      report.sourceReports,
      report.formattedReportType,
      report.highlightedTitleString,
      report.topicsString,
      report.rigsString,
      report.wellboresString,
      report.formationsString,
      report.sectionsString,
      report.formattedDepthInterval,
      report.formattedReportDate,
    );
  }

  public static CreateEmpty(): Report {
    return new Report(
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      new Array<Topic>(),
      new Array<Rig>(),
      new Array<Formation>(),
      new Array<Wellbore>(),
      new Array<Section>(),
      { startDepth: DEFAULT_MINIMUM_DEPTH, endDepth: DEFAULT_MAXIMUM_DEPTH },
      { startTime: DEFAULT_MINIMUM_DATE, endTime: DEFAULT_MAXIMUM_DATE },
      new Date(),
      new Array<IDescriptions>(),
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    );
  }
}

export const mapIReportToReportWith = (sourceObject : IReport, isUserCollected: boolean) : Report => {
  const report = mapIReportToReport(sourceObject);
  report.isUserCollected = isUserCollected;
  return report;
};

export const mapIReportToReport = (sourceObject : IReport) : Report => new Report(
  sourceObject.resultGuid,
  sourceObject.id,
  sourceObject.reportType,
  sourceObject.title,
  sourceObject.highlightedTitle,
  sourceObject.description,
  sourceObject.highlightedDescription,
  sourceObject.projectId,
  sourceObject.topics,
  sourceObject.rigs,
  sourceObject.formations,
  sourceObject.wellbores,
  sourceObject.sections,
  sourceObject.depthInterval,
  sourceObject.timeInterval,
  sourceObject.reportDate,
  sourceObject.sourceReports,
  formatReportType(sourceObject.reportType),
  highlightText(sourceObject.highlightedTitle),
  ReportFunctions.concatenateTopicNames(sourceObject),
  ReportFunctions.concatenateRigNames(sourceObject),
  ReportFunctions.concatenateWellboreNames(sourceObject),
  ReportFunctions.concatenateFormationNames(sourceObject),
  ReportFunctions.concatenateSectionNames(sourceObject),
  DepthIntervalFunctions.formattedString(sourceObject.depthInterval),
  GetReportDate(sourceObject.reportDate, sourceObject.timeInterval),
);

export class ReportFunctions {
  // TODO: use Report class instead of IReport for ReportDetailsPage as well and remove these functions.
  // Use corresponding properties implemented in Report class.
  static concatenateCountries(report: IReport) : string {
    return ConcatWithSeparator(report.wellbores.map((wellbore) => wellbore.country));
  }

  static concatenateFieldNames(report: IReport): string {
    return ConcatWithSeparator(report.wellbores.map((wellbore) => wellbore.field));
  }

  static concatenateRigNames(report: IReport) : string {
    return ConcatWithSeparator(report.rigs.map((rig) => rig.name));
  }

  static concatenateWellboreNames(report: IReport) : string {
    return ConcatWithSeparator(report.wellbores.map((wellbore) => wellbore.name));
  }

  static concatenateFormationNames(report: IReport) : string {
    return ConcatWithSeparator(report.formations.map((formation) => formation.name));
  }

  static concatenateTopicNames(report: IReport) : string {
    return ConcatWithSeparator(report.topics.map((topic) => topic.name));
  }

  static concatenateSectionNames(report: IReport) : string {
    return ConcatWithSeparator(report.sections.map((section) => section.sectionName));
  }

  static getWellcomUrl(report: IReport) : string {
    const appConfig: IConfiguration = getConfiguration();
    const emptyUrl = '';

    if (report.wellbores.length === 0) {
      return emptyUrl;
    }

    const wellboreS = report.wellbores[0].id;
    const projectS = report.projectId;

    switch (report.reportType) {
      case 'DailyReport': {
        const { reportDate } = report;
        if (!reportDate) {
          return emptyUrl;
        }

        // Daily reports in wellcom is created after the actual day the report is concerning.
        // Because of this  we add one day to the wellcom URL, since it is bases on the creation date of the report
        // While our UI uses the day of the actual operations
        const wellcomUrlDate = new Date(reportDate);
        wellcomUrlDate.setDate(wellcomUrlDate.getDate() + 1);

        const reportDateString = formatDateYYYYDashMMDashDD(wellcomUrlDate);
        return `${appConfig.wellcomPlannerUrl}/dbrreports/DBRWeb/DailyReport.aspx?WellboreS=${wellboreS}&ReportDateFrom=${reportDateString}&ReportType=default`;
      }
      case 'OperationSummary': {
        const endDate = report.timeInterval.endTime;
        if (!endDate) {
          return emptyUrl;
        }
        const endDateString = formatDateYYYYDashMMDashDD(endDate);
        return `${appConfig.wellcomPlannerUrl}/dbrreports/DBRWeb/DailyReport.aspx?WellboreS=${wellboreS}&ReportDateFrom=${endDateString}&ReportType=default`;
      }
      case 'SectionSummary': {
        if (report.sections.length === 0 || !projectS) {
          return emptyUrl;
        }
        const sectionS = report.sections[0].id;
        return `${appConfig.wellcomPlannerUrl}/projectplanning/index.html#project/${projectS}/sectsummary/Sect-${sectionS}`;
      }
      case 'Incident': {
        const startDate = report.timeInterval.startTime;
        if (!startDate) {
          return emptyUrl;
        }
        const startDateString = formatDateYYYYDashMMDashDD(startDate);
        const incidentS = report.id;
        const wellboreName = ReportFunctions.CompatibilityQueryParameterEncode(report.wellbores[0].name);
        return `${appConfig.wellcomPlannerUrl}/dailyreporting/#/reports/${wellboreName}/${startDateString}/incidents/${incidentS}`;
      }
      case 'Experience': {
        if (!projectS) {
          return emptyUrl;
        }
        const experienceS = report.id;
        return `${appConfig.wellcomPlannerUrl}/projectplanning/index.html#project/${projectS}/projectexperiencelist/${experienceS}`;
      }
      case 'Operation':
        if (!projectS) {
          return emptyUrl;
        }
        return `${appConfig.wellcomPlannerUrl}/projectplanning/index.html#project/${projectS}/planner-operations`;
      default:
        return emptyUrl;
    }
  }

  private static CompatibilityQueryParameterEncode(parameter : string) : string {
    // wellborename on format e.g.: NO+25_11-G-7+CY1T2
    return parameter.replace(/ /g, '+').replace(/\//g, '_');
  }
}

// TODO: remove these ids and the calls in sagas to moveViewToDiv
// after changeing the main page layout these moveViewToDiv calls are ineffective.
export const MESSAGESPANEL_DIV_ID = 'messagesPanelDiv';
export const RESULTS_DIV_ID = 'reportResultsDiv';
