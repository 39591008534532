import {
  GetInitialInformationAction, GET_INITIAL_INFORMATION_REQUESTED, GetInitialInformationSuccededAction, GET_INITIAL_INFORMATION_SUCCEDED,
  SetSelectedParameterValuesAction, SET_SELECTEDPARAMETERVALUES, SetSearchTextAction, SET_SEARCHTEXT, ExecuteSearchAction, EXECUTE_SEARCH_REQUESTED,
  SetOrderingRequestedAction, SET_ORDERING, SET_ORDERING_REQUESTED, SetOrderingAction,
  GetFacetableFieldsAction, GetFacetableFieldsSuccededAction, GET_FACETABLE_FIELDS_REQUESTED, GET_FACETABLE_FIELDS_SUCCEDED,
} from './actionTypes';
import {
  IInitialInformation, ISearchParameterValues, GenericFilterType, ISearchQuery, OrderingViewModel, SearchParameterAvailableValues,
} from './searchParametersTypes';

export const getInitialInformation = (urlQueryPart: string) : GetInitialInformationAction => ({
  type: GET_INITIAL_INFORMATION_REQUESTED,
  urlQueryPart,
});

export const getInitialInformationSucceeded = (initialInformation : IInitialInformation, searchQuery: ISearchQuery) : GetInitialInformationSuccededAction => ({
  type: GET_INITIAL_INFORMATION_SUCCEDED,
  initialInformation,
  searchQuery,
});

export const setSelectedParameters = (
  selectedParametersType : keyof ISearchParameterValues,
  selectedParameters : GenericFilterType[] | Date | Number | Boolean,
) : SetSelectedParameterValuesAction => ({
  type: SET_SELECTEDPARAMETERVALUES,
  selectedParametersType,
  selectedParameters,
});

export const setOrderingRequested = (ordering : OrderingViewModel) : SetOrderingRequestedAction => ({
  type: SET_ORDERING_REQUESTED,
  ordering,
});

export const setOrdering = (ordering : OrderingViewModel) : SetOrderingAction => ({
  type: SET_ORDERING,
  ordering,
});

export const setSearchText = (searchText : string) : SetSearchTextAction => ({
  type: SET_SEARCHTEXT,
  searchText,
});

export const executeSearch = (): ExecuteSearchAction => ({
  type: EXECUTE_SEARCH_REQUESTED,
});

export const getFacetableFields = (): GetFacetableFieldsAction => ({
  type: GET_FACETABLE_FIELDS_REQUESTED,
});

export const getFacetableFieldsSucceeded = (
  facetableFields : SearchParameterAvailableValues,
  searchQuery: ISearchQuery,
) : GetFacetableFieldsSuccededAction => ({
  type: GET_FACETABLE_FIELDS_SUCCEDED,
  searchParameterAvailableValues: facetableFields,
  searchQuery,
});
