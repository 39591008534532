import React from 'react';

export function UnauthorizedPage() {
  return (
    <div>
      <h1>Unauthorized</h1>
      <h2>You are missing rights to access this application. Please contact support!</h2>
    </div>
  );
}
