import * as React from 'react';
import { ChangeEvent } from 'react';
import { FormControl, TextField } from '@material-ui/core';

export interface SearchTextInputProps {
  value: string,
  error: boolean,
  helperText: string,
  onChange: (text: string) => void,
  onBlur: () => void,
}
export function SearchTextInput(props: SearchTextInputProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.onChange(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <TextField
        error={props.error}
        value={props.value}
        helperText={props.helperText}
        onChange={handleChange}
        onBlur={props.onBlur}
      />
    </FormControl>
  );
}
