import { formatDateYYYYDashMMDashDD } from '../../common/formatters';

// Initial Information ------------------------------------
export interface IInitialInformationResponse {
    searchParameterAvailableValues: SearchParameterAvailableValues;
    serviceNowFeedbackUrl: string;
}

export interface IInitialInformation {
    serviceNowFeedbackUrl: string;
    searchParameterAvailableValues: SearchParameterAvailableValues;
}

export interface SearchParameterAvailableValues {
    countries: Array<Country>;
    fields: Array<Field>;
    wellbores: Array<Wellbore>;
    sectionTypes: Array<SectionType>;
    sectionNames: Array<SectionName>;
    rigs: Array<Rig>;
    formations: Array<Formation>;
    reportTypes: Array<ReportType>;
    topics: Array<Topic>;
    orderings: Array<OrderingViewModel>;
}

// Report Query -------------------------------------------
// TODO: ReportQueryForAPI and IReportKey has the same structure. We could only use ReportKey.
export class ReportQueryForAPI {
  constructor(
        public id: string,
        public type: string,
  ) { }
}

// Search Query for API -----------------------------------
export class SearchQueryForAPI {
  constructor(
        public searchParams: SearchQueryParametersForAPI,
        public text: string,
        public skip: number,
  ) { }
}

type SearchQueryParametersForAPI = {
    CountryNames: Array<string>;
    FieldNames: Array<string>;
    WellboreNames: Array<string>;
    SectionNames: Array<string>;
    SectionTypeNames: Array<string>;
    RigNames: Array<string>;
    FormationNames: Array<string>;
    ReportTypes: Array<string>;
    Topics: Array<string>;
    StartTime: Date | null;
    EndTime: Date | null;
    StartDepth: Number | null;
    EndDepth: Number | null;
    Lucene: Boolean | null;
    Orderings: Array<Ordering>;
}

// Search Query for Store----------------------------------
export interface ISearchQuery {
    searchParams: ISearchParameterValues;
    text: string;
    executeQueryOnStartup : boolean;
}

export interface ISearchParameterValues {
    countries: Array<Country>;
    fields: Array<Field>;
    wellbores: Array<Wellbore>;
    sectionNames: Array<SectionName>;
    sectionTypes: Array<SectionType>;
    rigs: Array<Rig>;
    formations: Array<Formation>;
    reportTypes: Array<ReportType>;
    topics: Array<Topic>;
    startDate: Date;
    endDate: Date;
    startDepth: Number;
    endDepth: Number;
    lucene: Boolean;
    orderings: Array<OrderingViewModel>;
}

export const hasInputParameters = (searchParameter: ISearchParameterValues) => Object.keys(searchParameter).some((key) => {
  if (!objectContainsProperty(searchParameter, key)) {
    return false;
  }
  const value = searchParameter[key];
  if (Array.isArray(value)) {
    return value.length !== 0 && key !== 'orderings';
  } if (typeof value === 'boolean') {
    return value !== DEFAULT_LUCENE;
  } if (typeof value === 'number') {
    if (key === 'startDepth') {
      return value !== DEFAULT_MINIMUM_DEPTH;
    }
    if (key === 'endDepth') {
      return value !== DEFAULT_MAXIMUM_DEPTH;
    }
  } else if (value instanceof Date) {
    if (key === 'startDate') {
      return formatDateYYYYDashMMDashDD(value) !== formatDateYYYYDashMMDashDD(new Date(DEFAULT_MINIMUM_DATE));
    }
    if (key === 'endDate') {
      return formatDateYYYYDashMMDashDD(value) !== formatDateYYYYDashMMDashDD(new Date(DEFAULT_MAXIMUM_DATE));
    }
  }
  return value !== null;
});
function objectContainsProperty<T>(obj: T, key: PropertyKey): key is keyof T {
  return key in obj;
}

// Shared query parameter entities ------------------------
export type GenericFilterType = Country | Field | Wellbore | SectionType | SectionName | Rig | Formation | ReportType | Topic;

export interface Facet {
    name: string,
    count: number,
}

export const orderByFacetCount = <T extends Facet>(firstValue: T, secondValue: T) => {
  if (firstValue.count > secondValue.count) {
    return -1;
  }
  if (firstValue.count < secondValue.count) {
    return 1;
  }
  return 0;
};

export interface Country extends Facet {
    code: string;
}

export interface Field extends Facet {
    id: string;
}

export interface Wellbore extends Facet {
    id: string;
    field: string;
    country: string;
}

export interface SectionType extends Facet {
    code: string;
    sectionNames: Array<SectionName>;
}

export interface SectionName extends Facet {
    code: string;
    sectionTypeCode: string;
    diameterInch?: number;
}

export interface Rig extends Facet {
    id: string;
}

export interface Formation extends Facet {
    code: string;
}

export interface ReportType extends Facet {
    id: string;
}

export interface Topic extends Facet {
    code: string;
}

export interface TextInterval {
    startIndex: number;
    endIndex: number;
}

export interface Ordering {
    orderBy: OrderByValues;
    orderByDirection: OrderByDirectionValues;
}

// OrderBy ------------------------------------------------
const OrderByValuesConstList = [
  'MostRelevant',
  'ImportanceScore',
  'StartDate',
  'EndDate',
  'StartDepth',
  'EndDepth',
  'Field',
  'Rig',
  'Wellbore'] as const;

    type OrderByValues = typeof OrderByValuesConstList[number];

interface OrderByViewModel {
    orderBy : OrderByValues,
    displayName: string,
}

const OrderByViewModels : Array<OrderByViewModel> = [
  { orderBy: 'MostRelevant', displayName: 'Most relevant' },
  { orderBy: 'ImportanceScore', displayName: 'Importance score' },
  { orderBy: 'StartDate', displayName: 'Start date' },
  { orderBy: 'EndDate', displayName: 'End date' },
  { orderBy: 'StartDepth', displayName: 'Start depth' },
  { orderBy: 'EndDepth', displayName: 'End depth' },
  { orderBy: 'Field', displayName: 'Field' },
  { orderBy: 'Rig', displayName: 'Rig' },
  { orderBy: 'Wellbore', displayName: 'Wellbore' }];

// OrderByDirection ---------------------------------------
const OrderByDirectionValuesConstList = [
  'Ascending',
  'Descending',
] as const;

export type OrderByDirectionValues = typeof OrderByDirectionValuesConstList[number];

interface OrderByDirectionViewModel {
    orderByDirection : OrderByDirectionValues,
    displayName: string,
}

const OrderByDirectionViewModels : Array<OrderByDirectionViewModel> = [
  { orderByDirection: 'Ascending', displayName: '\u2193' }, // down arrow
  { orderByDirection: 'Descending', displayName: '\u2191' }, // up arrow
];

// OrderingViewModel --------------------------------------
export interface OrderingViewModel {
    label : string;
    ordering : Ordering;
}

const GetCartesianProductOfOrderByAndDirection : () => Array<OrderingViewModel> = () => OrderByViewModels
  .flatMap((orderBy) => OrderByDirectionViewModels.map((orderByDirection) => createOrderingViewModel(orderBy, orderByDirection)));

const createOrderingViewModel = (orderByViewModel : OrderByViewModel, orderByDirectionViewModel : OrderByDirectionViewModel) => (
    {
      label: orderingLabel(orderByViewModel.displayName, orderByDirectionViewModel.displayName),
      ordering: {
        orderBy: orderByViewModel.orderBy,
        orderByDirection: orderByDirectionViewModel.orderByDirection,
      } as Ordering,
    } as OrderingViewModel);

const orderingLabel = (orderByDisplayName: string, orderByDirectionDisplayName: string) => `${orderByDisplayName}  ${orderByDirectionDisplayName}`;

// Available OrderingViewModels ---------------------------
export const availableOrderingViewModels = GetCartesianProductOfOrderByAndDirection()
  .filter((x) => !(x.ordering.orderBy === 'MostRelevant' && x.ordering.orderByDirection === 'Ascending'));

const lookUpOrderingViewModel = (ordering: Ordering) => {
  const result = availableOrderingViewModels.find((x) => x.ordering.orderBy === ordering.orderBy && x.ordering.orderByDirection === ordering.orderByDirection);
  if (result === undefined) {
    throw new Error('Non-existing ordering.');
  }

  return result;
};

// Default values -----------------------------------------
export const DEFAULT_MINIMUM_DEPTH = 0;
export const DEFAULT_MAXIMUM_DEPTH = 30000;
export const DEFAULT_MINIMUM_DATE = new Date(1900, 0, 1);
const currentYear : number = (new Date()).getFullYear();
export const DEFAULT_MAXIMUM_DATE = new Date(currentYear + 1, 0, 1);
export const DEFAULT_LUCENE = false;
export const LUCENE_TRUE_STRING_VALUE = 'On';
export const LUCENE_FALSE_STRING_VALUE = 'Off';

const defaultOrdering : Ordering = { orderBy: 'MostRelevant', orderByDirection: 'Descending' };

export const DEFAULT_ORDERING_VIEW_MODEL : OrderingViewModel = lookUpOrderingViewModel(defaultOrdering);

export const SELECTABLE_PROPERTY_FOR_FILTERS = 'name';
export const SECTIONNAMES_GROUPBY_PROPERTY = 'sectionTypeCode';
