import * as lucene from 'lucene';

export const isLuceneQueryValid = (text: string): boolean => {
  try {
    lucene.parse(text);
  } catch (e) {
    return false;
  }
  const regexContainsEndingEscapeCharacter = /\\$/;
  const regexEndingEscapeCharacterIsValid = /(.*[^\\]+|\w*)(\\\\)+$/y;
  if (regexContainsEndingEscapeCharacter.test(text) && !regexEndingEscapeCharacterIsValid.test(text)) {
    return false;
  }
  return true;
};
