import { AppState } from '..';

export const getSearchResults = (state : AppState) => state.searchResults.items;

export const getSelectedResult = (state : AppState) => state.searchResults.selectedResult;

export const getHasNextPage = (state: AppState) => state.searchResults.hasNextPage;

export const getIsNextResultsPageLoading = (state: AppState) => state.searchResults.isNextResultsPageLoading;

export const getSearchResultsTotal = (state: AppState) => state.searchResults.total;
