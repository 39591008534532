import { FormControl } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import React from 'react';
import { ISearchParameterValues } from '../store/searchParameters/searchParametersTypes';

export interface SearchDatePickerProps {
    id: string,
    label: string,
    searchKey: keyof ISearchParameterValues,
    selectedValue: Date | Number,
    onChange: (key: keyof ISearchParameterValues, value: Date) => void,
    onBlur: () => void,
}
export function SearchDatePicker(props: SearchDatePickerProps) {
  const handleDateChange = (date: Date | null) => {
    if (date) props.onChange(props.searchKey, date);
  };

  return (
    <FormControl fullWidth>
      {/* <InputLabel id="date-picker-inline">{props.label}</InputLabel> */}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          onBlur={props.onBlur}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          id={`${props.id}date-picker-inline`}
          label={props.label}
          value={props.selectedValue}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}
