export const LOGIN_SUCCEDED = 'LOGIN_SUCCEDED';
export interface LoginSuccededAction {
    type: typeof LOGIN_SUCCEDED;
    userIdentity: UserIdentity;
}

export const LOGIN_FAILED = 'LOGIN_FAILED';
export interface LoginFailedAction {
    type: typeof LOGIN_FAILED;
    errorMessage: string;
}

export const LOGOUT_SUCCEDED = 'LOGOUT_SUCCEDED';
export interface LogoutSuccededAction {
    type: typeof LOGOUT_SUCCEDED;
}

export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export interface LogoutFailedAction {
    type: typeof LOGOUT_FAILED;
    errorMessage: string;
}

export type IdentityActionTypes =
    LoginSuccededAction | LoginFailedAction |
    LogoutSuccededAction | LogoutFailedAction;

export enum AuthState {
    LoggedIn,
    LoggedOut
}

export class Auth {
  constructor(
        public authState : AuthState,
        public userIdentity : UserIdentity | null,
  ) {}
}

export class UserIdentity {
  constructor(
        public userId: string,
        public userName: string,
        public roles: Array<string>,
  ) {
  }
}
