import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';
import { UserPrefsActionTypes } from '../userPrefs/types';
import {
  IInitialInformation, ISearchQuery, ISearchParameterValues, GenericFilterType, OrderingViewModel, SearchParameterAvailableValues,
} from './searchParametersTypes';

export const GET_INITIAL_INFORMATION_REQUESTED = 'GET_INITIAL_INFORMATION_REQUESTED';

export interface GetInitialInformationAction {
    type: typeof GET_INITIAL_INFORMATION_REQUESTED;
    urlQueryPart: string;
}

export const GET_INITIAL_INFORMATION_SUCCEDED = 'GET_INITIAL_INFORMATION_SUCCEDED';

export interface GetInitialInformationSuccededAction {
    type: typeof GET_INITIAL_INFORMATION_SUCCEDED;
    initialInformation: IInitialInformation;
    searchQuery: ISearchQuery;
}

export const SET_SELECTEDPARAMETERVALUES = 'SET_SELECTEDPARAMETERVALUES';

export interface SetSelectedParameterValuesAction {
    type: typeof SET_SELECTEDPARAMETERVALUES;
    selectedParametersType: keyof ISearchParameterValues;
    selectedParameters: GenericFilterType[] | Date | Number | Boolean;
}

export const SET_SEARCHTEXT = 'SET_SEARCHTEXT';

export interface SetSearchTextAction {
    type: typeof SET_SEARCHTEXT;
    searchText: string
}

export const SET_ORDERING_REQUESTED = 'SET_ORDERING_REQUESTED';

export interface SetOrderingRequestedAction {
    type: typeof SET_ORDERING_REQUESTED;
    ordering: OrderingViewModel
}

export const SET_ORDERING = 'SET_ORDERING';

export interface SetOrderingAction {
    type: typeof SET_ORDERING;
    ordering: OrderingViewModel
}

export const EXECUTE_SEARCH_REQUESTED = 'EXECUTE_SEARCH_REQUESTED';

export interface ExecuteSearchAction {
    type: typeof EXECUTE_SEARCH_REQUESTED;
}

export type ReportSearchParametersActionTypes =
    GetInitialInformationAction | GetInitialInformationSuccededAction |
    SetSelectedParameterValuesAction | SetSearchTextAction |
    ExecuteSearchAction | SetOrderingRequestedAction | SetOrderingAction |
    GetFacetableFieldsAction | GetFacetableFieldsSuccededAction;

export type ParametersUpdateThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    AppState,
    unknown,
    ReportSearchParametersActionTypes | UserPrefsActionTypes
>;

export const GET_FACETABLE_FIELDS_REQUESTED = 'GET_FACETABLE_FIELDS_REQUESTED';

export interface GetFacetableFieldsAction {
    type: typeof GET_FACETABLE_FIELDS_REQUESTED;
}

export const GET_FACETABLE_FIELDS_SUCCEDED = 'GET_FACETABLE_FIELDS_SUCCEDED';

export interface GetFacetableFieldsSuccededAction {
    type: typeof GET_FACETABLE_FIELDS_SUCCEDED;
    searchParameterAvailableValues: SearchParameterAvailableValues;
    searchQuery: ISearchQuery;
}
