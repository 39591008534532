import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { orderByFacetCount, GenericFilterType, ISearchParameterValues } from '../store/searchParameters/searchParametersTypes';

export interface AutocompleteVirtualizedListProps {
  label: string,
  selectableValues: GenericFilterType[],
  selectedValues: GenericFilterType[],
  selectableProperty: keyof GenericFilterType,
  searchKey: keyof ISearchParameterValues,
  onChange: (key: keyof ISearchParameterValues, value: GenericFilterType[]) => void
}

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;

  return React.cloneElement(data[index], {
    style: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      ...style,
    },
  });
}

const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  // TODO: check if these spread operators can be removed
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef<HTMLDivElement>((props, ref) => {
  // TODO: check how can eslint-disable be removed
  // eslint-disable-next-line react/prop-types
  const { children, ...other } = props;
  // eslint-disable-next-line react/prop-types
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 48;

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          style={{ padding: 0, height: Math.min(8, itemCount) * itemSize, maxHeight: 'auto' }}
          itemData={children}
          height={250}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export default function Virtualize(props: AutocompleteVirtualizedListProps) {
  const {
    selectableValues, searchKey, onChange, selectedValues, label, selectableProperty,
  } = props;

  const handleOnChangeEvent = (_event: React.ChangeEvent<{}>, value: GenericFilterType[]) => {
    onChange(searchKey, value);
  };

  return (
    <Autocomplete
      multiple
      disableListWrap
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      options={selectableValues.sort(orderByFacetCount)}
      getOptionLabel={(option) => `${option[selectableProperty]}`}
      renderOption={(option) => (
        <div className="dropdown-render-options">
          <div>{option[selectableProperty]}</div>
          <div>
            (
            {option.count.toLocaleString()}
            )
          </div>
        </div>
      )}
      onChange={handleOnChangeEvent}
      value={selectedValues}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          variant="standard"
          label={label}
          placeholder={label}
          fullWidth
        />
      )}
    />
  );
}
