import { ISearchQuery } from '../searchParameters/searchParametersTypes';
import { Report } from '../searchResults/searchResultTypes';
import {
  SetHeaderExpandedAction, SetShareDialogOpenAction, SetShareUrlAction, SET_HEADER_EXPANDED, SET_SHARE_DIALOG_OPEN, SET_SHARE_URL,
  UpdateSelectedReportsAction, UpdateShareUrlAction, UPDATE_SELECTED_REPORTS, UPDATE_SHARE_URL,
} from './types';

export const updateSelectedReports = (report : Report, isReportSelected : boolean): UpdateSelectedReportsAction => ({
  type: UPDATE_SELECTED_REPORTS,
  report,
  isReportSelected,
});

export const updateShareUrl = (query : ISearchQuery): UpdateShareUrlAction => ({
  type: UPDATE_SHARE_URL,
  query,
});

export const setShareUrl = (url: string): SetShareUrlAction => ({
  type: SET_SHARE_URL,
  url,
});

export const setHeaderExpanded = (expanded: boolean): SetHeaderExpandedAction => ({
  type: SET_HEADER_EXPANDED,
  expanded,
});

export const setShareDialogOpen = (dialogOpen: boolean): SetShareDialogOpenAction => ({
  type: SET_SHARE_DIALOG_OPEN,
  dialogOpen,
});
