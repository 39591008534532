/// <reference path="../../node_modules/jquery-resizable-dom/dist/jquery-resizable.d.ts" />
import './SearchResultPanel.css';
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ShareIcon from '@material-ui/icons/Share';
import { IconButton } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import $ from 'jquery';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { ReportDetails } from './ReportDetails';
import 'jquery-resizable-dom';
import { getNextResultsPage, setSelectedResult } from '../store/searchResults/actions';
import { Report } from '../store/searchResults/searchResultTypes';
import { updateSelectedReports, setShareDialogOpen } from '../store/userPrefs/actions';
import { ReportSearchResultsActionTypes } from '../store/searchResults/actionTypes';
import { thunkUpdateQueryAndUrl, UserPrefsActionTypes } from '../store/userPrefs/types';
import { AppState } from '../store';
import { TableCellContentWithTooltip } from './TableCellContentWithTooltip';
import { LinkDialog } from './LinkDialog';
import { getShareUrl, getIsShareDialogOpen } from '../store/userPrefs/selectors';
import { windowHeightResized } from '../common/windowHeightResized';

const useStyles = makeStyles({
  tableRow: {
    cursor: 'pointer',
  },
  selectedRow: {
    backgroundColor: '#7986cb',
  },
});

export interface OwnProps {
    searchResult: Array<Report>,
    selectedResult: Report | null,
    hasNextPage: boolean,
    isNextResultsPageLoading: boolean,
    extendedView: boolean,
    userCollectedReports: Array<Report>,
    total: number,
}

interface StateProps {
    urlForUserCollectedReports: string,
    shareReportDialogOpen: boolean,
}

interface DispatchProps {
    setSelectedReport: (selectedResult: Report) => void,
    handleChangeForIsUserCollected: (report: Report, checkedValue: boolean) => void,
    dispatchGetNextResultsPage: (startIndex: number, stopIndex: number) => void,
    setShareReportDialogOpen: (dialogOpen: boolean) => void,
}

type Props = StateProps & DispatchProps & OwnProps

const updateResultsTableHeaderWrapper = (width: number) => {
  const offset = $('.resultsTable').offset();
  $('.resultsTableHeaderWrapper').scrollLeft(-(offset == null ? 0 : offset.left));
  $('.resultsTableHeaderWrapper').width(`${($('.sidebar-left').length ? width : ($('.main-content').width() ?? 0)) - 17}px`);
};

const resultsTableRowsListHeight = (hasSelectedResult: boolean): number => (($(window).scrollTop() ?? 0)
  + ($(window).height() ?? 0)
  - ($('.resultsTableRowsList').length ? $('.resultsTableRowsList').position().top : 0)
  - 1
  + (hasSelectedResult ? 0 : -20));

export const updateSearchResultsPanel = (hasSelectedResult: boolean) => {
  updateResultsTableHeaderWrapper($('.sidebar-left').width() ?? 0);
  $('.resultsTableHeader').css('visibility', 'visible');
  $('.resultsTableRowsList').height(resultsTableRowsListHeight(hasSelectedResult));
};

const SearchResultsPanel : React.FC<Props> = ({
  selectedResult,
  searchResult,
  hasNextPage,
  dispatchGetNextResultsPage,
  isNextResultsPageLoading,
  setSelectedReport,
  handleChangeForIsUserCollected,
  userCollectedReports,
  urlForUserCollectedReports,
  shareReportDialogOpen,
  setShareReportDialogOpen,
}) => {
  const classes = useStyles();

  $('.sidebar-left').resizable({
    handleSelector: '.splitter',
    resizeHeight: false,
    // resize will change element width to pixels, we recalculate that to percentage to keep aspect ratio
    // of sidebar-left and manin-content panels when zoomin in/out
    onDrag: (_event: JQuery.Event, element: JQuery, _newWidth: number, _newHeight: number, _options: JQueryResizeable.ResizeableOptions): boolean => {
      const viewportWidth = window.innerWidth;
      const sidebarWidth = element.width();
      if (sidebarWidth) {
        const widthRatio = (sidebarWidth / viewportWidth) * 100;
        element.width(`${widthRatio}%`);
        // Supporting code for virtual scrolling
        updateResultsTableHeaderWrapper(sidebarWidth);
      }
      return true;
    },
  });

  // Supporting code for virtual scrolling
  $('.resultsTableRowsList').scroll(() => updateResultsTableHeaderWrapper($('.sidebar-left').width() ?? 0));

  const rowHeight = ($('.tableRow').height() ?? 55);
  const headerHeight = 55;
  const firstRowHeight = headerHeight + rowHeight;
  const rowHeights = [firstRowHeight, ...(new Array(searchResult.length).fill(true).map(() => rowHeight))];
  const getItemSize = (index: number) => rowHeights[index];

  const items = searchResult;
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = (startIndex: number, stopIndex: number): Promise<any> | any => {
    if (!isNextResultsPageLoading) {
      dispatchGetNextResultsPage(startIndex, stopIndex);
    }
  };
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  return (
    <>
      <div className={selectedResult !== null ? 'sidebar-left' : 'main-content'}>
        {searchResult.length > 0 && (
        <TableContainer className="resultsTableContainer" style={{ maxHeight: '100%' }} component={Paper}>
          <div className="resultsTableHeaderWrapper">
            <Table className="resultsTableHeader" size="small" stickyHeader={false}>
              <colgroup>
                <col width="5%" />
                <col width={selectedResult === null ? '10%' : '20%'} />
                <col width={selectedResult === null ? '15%' : '35%'} />
                <col width={selectedResult === null ? '10%' : '20%'} />
                <col width={selectedResult === null ? '10%' : '20%'} />

                {selectedResult === null ? <col width="10%" /> : null}
                {selectedResult === null ? <col width="10%" /> : null}
                {selectedResult === null ? <col width="10%" /> : null}
                {selectedResult === null ? <col width="10%" /> : null}
                {selectedResult === null ? <col width="10%" /> : null}
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {userCollectedReports.length > 0
                                && (
                                <div>
                                  {/* <ShareIcon onClick={() => setShareReportDialogOpen(true)}/> */}
                                  <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={() => setShareReportDialogOpen(true)}
                                        // startIcon={<ShareIcon />}
                                    data-toggle="Tooltip"
                                    data-placement="top"
                                    title="Share report set"
                                    style={{ width: 42, height: 42 }}
                                  >
                                    <ShareIcon fontSize="small" />
                                  </IconButton>
                                  <LinkDialog
                                    title="Copy link to collected reports"
                                    sharedUrl={urlForUserCollectedReports}
                                    open={shareReportDialogOpen}
                                    onClose={() => setShareReportDialogOpen(false)}
                                  />
                                </div>
                                )}
                  </TableCell>
                  <TableCell className="resultsTableHeaderTableCell overflow-ellipsis" align="center">Report type</TableCell>
                  <TableCell className="resultsTableHeaderTableCell overflow-ellipsis" align="center">Title</TableCell>
                  <TableCell className="resultsTableHeaderTableCell overflow-ellipsis" align="center">Wellbore</TableCell>
                  <TableCell className="resultsTableHeaderTableCell overflow-ellipsis" align="center">Rig</TableCell>

                  {selectedResult === null ? <TableCell className="resultsTableHeaderTableCell overflow-ellipsis" align="center">Section</TableCell> : null}
                  {selectedResult === null ? <TableCell className="resultsTableHeaderTableCell overflow-ellipsis" align="center">Depth range</TableCell> : null}
                  {selectedResult === null ? <TableCell className="resultsTableHeaderTableCell overflow-ellipsis" align="center">Formation</TableCell> : null}
                  {selectedResult === null ? <TableCell className="resultsTableHeaderTableCell overflow-ellipsis" align="center">Topics</TableCell> : null}
                  {selectedResult === null ? <TableCell className="resultsTableHeaderTableCell overflow-ellipsis" align="center">Report date</TableCell> : null}
                </TableRow>
              </TableHead>
            </Table>
          </div>
          {windowHeightResized()}
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            minimumBatchSize={100}
          >
            {({ onItemsRendered, ref }) => (
              <List
                className="resultsTableRowsList"
                itemCount={searchResult.length}
                itemSize={getItemSize}
                overscanCount={8}
                onItemsRendered={onItemsRendered}
                ref={ref}
                width="100%"
                height={resultsTableRowsListHeight(selectedResult !== null)}
              >
                {({ index, style }) => (
                  <div className="resultsTableRow" style={style}>
                    {index === 0 ? <div className="resultsTableHeaderOffset" /> : null}
                    <Table className="resultsTable" size="small">
                      <colgroup>
                        <col width="5%" />
                        <col width={selectedResult === null ? '10%' : '20%'} />
                        <col width={selectedResult === null ? '15%' : '35%'} />
                        <col width={selectedResult === null ? '10%' : '20%'} />
                        <col width={selectedResult === null ? '10%' : '20%'} />

                        {selectedResult === null ? <col width="10%" /> : null}
                        {selectedResult === null ? <col width="10%" /> : null}
                        {selectedResult === null ? <col width="10%" /> : null}
                        {selectedResult === null ? <col width="10%" /> : null}
                        {selectedResult === null ? <col width="10%" /> : null}
                      </colgroup>
                      <TableBody>
                        <TableRow
                          key={searchResult[index].resultGuid}
                          hover
                          className={`${classes.tableRow}
                          ${(selectedResult?.reportType === searchResult[index].reportType
                            && selectedResult.id === searchResult[index].id
                            ? classes.selectedRow : '')}`}
                        >
                          <TableCell align="center">
                            <Checkbox
                              defaultChecked={!(userCollectedReports.find((report) => report.resultGuid === searchResult[index].resultGuid) === undefined)}
                              onChange={(event) => handleChangeForIsUserCollected(searchResult[index], event.target.checked)}
                              color="primary"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                          </TableCell>
                          <TableCell align="center" onClick={() => setSelectedReport(searchResult[index])}>
                            <TableCellContentWithTooltip
                              className="text-two-lines"
                              id={`tableCell-reportType_${index}`}
                              title={searchResult[index].formattedReportType}
                            />
                          </TableCell>
                          <TableCell align="center" onClick={() => setSelectedReport(searchResult[index])} style={{ maxHeight: 50 }}>
                            <TableCellContentWithTooltip
                              className="text-two-lines"
                              id={`tableCell-title_${index}`}
                              title={searchResult[index].title}
                            />
                          </TableCell>
                          <TableCell align="center" onClick={() => setSelectedReport(searchResult[index])}>
                            <TableCellContentWithTooltip
                              className="text-two-lines"
                              id={`tableCell-wellbore_${index}`}
                              title={searchResult[index].wellboresString}
                            />
                          </TableCell>
                          <TableCell align="center" onClick={() => setSelectedReport(searchResult[index])}>
                            <TableCellContentWithTooltip
                              className="text-two-lines"
                              id={`tableCell-rig_${index}`}
                              title={searchResult[index].rigsString}
                            />
                          </TableCell>

                          {selectedResult === null ? (
                            <TableCell align="center" onClick={() => setSelectedReport(searchResult[index])}>
                              <TableCellContentWithTooltip
                                className="text-two-lines"
                                id={`tableCell-section_${index}`}
                                title={searchResult[index].sectionsString}
                              />
                            </TableCell>
                          ) : null}

                          {selectedResult === null ? (
                            <TableCell align="center" onClick={() => setSelectedReport(searchResult[index])}>
                              <TableCellContentWithTooltip
                                className="text-two-lines"
                                id={`tableCell-depthRange_${index}`}
                                title={searchResult[index].formattedDepthInterval}
                              />
                            </TableCell>
                          ) : null}

                          {selectedResult === null ? (
                            <TableCell align="center" onClick={() => setSelectedReport(searchResult[index])}>
                              <TableCellContentWithTooltip
                                className="text-two-lines"
                                id={`tableCell-formation_${index}`}
                                title={searchResult[index].formationsString}
                              />
                            </TableCell>
                          ) : null}

                          {selectedResult === null ? (
                            <TableCell align="center" onClick={() => setSelectedReport(searchResult[index])}>
                              <TableCellContentWithTooltip
                                className="text-two-lines"
                                id={`tableCell-topics_${index}`}
                                title={searchResult[index].topicsString}
                              />
                            </TableCell>
                          ) : null}
                          {selectedResult === null ? (
                            <TableCell align="center" onClick={() => setSelectedReport(searchResult[index])}>
                              <TableCellContentWithTooltip
                                className="text-two-lines"
                                id={`tableCell-reportDate_${index}`}
                                title={searchResult[index].formattedReportDate}
                              />
                            </TableCell>
                          ) : null}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                )}
              </List>
            )}
          </InfiniteLoader>
        </TableContainer>
        )}
      </div>
      {selectedResult !== null && (
      <>
        <div className="splitter" />
        <div className="main-content">
          <ReportDetails report={selectedResult} showHidePanelButton renderDependency={selectedResult} />
        </div>
      </>
      )}
    </>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    urlForUserCollectedReports: getShareUrl(state),
    shareReportDialogOpen: getIsShareDialogOpen(state),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, void, UserPrefsActionTypes | ReportSearchResultsActionTypes>): DispatchProps {
  return {
    setSelectedReport: (selectedResult: Report) => dispatch(setSelectedResult(selectedResult)),
    handleChangeForIsUserCollected: (report: Report, checkedValue: boolean) => dispatch(
      thunkUpdateQueryAndUrl(dispatch(updateSelectedReports(report, checkedValue))),
    ),
    dispatchGetNextResultsPage: (startIndex: number, stopIndex: number) => dispatch(getNextResultsPage(startIndex, stopIndex)),
    setShareReportDialogOpen: (dialogOpen: boolean) => dispatch(setShareDialogOpen(dialogOpen)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SearchResultsPanel);
