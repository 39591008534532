import { extractErrorMessage } from '../../common/errorUtils';
import { UNKNOW_ERROR_MESSAGE } from '../common/sagasCommon';
import {
  SHOW_ERROR, SHOW_PROGRESS, HIDE_ERROR, HIDE_PROGRESS, ReportErrorAction, REPORT_ERROR,
  ShowErrorAction, ShowProgressAction, HideErrorAction, HideProgressAction, SystemMessage,
} from './types';

/**
 * Shows the error message to the user and also explicitly sends it to App Insights.
 * The error message is extracted from the error object. (.message or if the error is string else "Unknown error.")
 * Use this action when the exception is not re-thrown. (ex. : Inside Sagas where we handle all exceptions.)
 * @param error
 * @returns {ReportErrorAction} ReportErrorAction
 */
export const reportError = (error: any) : ReportErrorAction => reportErrorWithCustomMessage(
  new SystemMessage(extractErrorMessage(error, UNKNOW_ERROR_MESSAGE)),
  error,
);

/**
 * Shows the error with a customized message to the user and also explicitly sends it to App Insights.
 * Use this action when the exception is not re-thrown. (ex. : Inside Sagas where we handle all exceptions.)
 * @param errorMessage Custom error message
 * @param error Error object
 * @returns {ReportErrorAction} ReportErrorAction
 */
export const reportErrorWithCustomMessage = (errorMessage: SystemMessage, error: any) : ReportErrorAction => ({
  type: REPORT_ERROR,
  errorMessage: errorMessage.message,
  error,
});

/**
 * Shows the error message to the user. (DOES NOT send it to App Insights.)
 * The error message is extracted from the error object. (.message or if the error is string else "Unknown error.")
 * @param error Error object
 * @returns {ShowErrorAction} ShowErrorAction
 */
export const showError = (error: any) : ShowErrorAction => showErrorWithCustomMessage(
  new SystemMessage(extractErrorMessage(error, UNKNOW_ERROR_MESSAGE)),
  error,
);

/**
 * Shows the error with a customized message to the user. (DOES NOT send it to App Insights.)
 * Use this action when the exception is not re-thrown. (ex. : Inside Sagas where we handle all exceptions.)
 * @param errorMessage Custom error message
 * @param error Error object
 * @returns {ShowErrorAction} ShowErrorAction
 */
export const showErrorWithCustomMessage = (errorMessage: SystemMessage, error: any) : ShowErrorAction => ({
  type: SHOW_ERROR,
  errorMessage: errorMessage.message,
  error,
});

export const showProgress = (progressMessage: SystemMessage) : ShowProgressAction => ({
  type: SHOW_PROGRESS,
  progressMessage: progressMessage.message,
});

export const hideError = () : HideErrorAction => ({
  type: HIDE_ERROR,
});

export const hideProgress = () : HideProgressAction => ({
  type: HIDE_PROGRESS,
});
