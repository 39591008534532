declare function initInsights(window:any, document:any, params: any) : void;
interface IAppInsightsCustomEvent {
    name : string,
    properties: object
}

interface IExceptionTelemtry {
    exception : Error,
    severityLevel: number
}

interface AppInsights {
    trackEvent (event: IAppInsightsCustomEvent, extraData: any) : void;
    trackException(exception: IExceptionTelemtry, extraData: any) : void;
    addTelemetryInitializer(fuction: any) : boolean;
}
declare let appInsights : AppInsights;

let appInsightsDepartment : string | null = null;

// send telemetry to app insights documentation: https://docs.microsoft.com/en-us/azure/azure-monitor/app/usage-overview#send-telemetry-from-your-app
export const initAppInsigths = (instrumentationKey : string, department : string) : void => {
  appInsightsDepartment = department;
  initInsights(window, document, {
    src: 'https://az416426.vo.msecnd.net/scripts/b/ai.2.min.js', // The SDK URL Source
    // name: "appInsights", // Global SDK Instance name defaults to "appInsights" when not supplied
    // ld: 0, // Defines the load delay (in ms) before attempting to load the sdk. -1 = block page load and add to head. (default) = 0ms load after timeout,
    // useXhr: 1, // Use XHR instead of fetch to report failures (if available),
    // crossOrigin: "anonymous", // When supplied this will add the provided value as the cross origin attribute on the script tag
    cfg: { // Application Insights Configuration
      instrumentationKey,
      /* ...Other Configuration Options... */
    },
  });

  const telemetryInitializer = (envelope: any) => {
    const authCodeRedirectUrlRegex = /#code=/;
    const isAuthCodeRedirectUrl = envelope && envelope.baseData && envelope.baseData.uri && authCodeRedirectUrlRegex.test(envelope.baseData.uri);

    // prevent sending any app insights logs which contains authorization code in URI data
    return !isAuthCodeRedirectUrl;
  };
  appInsights.addTelemetryInitializer(telemetryInitializer);
};

export abstract class AppInsightsEvent {
  private eventCode : string;

  public get EventCode() {
    return this.eventCode;
  }

  private eventParams : object;

  public get EventParams() {
    return this.eventParams;
  }

  constructor(eventCode: string, eventParams: object) {
    this.eventCode = eventCode;
    this.eventParams = eventParams;
  }
}

const appInsightsUninitializedMessage = 'App insights is not initialized.';

// see documentation for custom app insights events:
// https://docs.microsoft.com/en-us/azure/azure-monitor/app/usage-overview#custom-business-events
export const appInsightsTrackEvent = (event : AppInsightsEvent) : void => {
  if (Object.prototype.hasOwnProperty.call(window, 'appInsights')) {
    appInsights.trackEvent({ name: event.EventCode, properties: event.EventParams }, { department: appInsightsDepartment });
  } else {
    console.error(appInsightsUninitializedMessage);
  }
};

export const appInsightsLogError = (err : any) : void => {
  if (Object.prototype.hasOwnProperty.call(window, 'appInsights')) {
    appInsights.trackException({ exception: err, severityLevel: 3 }, { department: appInsightsDepartment });
  } else {
    console.error(appInsightsUninitializedMessage);
  }
};
