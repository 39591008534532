import * as React from 'react';
import {
  TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Paper,
} from '@material-ui/core';
import { IReport, IDescriptions } from '../store/searchResults/searchResultTypes';
import { formatReportType, GetFormattedTime } from '../common/formatters';
import { highlightText } from '../common/highlightText';

const splitDescriptionIntoLinesWithIndex = (text: string) => {
  const result = [];
  const delim = '\n';
  const splits = text.split(delim);
  let index = 0;
  for (let i = 0; i < splits.length; i += 1) {
    result.push({
      text: splits[i],
      index,
    });
    index += splits[i].length + delim.length;
  }
  return result;
};

export function GetReportTitle(report: IReport) {
  switch (report.reportType) {
    case 'DailyReport': {
      return (
        <>
          <strong>
            Title:
          </strong>
          {' '}
          {report.title}
        </>
      );
    }
    case 'SectionSummary': {
      return (
        <strong>
          {report.sections[0].sectionName}
          {' '}
          Section summary
        </strong>
      );
    }
    case 'Operation':
    case 'OperationSummary':
    case 'Incident':
    case 'Experience':
    default:
      return (
        <React.Fragment key={report.highlightedTitle}>
          <strong>
            {formatReportType(report.reportType)}
            {' '}
            title:
            {' '}
          </strong>
          {highlightText(report.highlightedTitle)}
        </React.Fragment>
      );
  }
}

export function GetReportDescription(report: IReport) {
  switch (report.reportType) {
    case 'DailyReport': {
      const sourceOperations : IDescriptions[] = report.sourceReports.slice();
      let sourceSummary : IDescriptions[] = new Array<IDescriptions>();
      for (let index = 0; index < report.sourceReports.length; index += 1) {
        if (report.sourceReports[index] != null && report.sourceReports[index].operationData == null) {
          sourceSummary = sourceOperations.splice(index, 1);
        }
      }

      return (
        <div>
          {SourceDescription(sourceSummary)}
          {sourceOperations.length > 0
            ? (
              <div>
                <strong>
                  Operations:
                </strong>
                {DateRangeWithDescriptionTable(sourceOperations)}
              </div>
            )
            : null}
        </div>
      );
    }
    case 'SectionSummary': {
      return SourceDescription(report.sourceReports);
    }
    case 'Operation':
    case 'OperationSummary':
    case 'Incident':
    case 'Experience':
    default:
      return (
        <React.Fragment key="BaseDescription">
          <strong>Description:</strong>
          {' '}
          {FormatDescription(report.description)}
        </React.Fragment>
      );
  }
}

function DateRangeWithDescriptionTable(descriptions: Array<IDescriptions>) {
  return (
    <div className="row">
      <div className="col-10">
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left">From</TableCell>
                <TableCell align="left">To</TableCell>
                <TableCell align="left">Main Activity</TableCell>
                <TableCell align="left">Sub Activity</TableCell>
                <TableCell align="left">Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {descriptions.map((descriptionItem: IDescriptions) => (
                <TableRow key={descriptionItem.id}>
                  <TableCell align="left">{GetFormattedTime(descriptionItem.operationData.startTime)}</TableCell>
                  <TableCell align="left">{GetFormattedTime(descriptionItem.operationData.endTime)}</TableCell>
                  <TableCell align="left">{descriptionItem.operationData.mainActivity}</TableCell>
                  <TableCell align="left">{descriptionItem.operationData.subActivity}</TableCell>
                  <TableCell align="left">{FormatDescription(descriptionItem.description)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

function SourceDescription(descriptions: Array<IDescriptions>) {
  return (
    <React.Fragment key="SourceDescriptions">
      {descriptions.map((descriptionItem: IDescriptions) => (
        <React.Fragment key={descriptionItem.title}>
          <strong>{descriptionItem.title}</strong>
          <br />
          {FormatDescription(descriptionItem.description)}
          <br />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

function FormatDescription(text: string) {
  return (
    <>
      {splitDescriptionIntoLinesWithIndex(text ?? '').map((descriptionWithIndex) => (
        <React.Fragment key={descriptionWithIndex.index}>
          <span>
            {highlightText(descriptionWithIndex.text)}
          </span>
          <br />
        </React.Fragment>
      ))}
    </>
  );
}
