import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReportQueryForAPI } from '../store/searchParameters/searchParametersTypes';
import { ReportDetails } from '../components/ReportDetails';
import { getAuthServiceSingleton, getLoginErrorMessage } from '../services/authentication/authService';
import { useMountEffect } from './App';
import { executeReportRetrieval } from '../store/reportDetails/actions';
import setupAppInsights from '../services/appInsights/appInsightsSetup';

import { getLinkedReport } from '../store/reportDetails/selectors';

interface IReportDetailsPageParams {
    reportType: string;
    reportId: string;
}

export function ReportDetailsPage() {
  const dispatch = useDispatch();
  const { reportType, reportId }: IReportDetailsPageParams = useParams();
  const reportQuery = new ReportQueryForAPI(reportId, reportType);

  useMountEffect(() => {
    const authService = getAuthServiceSingleton();
    authService.ensureUserLoggedIn()
      .catch((e) => {
        throw new Error(getLoginErrorMessage(e));
      })
      .then(setupAppInsights)
      .then(() => dispatch(executeReportRetrieval(reportQuery)));
  });

  const linkedReport = useSelector(getLinkedReport);

  return (
    <div>
      {linkedReport
        ? (
          <div className="row">
            <div className="col-1" />
            <div className="col-10">
              <ReportDetails report={linkedReport} />
            </div>
            <div className="col-1" />
          </div>
        )
        : null}
    </div>
  );
}
