import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'typed-redux-saga';
import thunk from 'redux-thunk';
import searchParametersSaga from './searchParameters/searchParametersSagas';
import facetableFieldsSagas from './searchParameters/facetableFieldsSagas';
import searchResultSaga from './searchResults/searchResultSagas';
import reportDetailsSaga from './reportDetails/sagas';
import appMessagesSaga from './appMessages/sagas';
import { authReducer } from './auth/reducers';
import { appMessagesReducer } from './appMessages/reducers';
import { reportDetailsReducer } from './reportDetails/reducers';
import { searchParametersReducer } from './searchParameters/reducers';
import { searchResultsReducer } from './searchResults/reducers';
import { userPrefsReducer } from './userPrefs/reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  appMessages: appMessagesReducer,
  reportDetails: reportDetailsReducer,
  searchParameters: searchParametersReducer,
  searchResults: searchResultsReducer,
  userPrefs: userPrefsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const composeEnhancers = composeWithDevTools({});
  // create the redux-saga middleware
  const sagaMiddleware = createSagaMiddleware();

  // We'll create our store with the combined reducers/sagas, and the initial Redux state that
  // we'll be passing from our entry point.
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware, thunk)),
  );

  sagaMiddleware.run(rootSaga);
  return store;
}

export const store = configureStore();

export function* rootSaga() {
  yield all([fork(searchResultSaga), fork(facetableFieldsSagas), fork(searchParametersSaga), fork(reportDetailsSaga), fork(appMessagesSaga)]);
}
