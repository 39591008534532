import {
  ReportSearchResultsActionTypes, EXECUTE_SEARCH_SUCCEEDED, GET_NEXT_RESULTS_PAGE_SUCCEEDED,
  SET_SELECTEDRESULT, SET_IS_USER_COLLECTED, SET_NEXT_RESULTS_PAGE_REQUESTED,
} from './actionTypes';
import { Report, mapIReportToReport, ISearchResult } from './searchResultTypes';

export interface IResultsState {
  items: Array<Report>,
  hasNextPage: boolean,
  isNextResultsPageLoading: boolean,
  receivedResultsCount: number,
  total: number,
  selectedResult: Report | null,
}

export const initialResultsState: IResultsState = {
  items: new Array<Report>(),
  hasNextPage: false,
  isNextResultsPageLoading: false,
  receivedResultsCount: 0,
  total: 0,
  selectedResult: null,
};

const hasNextPage = (searchResult: ISearchResult): boolean => (receivedResultsCount(searchResult) < searchResult.total);
const pageSize = 100;
const receivedResultsCount = (searchResult: ISearchResult): number => (searchResult.skip ?? 0) + pageSize;
const chooseTotalResultsEstimate = (numberOfResults: number, total: number): number => ((numberOfResults > total) ? numberOfResults : total);

export function searchResultsReducer(
  state = initialResultsState,
  action: ReportSearchResultsActionTypes,
): IResultsState {
  // const getAllUserCollectedReportsFromSearchResults = (state: IResultsState) => getAllUserCollectedReportsFromSearchState(state);

  const addResults = (searchResult: ISearchResult, userSelectedReports: Report[], newSelectedResult: Report | null) => {
    const resultReports = searchResult
      .items
      .filter((resReport) => userSelectedReports.filter((report) => report.id === resReport.id).length === 0)
      .map((x) => mapIReportToReport(x));

    const accumulatedResults = userSelectedReports.concat(resultReports);

    return {
      items: accumulatedResults,
      hasNextPage: hasNextPage(searchResult),
      isNextResultsPageLoading: false,
      receivedResultsCount: receivedResultsCount(searchResult),
      total: chooseTotalResultsEstimate(accumulatedResults.length, searchResult.total),
      selectedResult: newSelectedResult,
    };
  };

  switch (action.type) {
    case EXECUTE_SEARCH_SUCCEEDED: {
      return addResults(action.searchResult, action.userSelectedReports, null);
    }
    case GET_NEXT_RESULTS_PAGE_SUCCEEDED: {
      return addResults(action.searchResult, [...state.items], state.selectedResult);
    }

    case SET_NEXT_RESULTS_PAGE_REQUESTED: {
      return {
        ...state,
        isNextResultsPageLoading: true,
      };
    }

    case SET_SELECTEDRESULT: {
      const { selectedResult } = action;

      return {
        ...state,
        selectedResult,
      };
    }

    case SET_IS_USER_COLLECTED: {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === action.reportId) {
            const newReport = Report.Clone(item);
            newReport.isUserCollected = action.isUserCollected;
            return newReport;
          }

          return item;
        }),
      };
    }
    default:
      return state;
  }
}
