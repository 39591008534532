import {
  all, fork, takeEvery, put,
} from 'typed-redux-saga';
import { extractErrorMessage } from '../../common/errorUtils';
import { appInsightsLogError } from '../../services/appInsights/appInsights';
import { showErrorWithCustomMessage } from './actions';
import { ReportErrorAction, REPORT_ERROR, SystemMessage } from './types';

function* handleReportError(action : ReportErrorAction) {
  try {
    if (action.error != null) {
      appInsightsLogError(action.error);
    }
    yield put(showErrorWithCustomMessage(new SystemMessage(action.errorMessage), action.error));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Error while logging error.${extractErrorMessage(err)}`);
  }
}

function* watchReportError() {
  yield takeEvery(REPORT_ERROR, handleReportError);
}

function* appMessagesSaga() {
  yield all([fork(watchReportError)]);
}

export default appMessagesSaga;
