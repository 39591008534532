import {
  IdentityActionTypes, LOGIN_SUCCEDED, LOGIN_FAILED, LOGOUT_SUCCEDED, LOGOUT_FAILED, Auth, AuthState,
} from './types';

const initialState : Auth = new Auth(AuthState.LoggedOut, null);

export function authReducer(
  state = initialState,
  action: IdentityActionTypes,
): Auth {
  switch (action.type) {
    case LOGIN_SUCCEDED: {
      const { userIdentity } = action;
      return {
        ...state,
        authState: AuthState.LoggedIn,
        userIdentity,
      };
    }

    case LOGIN_FAILED: {
      return {
        ...state,
        authState: AuthState.LoggedOut,
        userIdentity: null,
      };
    }

    case LOGOUT_SUCCEDED: {
      return {
        ...initialState,
      };
    }

    case LOGOUT_FAILED: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
}
