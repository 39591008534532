import {
  all, call, fork, put, select, takeEvery,
} from 'typed-redux-saga';
import {
  getInitialInformationSucceeded, setOrdering, getFacetableFields, executeSearch,
} from './actions';
import { GetReportListByTypeAndIdResponse, ReportSearchApi } from '../../services/reportSearchApi';
import {
  showProgress, hideProgress, reportError, showError,
} from '../appMessages/actions';
import { SystemMessage } from '../appMessages/types';
import { getSearchQuery } from './searchParametersSelectors';
import { parseUrlQuery, getCollectedReportsShareUrl } from '../../common/urlParameters/urlParameterConverter';
import {
  GET_INITIAL_INFORMATION_REQUESTED, GetInitialInformationAction, SetOrderingRequestedAction, SET_ORDERING_REQUESTED,
} from './actionTypes';
import {
  ISearchQuery, IInitialInformationResponse, availableOrderingViewModels, hasInputParameters,
} from './searchParametersTypes';
import { mapIReportToReport } from '../searchResults/searchResultTypes';
import { setShareUrl, updateSelectedReports } from '../userPrefs/actions';
import { executeSearchSucceeded } from '../searchResults/actions';

function* handleGetInitialInformation(action : GetInitialInformationAction) {
  const reportSearchApi = new ReportSearchApi();
  const findMissingUserCollectedReportKeys = (providedIds: string[], returnedIds: string[]): string[] => providedIds
    .filter((x) => !returnedIds.includes(x)).filter(String);

  yield put(showProgress(new SystemMessage('Getting initial information from server')));

  try {
    const initialInformation : IInitialInformationResponse = yield call(reportSearchApi.getInitialInformation);
    initialInformation.searchParameterAvailableValues.orderings = availableOrderingViewModels;

    // userCollectedReportKeys0
    const [userQuery, userCollectedReportKeys] = parseUrlQuery(action.urlQueryPart, initialInformation.searchParameterAvailableValues);

    // If there exists previously selected reports in URL, get their information and show them as results, whether search is on or not
    if (userCollectedReportKeys && userCollectedReportKeys.length > 0) {
      const response : GetReportListByTypeAndIdResponse = yield call(reportSearchApi.getReportListByTypeAndId, userCollectedReportKeys);
      const userCollectedReports = response.reports.map((report) => mapIReportToReport(report));
      const missingUserCollectedReportKeys = findMissingUserCollectedReportKeys(userCollectedReportKeys.map((x) => x.id), response.reports.map((x) => x.id));
      if (missingUserCollectedReportKeys.length > 0) {
        const reportWord = missingUserCollectedReportKeys.length === 1 ? 'report was' : 'reports were';
        const message = `The following ${reportWord} referenced, but could not be found: ${missingUserCollectedReportKeys.join(', ')}.`;
        yield put(showError(message));
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const report of userCollectedReports) {
        report.isUserCollected = true;
        yield put(updateSelectedReports(report, true));
      }

      yield put(setShareUrl(getCollectedReportsShareUrl(userCollectedReports)));

      yield put(executeSearchSucceeded({ items: [], total: 0, skip: 0 }, userCollectedReports));
    }

    yield put(getInitialInformationSucceeded(initialInformation, userQuery));
    if (hasInputParameters(userQuery.searchParams) || userQuery.text !== '') {
      yield put(getFacetableFields());
    }
  } catch (err) {
    yield put(reportError(err));
  }

  yield put(hideProgress());

  try {
    const userQuery : ISearchQuery = yield select(getSearchQuery);
    if (userQuery.executeQueryOnStartup) {
      yield put(executeSearch());
    }
  } catch (err) {
    yield put(reportError(err));
  }
}

function* watchGetInitialInformation() {
  yield takeEvery(GET_INITIAL_INFORMATION_REQUESTED, handleGetInitialInformation);
}

function* handleSetOrderingRequested(action : SetOrderingRequestedAction) {
  const { ordering } = action;
  yield put(setOrdering(ordering));
  yield put(executeSearch());
}

function* watchSetOrderingRequested() {
  yield takeEvery(SET_ORDERING_REQUESTED, handleSetOrderingRequested);
}

function* searchParametersSaga() {
  yield all([fork(watchGetInitialInformation), fork(watchSetOrderingRequested)]);
}

export default searchParametersSaga;
