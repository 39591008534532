import { IReport } from '../searchResults/searchResultTypes';
import {
  EXECUTE_REPORT_RETRIEVAL_SUCCEEDED, ReportDetailsActionTypes, EXECUTE_CLEAR_RELATED_REPORTS,
  GET_RELATED_REPORTS_REQUESTED, GET_RELATED_REPORTS_SUCCEEDED, GET_RELATED_REPORTS_FAILED,
  GET_SIMILAR_REPORTS_REQUESTED, GET_SIMILAR_REPORTS_SUCCEEDED, GET_SIMILAR_REPORTS_FAILED,
  LOAD_MORE_SIMILAR_REPORTS_FAILED, LOAD_MORE_SIMILAR_REPORTS_REQUESTED, LOAD_MORE_SIMILAR_REPORTS_SUCCEEDED,
  LOAD_MORE_RELATED_REPORTS_REQUESTED, LOAD_MORE_RELATED_REPORTS_SUCCEEDED, LOAD_MORE_RELATED_REPORTS_FAILED,
} from './actionTypes';

interface IReportDetailstate {
  linkedReport: IReport | null;

  relatedReports: IReport[];
  relatedReportsTotalCount: number;
  existsMoreRelatedReports: boolean;

  similarReports: IReport[];
  similarReportsTotalCount: number;
  existsMoreSimilarReports: boolean;

  isGettingRelatedReports : boolean;
  isLoadingMoreRelatedReports : boolean;

  isGettingSimilarReports : boolean;
  isLoadingMoreSimilarReports : boolean;
}

const initialState: IReportDetailstate = {
  linkedReport: null,
  relatedReports: [],
  relatedReportsTotalCount: 0,
  existsMoreRelatedReports: false,

  similarReports: [],
  similarReportsTotalCount: 0,
  existsMoreSimilarReports: false,

  isGettingRelatedReports: false,
  isLoadingMoreRelatedReports: false,

  isGettingSimilarReports: false,
  isLoadingMoreSimilarReports: false,
};

export function reportDetailsReducer(
  state = initialState,
  action: ReportDetailsActionTypes,
): IReportDetailstate {
  switch (action.type) {
    // ReportRetrieval ------------------------------------
    case EXECUTE_REPORT_RETRIEVAL_SUCCEEDED: {
      const retrievedReport = action.reportResult;
      return {
        ...state,
        linkedReport: retrievedReport,
      };
    }
    // GetRelatedReports ----------------------------------
    case GET_RELATED_REPORTS_REQUESTED: {
      return {
        ...state,
        isGettingRelatedReports: true,
      };
    }
    case GET_RELATED_REPORTS_SUCCEEDED: {
      const response = action.relatedReportsResponse;
      return {
        ...state,
        relatedReports: response.reports,
        relatedReportsTotalCount: response.totalReportCount,
        existsMoreRelatedReports: response.reports.length < response.totalReportCount,
        isGettingRelatedReports: false,
      };
    }

    case GET_RELATED_REPORTS_FAILED: {
      return {
        ...state,
        relatedReports: [],
        isGettingRelatedReports: false,
      };
    }

    // LoadMoreRelatedReports ----------------------------------
    case LOAD_MORE_RELATED_REPORTS_REQUESTED: {
      return {
        ...state,
        isLoadingMoreRelatedReports: true,
      };
    }
    case LOAD_MORE_RELATED_REPORTS_SUCCEEDED: {
      const response = action.relatedReportsResponse;
      const extendedReports = state.relatedReports.concat(response.reports);
      return {
        ...state,
        relatedReports: extendedReports,
        relatedReportsTotalCount: response.totalReportCount,
        existsMoreRelatedReports: extendedReports.length < response.totalReportCount,
        isLoadingMoreRelatedReports: false,
      };
    }

    case LOAD_MORE_RELATED_REPORTS_FAILED: {
      return {
        ...state,
        relatedReports: [],
        isLoadingMoreRelatedReports: false,
      };
    }

    // GetSimilarReports ----------------------------------
    case GET_SIMILAR_REPORTS_REQUESTED: {
      return {
        ...state,
        isGettingSimilarReports: true,
      };
    }
    case GET_SIMILAR_REPORTS_SUCCEEDED: {
      const response = action.similarReportsResponse;
      return {
        ...state,
        similarReports: response.reports,
        similarReportsTotalCount: response.totalReportCount,
        existsMoreSimilarReports: response.reports.length < response.totalReportCount,
        isGettingSimilarReports: false,
      };
    }

    case GET_SIMILAR_REPORTS_FAILED: {
      return {
        ...state,
        similarReports: [],
        isGettingSimilarReports: false,
      };
    }

    // LoadMoreSimilarReports ----------------------------------
    case LOAD_MORE_SIMILAR_REPORTS_REQUESTED: {
      return {
        ...state,
        isGettingRelatedReports: false,
        isLoadingMoreSimilarReports: true,
      };
    }
    case LOAD_MORE_SIMILAR_REPORTS_SUCCEEDED: {
      const response = action.similarReportsResponse;
      const extendedReports = state.similarReports.concat(response.reports);

      return {
        ...state,
        similarReports: extendedReports,
        similarReportsTotalCount: response.totalReportCount,
        existsMoreSimilarReports: extendedReports.length < response.totalReportCount,
        isGettingRelatedReports: false,
        isLoadingMoreSimilarReports: false,
      };
    }

    case LOAD_MORE_SIMILAR_REPORTS_FAILED: {
      return {
        ...state,
        similarReports: [],
        isGettingRelatedReports: false,
        isLoadingMoreSimilarReports: false,
      };
    }

    // ClearRelatedReports ---------------------------------
    case EXECUTE_CLEAR_RELATED_REPORTS: {
      return {
        ...state,
        relatedReports: [],
        similarReports: [],
      };
    }

    default:
      return state;
  }
}
