import {
  all, call, fork, put, select, takeEvery,
} from 'typed-redux-saga';
import {
  EXECUTE_REPORT_RETRIEVAL_REQUESTED, ExecuteReportRetrievalActionRequested, LoadMoreRelatedReportsActionRequested,
  GET_RELATED_REPORTS_REQUESTED, GET_SIMILAR_REPORTS_REQUESTED, GetSimilarReportsActionRequested,
  LoadMoreSimilarReportsActionRequested, LOAD_MORE_SIMILAR_REPORTS_REQUESTED, LOAD_MORE_RELATED_REPORTS_REQUESTED,
} from './actionTypes';
import {
  executeReportRetrievalSucceeded, getRelatedReportsSucceeded, getRelatedReportsFailed,
  getSimilarReportsFailed, getSimilarReportsSucceeded,
  loadMoreSimilarReportsSucceeded, loadMoreSimilarReportsFailed, loadMoreRelatedReportsFailed, loadMoreRelatedReportsSucceeded,
} from './actions';
import { ReportSearchApi } from '../../services/reportSearchApi';
import { SystemMessage } from '../appMessages/types';
import { showProgress, hideProgress, reportError } from '../appMessages/actions';
import { moveViewToDiv } from '../../components/Animator';
import { MESSAGESPANEL_DIV_ID } from '../searchResults/searchResultTypes';
import { IRelatedReportsRequest } from './types';
import { getRelatedReportsCount, getSimilarReportsCount } from './selectors';

// GetReport ----------------------------------------------
function* handleExecuteReportQuery(action: ExecuteReportRetrievalActionRequested) {
  const reportSearchApi = new ReportSearchApi();

  yield put(showProgress(new SystemMessage('Retrieving report from server...')));

  try {
    moveViewToDiv(MESSAGESPANEL_DIV_ID);
    const res = yield call(reportSearchApi.getReportByTypeAndId, action.reportQuery);
    yield put(executeReportRetrievalSucceeded(res));
  } catch (err) {
    yield put(reportError(err));
  }

  yield put(hideProgress());
}

function* watchExecuteGetReport() {
  yield takeEvery(EXECUTE_REPORT_RETRIEVAL_REQUESTED, handleExecuteReportQuery);
}

// GetRelatedReports --------------------------------------
function* handleExecuteRelatedReportsQuery(action: LoadMoreRelatedReportsActionRequested) {
  const reportSearchApi = new ReportSearchApi();
  const request : IRelatedReportsRequest = {
    reportKey: {
      id: action.reportQuery.id,
      type: action.reportQuery.type,
    },
    pageInfo: {
      skip: 0,
      top: 10,
    },
  };

  try {
    const res = yield call(reportSearchApi.getRelatedReportsByTypeAndId, request);
    yield put(getRelatedReportsSucceeded(res));
  } catch (err) {
    moveViewToDiv(MESSAGESPANEL_DIV_ID);
    yield put(reportError(err));
    yield put(getRelatedReportsFailed());
  }
}

function* watchExecuteGetRelatedReports() {
  yield takeEvery(GET_RELATED_REPORTS_REQUESTED, handleExecuteRelatedReportsQuery);
}

// LoadMoreRelatedReports ---------------------------------
function* handleLoadMoreRelatedReportsRequested(action: LoadMoreSimilarReportsActionRequested) {
  const reportSearchApi = new ReportSearchApi();
  const relatedReportsCount = yield select(getRelatedReportsCount);

  const request : IRelatedReportsRequest = {
    reportKey: {
      id: action.reportQuery.id,
      type: action.reportQuery.type,
    },
    pageInfo: {
      skip: relatedReportsCount,
      top: 10,
    },
  };
  try {
    const res = yield call(reportSearchApi.getRelatedReportsByTypeAndId, request);
    yield put(loadMoreRelatedReportsSucceeded(res));
  } catch (err) {
    moveViewToDiv(MESSAGESPANEL_DIV_ID);
    yield put(reportError(err));
    yield put(loadMoreRelatedReportsFailed());
  }
}

function* watchLoadMoreRelatedReports() {
  yield takeEvery(LOAD_MORE_RELATED_REPORTS_REQUESTED, handleLoadMoreRelatedReportsRequested);
}

// GetSimilarReports --------------------------------------
function* handleGetSimilarReportsRequested(action: GetSimilarReportsActionRequested) {
  const reportSearchApi = new ReportSearchApi();
  const request : IRelatedReportsRequest = {
    reportKey: {
      id: action.reportQuery.id,
      type: action.reportQuery.type,
    },
    pageInfo: {
      skip: 0,
      top: 10,
    },
  };
  try {
    const res = yield call(reportSearchApi.getSimilarReportsByTypeAndId, request);
    yield put(getSimilarReportsSucceeded(res));
  } catch (err) {
    moveViewToDiv(MESSAGESPANEL_DIV_ID);
    yield put(reportError(err));
    yield put(getSimilarReportsFailed());
  }
}

function* watchGetSimilarReports() {
  yield takeEvery(GET_SIMILAR_REPORTS_REQUESTED, handleGetSimilarReportsRequested);
}

// LoadMoreSimilarReports ---------------------------------
function* handleLoadMoreSimilarReportsRequested(action: LoadMoreSimilarReportsActionRequested) {
  const reportSearchApi = new ReportSearchApi();
  const similarReportsCount = yield select(getSimilarReportsCount);

  const request : IRelatedReportsRequest = {
    reportKey: {
      id: action.reportQuery.id,
      type: action.reportQuery.type,
    },
    pageInfo: {
      skip: similarReportsCount,
      top: 10,
    },
  };
  try {
    const res = yield call(reportSearchApi.getSimilarReportsByTypeAndId, request);
    yield put(loadMoreSimilarReportsSucceeded(res));
  } catch (err) {
    moveViewToDiv(MESSAGESPANEL_DIV_ID);
    yield put(reportError(err));
    yield put(loadMoreSimilarReportsFailed());
  }
}

function* watchLoadMoreSimilarReports() {
  yield takeEvery(LOAD_MORE_SIMILAR_REPORTS_REQUESTED, handleLoadMoreSimilarReportsRequested);
}

export default function* reportDetailsSaga() {
  yield all([fork(watchExecuteGetReport), fork(watchExecuteGetRelatedReports),
    fork(watchGetSimilarReports), fork(watchLoadMoreSimilarReports), fork(watchLoadMoreRelatedReports)]);
}
