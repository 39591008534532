import React from 'react';

export const highlightText = (text: String) => {
  if (!text) return '';
  const regex = /(<em>[^<]+<\/em>)/g;
  const splitText = text.split(regex);

  let keyCounter = 0;
  return splitText.map((part) => {
    if (regex.test(part)) {
      keyCounter += 1;
      return (
        <mark key={keyCounter} style={{ backgroundColor: '#e0a060' }}>{part.replace('<em>', '').replace('</em>', '')}</mark>
      );
    }
    return part;
  });
};
