import {
  all, call, fork, put, select, takeEvery,
} from 'typed-redux-saga';
import { GET_FACETABLE_FIELDS_REQUESTED } from './actionTypes';
import { getFacetableFieldsSucceeded } from './actions';
import { ReportSearchApi } from '../../services/reportSearchApi';
import { SearchParameterAvailableValues } from './searchParametersTypes';
import { getSearchQueryForAPI } from './searchParametersSelectors';
import { reportError } from '../appMessages/actions';

function* handleGetFacetableField() {
  const reportSearchApi = new ReportSearchApi();

  try {
    const userQuery = yield select(getSearchQueryForAPI);
    userQuery.skip = 0;

    const facetableFields : SearchParameterAvailableValues = yield call(reportSearchApi.getFacets, userQuery);
    yield put(getFacetableFieldsSucceeded(facetableFields, userQuery));
  } catch (err) {
    yield put(reportError(err));
  }
}

function* watchGetFacetableFields() {
  yield takeEvery(GET_FACETABLE_FIELDS_REQUESTED, handleGetFacetableField);
}

function* facetableFieldsSagas() {
  yield all([fork(watchGetFacetableFields)]);
}

export default facetableFieldsSagas;
