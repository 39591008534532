export class SystemMessage {
  constructor(
        public message: string,
  ) {}
}

export const SHOW_ERROR = 'SHOW_ERROR';
export interface ShowErrorAction {
    type: typeof SHOW_ERROR;
    errorMessage: string;
    error: any;
}

export const REPORT_ERROR = 'REPORT_ERROR';
export interface ReportErrorAction {
    type: typeof REPORT_ERROR;
    errorMessage: string;
    error: any;
}

export const SHOW_PROGRESS = 'SHOW_PROGRESS';
export interface ShowProgressAction {
    type: typeof SHOW_PROGRESS;
    progressMessage: string;
}

export const HIDE_ERROR = 'HIDE_ERROR';
export interface HideErrorAction {
    type: typeof HIDE_ERROR;
}

export const HIDE_PROGRESS = 'HIDE_PROGRESS';
export interface HideProgressAction {
    type: typeof HIDE_PROGRESS;
}

export type appMessagesActionTypes =
    ShowErrorAction | ReportErrorAction | ShowProgressAction | HideErrorAction | HideProgressAction;

export interface IAppMessages {
    errorMessage: SystemMessage,
    progressMessage: SystemMessage
}
