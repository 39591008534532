import { formatDateYYYYDashMMDashDD, parseYYYYDashMMDashDDToDate } from '../formatters';
import {
  Country, DEFAULT_MAXIMUM_DATE, DEFAULT_MAXIMUM_DEPTH, DEFAULT_MINIMUM_DATE, DEFAULT_MINIMUM_DEPTH, DEFAULT_LUCENE,
  Field, Formation, ReportType, Rig, SearchParameterAvailableValues, ISearchQuery, SectionName, SectionType, Topic, Wellbore, OrderingViewModel,
} from '../../store/searchParameters/searchParametersTypes';
import history from '../history';
import { Report } from '../../store/searchResults/searchResultTypes';
import { getBaseUrl } from '../baseUrl';
import { AUTH_PARAM_NAME, AUTH_PARAM_VALUE_POPUP, getAuthServiceSingleton } from '../../services/authentication/authService';
import { IReportKey } from '../../store/common/types';

const countriesParamKey = 'countries';
const fieldsParamKey = 'fields';
const wellboresParamKey = 'wellbores';
const sectionTypesParamKey = 'sectiontypes';
const sectionsParamKey = 'sections';
const rigsParamKey = 'rigs';
const formationsParamKey = 'formations';
const reportTypesParamKey = 'reporttypes';
const topicsParamKey = 'topics';
const startDateParamKey = 'startdate';
const endDateParamKey = 'enddate';
const startDepthParamKey = 'startdepth';
const endDepthParamKey = 'enddepth';
const searchTextParamKey = 'searchtext';
const executeSearchParamKey = 'search';
const collectedReportKeysParamKey = 'reports';
const luceneParamKey = 'lucene';
const orderingParamKey = 'ordering';

export const updateUrlQuery = (searchQuery: ISearchQuery, reports: Array<Report>) : string => {
  const authService = getAuthServiceSingleton();
  const params = generateUrlQuery(searchQuery, reports, authService.isPopupAuth());
  history.replace(`/?${params}`);

  return getCollectedReportsShareUrl(reports);
};

export const getCollectedReportsShareUrl = (reports: Array<Report>) => `${getBaseUrl()}?${addCollectedReportParams('', reports)}`;

export const generateUrlQuery = (searchQuery : ISearchQuery, reports: Array<Report>, isPopupAuth: boolean) : string => {
  let urlQueryPart = '';
  const parameterObjects = searchQuery.searchParams;
  urlQueryPart = addListUrlParameterEncoded(urlQueryPart, countriesParamKey, parameterObjects.countries.map((x) => x.name));
  urlQueryPart = addListUrlParameterEncoded(urlQueryPart, fieldsParamKey, parameterObjects.fields.map((x) => x.name));
  urlQueryPart = addListUrlParameterEncoded(urlQueryPart, wellboresParamKey, parameterObjects.wellbores.map((x) => x.name));
  urlQueryPart = addListUrlParameterEncoded(urlQueryPart, sectionTypesParamKey, parameterObjects.sectionTypes.map((x) => x.name));
  urlQueryPart = addListUrlParameterEncoded(urlQueryPart, sectionsParamKey, parameterObjects.sectionNames.map((x) => x.name));
  urlQueryPart = addListUrlParameterEncoded(urlQueryPart, rigsParamKey, parameterObjects.rigs.map((x) => x.name));
  urlQueryPart = addListUrlParameterEncoded(urlQueryPart, formationsParamKey, parameterObjects.formations.map((x) => x.name));
  urlQueryPart = addListUrlParameterEncoded(urlQueryPart, reportTypesParamKey, parameterObjects.reportTypes.map((x) => x.name));
  urlQueryPart = addListUrlParameterEncoded(urlQueryPart, topicsParamKey, parameterObjects.topics.map((x) => x.name));
  urlQueryPart = addListUrlParameterEncoded(
    urlQueryPart,
    orderingParamKey,
    parameterObjects.orderings.map((x) => `${x.ordering.orderBy}-${x.ordering.orderByDirection}`),
  );

  urlQueryPart = addCollectedReportParams(urlQueryPart, reports);

  if (formatDateYYYYDashMMDashDD(parameterObjects.startDate) !== formatDateYYYYDashMMDashDD(DEFAULT_MINIMUM_DATE)) {
    urlQueryPart = addUrlParameterEncoded(urlQueryPart, startDateParamKey, formatDateYYYYDashMMDashDD(parameterObjects.startDate));
  }

  if (formatDateYYYYDashMMDashDD(parameterObjects.endDate) !== formatDateYYYYDashMMDashDD(DEFAULT_MAXIMUM_DATE)) {
    urlQueryPart = addUrlParameterEncoded(urlQueryPart, endDateParamKey, formatDateYYYYDashMMDashDD(parameterObjects.endDate));
  }

  if (parameterObjects.startDepth !== DEFAULT_MINIMUM_DEPTH) {
    urlQueryPart = addUrlParameterEncoded(urlQueryPart, startDepthParamKey, parameterObjects.startDepth.toString());
  }

  if (parameterObjects.endDepth !== DEFAULT_MAXIMUM_DEPTH) {
    urlQueryPart = addUrlParameterEncoded(urlQueryPart, endDepthParamKey, parameterObjects.endDepth.toString());
  }

  if (searchQuery.text) {
    urlQueryPart = addUrlParameterEncoded(urlQueryPart, searchTextParamKey, searchQuery.text);
  }

  if (parameterObjects.lucene !== DEFAULT_LUCENE) {
    urlQueryPart = addUrlParameterEncoded(urlQueryPart, luceneParamKey, String(parameterObjects.lucene));
  }

  if (searchQuery.executeQueryOnStartup) {
    urlQueryPart = addUrlParameterEncoded(urlQueryPart, executeSearchParamKey, 'true');
  }

  if (isPopupAuth) {
    urlQueryPart = addUrlParameterEncoded(urlQueryPart, AUTH_PARAM_NAME, AUTH_PARAM_VALUE_POPUP);
  }

  return urlQueryPart;
};

export const addCollectedReportParams = (urlQueryPart : string, reports: Array<Report>) => addListUrlParameterEncoded(
  urlQueryPart,
  collectedReportKeysParamKey,
  reports.map((x) => `${x.reportType}-${x.id}`),
);

const addListUrlParameterEncoded = (urlQueryPart: string, parameterName: string, values: string[]) => {
  if (values !== null && values.length > 0) {
    return addUrlParameter(urlQueryPart, parameterName, values.map((x) => encodeURIComponent(x)).join(','));
  }
  return urlQueryPart;
};

const addUrlParameterEncoded = (urlQueryPart: string, parameterName: string, value: string) => addUrlParameter(
  urlQueryPart,
  parameterName,
  encodeURIComponent(value),
);

const addUrlParameter = (urlQueryPart: string, parameterName: string, value: string) => {
  const newUrlQueryPart = `${(urlQueryPart.length > 0 ? '&' : '') + parameterName}=${value}`;
  return urlQueryPart + newUrlQueryPart;
};

interface IParsedUrlParameters {
    paramKeyValuesPairs : Array<IParsedUrlParameter>;
}

interface IParsedUrlParameter {
    paramKey : string;
    paramValues : Array<string>;
}

export const parseUrlQuery = (urlQuery : string, availableValues: SearchParameterAvailableValues) : [ISearchQuery, Array<IReportKey>] => {
  const parsedSearchQuery : ISearchQuery = {
    searchParams: {
      countries: new Array<Country>(),
      fields: new Array<Field>(),
      wellbores: new Array<Wellbore>(),
      sectionNames: new Array<SectionName>(),
      sectionTypes: new Array<SectionType>(),
      rigs: new Array<Rig>(),
      formations: new Array<Formation>(),
      reportTypes: new Array<ReportType>(),
      topics: new Array<Topic>(),
      startDate: new Date(DEFAULT_MINIMUM_DATE),
      endDate: new Date(DEFAULT_MAXIMUM_DATE),
      startDepth: DEFAULT_MINIMUM_DEPTH,
      endDepth: DEFAULT_MAXIMUM_DEPTH,
      lucene: DEFAULT_LUCENE,
      orderings: new Array<OrderingViewModel>(),
    },
    text: '',
    executeQueryOnStartup: false,
  };

  const parsedUrlParameters = parseUrlQueryPart(urlQuery);

  parseObjectParameterList<Country>(
    parsedUrlParameters,
    countriesParamKey,
    (paramValue) => availableValues.countries.find((x) => x.name === paramValue),
    (paramObject) => parsedSearchQuery.searchParams.countries.push(paramObject),
  );

  parseObjectParameterList<Field>(
    parsedUrlParameters,
    fieldsParamKey,
    (paramValue) => availableValues.fields.find((x) => x.name === paramValue),
    (paramObject) => parsedSearchQuery.searchParams.fields.push(paramObject),
  );

  parseObjectParameterList<Wellbore>(
    parsedUrlParameters,
    wellboresParamKey,
    (paramValue) => availableValues.wellbores.find((x) => x.name === paramValue),
    (paramObject) => parsedSearchQuery.searchParams.wellbores.push(paramObject),
  );

  parseObjectParameterList<SectionType>(
    parsedUrlParameters,
    sectionTypesParamKey,
    (paramValue) => availableValues.sectionTypes.find((x) => x.name === paramValue),
    (paramObject) => parsedSearchQuery.searchParams.sectionTypes.push(paramObject),
  );

  parseObjectParameterList<SectionName>(
    parsedUrlParameters,
    sectionsParamKey,
    (paramValue) => availableValues.sectionNames.find((x) => x.name === paramValue),
    (paramObject) => parsedSearchQuery.searchParams.sectionNames.push(paramObject),
  );

  parseObjectParameterList<Rig>(
    parsedUrlParameters,
    rigsParamKey,
    (paramValue) => availableValues.rigs.find((x) => x.name === paramValue),
    (paramObject) => parsedSearchQuery.searchParams.rigs.push(paramObject),
  );

  parseObjectParameterList<Formation>(
    parsedUrlParameters,
    formationsParamKey,
    (paramValue) => availableValues.formations.find((x) => x.name === paramValue),
    (paramObject) => parsedSearchQuery.searchParams.formations.push(paramObject),
  );

  parseObjectParameterList<ReportType>(
    parsedUrlParameters,
    reportTypesParamKey,
    (paramValue) => availableValues.reportTypes.find((x) => x.name === paramValue),
    (paramObject) => parsedSearchQuery.searchParams.reportTypes.push(paramObject),
  );

  parseObjectParameterList<Topic>(
    parsedUrlParameters,
    topicsParamKey,
    (paramValue) => availableValues.topics.find((x) => x.name === paramValue),
    (paramObject) => parsedSearchQuery.searchParams.topics.push(paramObject),
  );

  parseObjectParameterList<OrderingViewModel>(
    parsedUrlParameters,
    orderingParamKey,
    (paramValue) => {
      const orderingValues = paramValue.split('-');
      if (orderingValues.length < 2) {
        return undefined;
      }
      const orderBy = orderingValues[0];
      const orderByDirection = orderingValues[1];

      const orderingViewModel = availableValues.orderings.find((x) => x.ordering.orderBy === orderBy && x.ordering.orderByDirection === orderByDirection);
      return orderingViewModel;
    },
    (paramObject) => parsedSearchQuery.searchParams.orderings.push(paramObject),
  );

  const userCollectedReportKeys = parseReportKeyParameterList(parsedUrlParameters);

  parseDateParameter(
    parsedUrlParameters,
    startDateParamKey,
    (date) => { parsedSearchQuery.searchParams.startDate = date; },
  );

  parseDateParameter(
    parsedUrlParameters,
    endDateParamKey,
    (date) => { parsedSearchQuery.searchParams.endDate = date; },
  );

  parseNumberParameter(
    parsedUrlParameters,
    startDepthParamKey,
    (depth) => { parsedSearchQuery.searchParams.startDepth = depth; },
  );

  parseNumberParameter(
    parsedUrlParameters,
    endDepthParamKey,
    (depth) => { parsedSearchQuery.searchParams.endDepth = depth; },
  );

  parseStringParameter(
    parsedUrlParameters,
    searchTextParamKey,
    (searchText) => { parsedSearchQuery.text = searchText; },
  );

  parseBooleanParameter(
    parsedUrlParameters,
    executeSearchParamKey,
    (executeSearch) => { parsedSearchQuery.executeQueryOnStartup = executeSearch; },
  );

  parseBooleanParameter(
    parsedUrlParameters,
    luceneParamKey,
    (lucene) => { parsedSearchQuery.searchParams.lucene = lucene; },
  );

  return [parsedSearchQuery, userCollectedReportKeys];
};

const parseUrlQueryPart = (urlQuery: string) : IParsedUrlParameters => {
  let cleanedUrlQuery = urlQuery.trim();
  if ((urlQuery.trim())[0] === '?') {
    cleanedUrlQuery = cleanedUrlQuery.substring(1);
  }

  const paramKeyValuePairs = cleanedUrlQuery.split('&').map((x) => {
    const parts = x.split('=');
    const key = parts[0];
    const value = parts[1];
    return [key, value];
  });

  const paramKeyValuesPairs = paramKeyValuePairs.map((paramKeyValuePair) => {
    const paramKey = paramKeyValuePair[0];
    const paramValuesCommaSeparated = paramKeyValuePair[1];
    const paramValues = paramValuesCommaSeparated === undefined
      ? []
      : paramValuesCommaSeparated.split(',').map((x) => decodeURIComponent(x));
    return { paramKey, paramValues };
  });

  return { paramKeyValuesPairs };
};

const parseObjectParameterList = <TParameterObject> (
  parsedUrlParameters: IParsedUrlParameters,
  urlParamKey: string,
  findParamObjectPredicate: (paramValue: string) => TParameterObject | undefined,
  processObjectParam: (paramObject: TParameterObject) => void) : void => {
  const paramKeyValuePair = findParamKeyValuePair(parsedUrlParameters, urlParamKey);
  if (paramKeyValuePair !== undefined) {
    paramKeyValuePair.paramValues.forEach((paramValue) => {
      const paramObject = findParamObjectPredicate(paramValue);
      if (paramObject !== undefined && paramObject !== null) {
        const nonEmptyParamObject = paramObject as TParameterObject;
        processObjectParam(nonEmptyParamObject);
      }
    });
  }
};

const parseReportKeyParameterList = (parsedUrlParameters: IParsedUrlParameters) : Array<IReportKey> => {
  const emptyList = new Array<IReportKey>();

  if (!parsedUrlParameters) {
    return emptyList;
  }

  const collectedReportKeyParams = findParamKeyValuePair(parsedUrlParameters, collectedReportKeysParamKey);
  if (!collectedReportKeyParams || !collectedReportKeyParams.paramValues) {
    return emptyList;
  }

  const reportKeyRegExp = /^(Experience|Incident|Operation|OperationSummary|SectionSummary)-(\d+)$/;
  const reportKeys : Array<IReportKey> = collectedReportKeyParams.paramValues.map((x) => {
    const match = reportKeyRegExp.exec(x);
    if (match && match[1] && match[2]) {
      return { type: match[1], id: match[2] };
    }
    return null;
  }).filter((x) : x is IReportKey => x !== null && x !== undefined);

  return reportKeys;
};

const parseDateParameter = (
  parsedUrlParameters: IParsedUrlParameters,
  urlParamKey: string,
  parseSuccess: (date: Date) => void,
) : void => {
  getUrlParameterFirstValue(parsedUrlParameters, urlParamKey, (value) => {
    const date = parseYYYYDashMMDashDDToDate(value);
    if (date !== null) {
      parseSuccess(date);
    }
  });
};

const parseNumberParameter = (
  parsedUrlParameters: IParsedUrlParameters,
  urlParamKey: string,
  parseSuccess: (numberValue: Number) => void,
) : void => {
  getUrlParameterFirstValue(parsedUrlParameters, urlParamKey, (value) => {
    const numberValue = parseInt(value, 10);
    if (!Number.isNaN(numberValue)) {
      parseSuccess(numberValue);
    }
  });
};

const parseStringParameter = (
  parsedUrlParameters: IParsedUrlParameters,
  urlParamKey: string,
  parseSuccess: (stringValue: string) => void,
) : void => {
  getUrlParameterFirstValue(parsedUrlParameters, urlParamKey, (value) => {
    if (value !== null && value !== undefined && value !== '') {
      parseSuccess(value);
    }
  });
};

const parseBooleanParameter = (
  parsedUrlParameters: IParsedUrlParameters,
  urlParamKey: string,
  parseSuccess: (booleanValue: boolean) => void,
) : void => {
  getUrlParameterFirstValue(parsedUrlParameters, urlParamKey, (value) => {
    if (value === 'false') {
      parseSuccess(false);
    } else if (value === 'true') {
      parseSuccess(true);
    }
  });
};

const getUrlParameterFirstValue = (
  parsedUrlParameters: IParsedUrlParameters,
  urlParamKey: string,
  getFirstSuccess: (value: string) => void,
) : void => {
  const paramKeyValuePair = findParamKeyValuePair(parsedUrlParameters, urlParamKey);
  if (paramKeyValuePair !== undefined && paramKeyValuePair.paramValues.length > 0) {
    const value = paramKeyValuePair.paramValues[0];
    getFirstSuccess(value);
  }
};

const findParamKeyValuePair = (parsedUrlParameters: IParsedUrlParameters, urlParamKey: string) : IParsedUrlParameter | undefined => parsedUrlParameters
  .paramKeyValuesPairs.find((parsedUrlParameter) => parsedUrlParameter.paramKey === urlParamKey);
