import { ParametersUpdateThunk, ReportSearchParametersActionTypes } from '../searchParameters/actionTypes';
import { getSearchQuery } from '../searchParameters/searchParametersSelectors';
import { ISearchQuery } from '../searchParameters/searchParametersTypes';
import { Report } from '../searchResults/searchResultTypes';
import { updateShareUrl } from './actions';

export const UPDATE_SELECTED_REPORTS = 'UPDATE_SELECTED_REPORTS';
export interface UpdateSelectedReportsAction {
    type: typeof UPDATE_SELECTED_REPORTS,
    report: Report,
    isReportSelected: boolean
}

export const UPDATE_SHARE_URL = 'UPDATE_SHARE_URL';
export interface UpdateShareUrlAction {
    type: typeof UPDATE_SHARE_URL,
    query: ISearchQuery,
}

export const SET_SHARE_URL = 'SET_SHARE_URL';
export interface SetShareUrlAction {
    type: typeof SET_SHARE_URL,
    url: string,
}

export const SET_HEADER_EXPANDED = 'SET_HEADER_EXPANDED';
export interface SetHeaderExpandedAction {
    type: typeof SET_HEADER_EXPANDED,
    expanded: boolean,
}

export const SET_SHARE_DIALOG_OPEN = 'SET_SHARE_DIALOG_OPEN';
export interface SetShareDialogOpenAction {
    type: typeof SET_SHARE_DIALOG_OPEN,
    dialogOpen: boolean,
}

export type UserPrefsActionTypes = UpdateSelectedReportsAction | UpdateShareUrlAction | SetHeaderExpandedAction | SetShareDialogOpenAction | SetShareUrlAction;

// TODO: refactor thunk to saga
export const thunkUpdateQueryAndUrl = (action: ReportSearchParametersActionTypes | UserPrefsActionTypes): ParametersUpdateThunk => (dispatch, getState) => {
  dispatch(action);
  setTimeout(() => {
    const userSearchParams = getSearchQuery(getState());
    dispatch(updateShareUrl(userSearchParams));
  }, 1000);
};
