import React from 'react';

export function SignedOutPage() {
  return (
    <div>
      <h1>Signed out</h1>
      <h2>You are signed out of Report Search.</h2>
    </div>
  );
}
