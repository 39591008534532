import * as React from 'react';
import './ParameterMultiSelectDropdown.css';
import AutoComplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { ISearchParameterValues, GenericFilterType, orderByFacetCount } from '../store/searchParameters/searchParametersTypes';

export interface ParameterMultiSelectDropdownProps<T extends GenericFilterType> {
    label: string,
    selectableValues: T[],
    selectedValues: T[],
    selectableProperty: keyof T,
    searchKey: keyof ISearchParameterValues,
    onChange: (key: keyof ISearchParameterValues, value: T[]) => void
    groupBy?: keyof T
    formatter?: (text: string) => string
}

export default class ParameterMultiSelectDropdown<T extends GenericFilterType> extends React.PureComponent<ParameterMultiSelectDropdownProps<T>> {
  getOptionLabel = this.props.formatter
    ? (option: T) => (this.props.formatter as (text: string) => string)(`${option[(this.props.selectableProperty)]}`)
    : (option: T) => `${option[(this.props.selectableProperty)]}`;

  handleChange = (_event: React.ChangeEvent<{}>, value: T[], _reason: AutocompleteChangeReason) => {
    this.props.onChange(this.props.searchKey, value);
  };

  orderByGroupThenCount = (firstValue: T, secondValue: T, groupBy: keyof T) => {
    if (firstValue[groupBy] > secondValue[groupBy]) {
      return 1;
    }
    if (firstValue[groupBy] < secondValue[groupBy]) {
      return -1;
    }
    return orderByFacetCount(firstValue, secondValue);
  };

  orderByFacets = (selectableValues: T[]) => {
    const { groupBy } = this.props;

    if (groupBy) {
      return selectableValues.sort((firstVal, secondVal) => this.orderByGroupThenCount(firstVal, secondVal, groupBy));
    }

    return selectableValues.sort(orderByFacetCount);
  };

  render() {
    const groupBy = this.props.groupBy ? ((option: T) => `${option[(this.props.groupBy) as keyof T]}`) : undefined;
    const {
      selectableValues,
      selectedValues,
      label,
    } = this.props;
    return (
      <AutoComplete
        multiple
        limitTags={1}
        size="small"
        groupBy={groupBy}
        options={this.orderByFacets(selectableValues)}
        getOptionLabel={this.getOptionLabel}
        renderOption={(option) => (
          <div className="dropdown-render-options">
            <div>{option[this.props.selectableProperty]}</div>
            <div>
              (
              {option.count.toLocaleString()}
              )
            </div>
          </div>
        )}
        value={selectedValues}
        onChange={this.handleChange}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="standard"
            label={label}
            placeholder={label}
          />
        )}
        fullWidth
      />
    );
  }
}
