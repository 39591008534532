import {
  appMessagesActionTypes, SHOW_ERROR, SHOW_PROGRESS, HIDE_ERROR, HIDE_PROGRESS,
  SystemMessage, IAppMessages,
} from './types';

const initialState : IAppMessages = {
  errorMessage: new SystemMessage(''),
  progressMessage: new SystemMessage(''),
};

export const appMessagesReducer = (
  state = initialState,
  action: appMessagesActionTypes,
) : IAppMessages => {
  switch (action.type) {
    case SHOW_ERROR: {
      return {
        ...state,
        errorMessage: new SystemMessage(action.errorMessage),
      };
    }
    case SHOW_PROGRESS: {
      return {
        ...state,
        progressMessage: new SystemMessage(action.progressMessage),
      };
    }
    case HIDE_ERROR: {
      return {
        ...state,
        errorMessage: new SystemMessage(''),
      };
    }
    case HIDE_PROGRESS: {
      return {
        ...state,
        progressMessage: new SystemMessage(''),
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
